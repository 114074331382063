import { ColDef } from "ag-grid-community";

import { InstitutionCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/institution-cell-renderer/institution-renderer.component";
import { ProductCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/product-cell-renderer/product-renderer.component";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";

import { AggregateAmountCellRenderer } from "../cell-renderers/aggregate-amount-renderer/aggregate-amount-cell-renderer.component";
import { BalanceCellRenderer } from "../cell-renderers/balance-renderer/balance-renderer.component";
import { BookCellRenderer } from "../cell-renderers/book-renderer/book-renderer.component";
import { CategoryCellRenderer } from "../cell-renderers/category-renderer/category-renderer.component";
import { CheckBoxCellRenderer } from "../cell-renderers/check-box-renderer/check-box-cell-renderer.component";
import { ClassificationCellRenderer } from "../cell-renderers/classification-renderer/classification-renderer.component";
import { InAmountCellRenderer } from "../cell-renderers/in-amount-renderer/in-amount-cell-renderer.component";
import { NormalizedInAmountCellRenderer } from "../cell-renderers/normalized-in-amount-renderer/normalized-in-amount-cell-renderer.component";
import { NormalizedOutAmountCellRenderer } from "../cell-renderers/normalized-out-amount-renderer/normalized-out-amount-cell-renderer.component";
import { OutAmountCellRenderer } from "../cell-renderers/out-amount-renderer/out-amount-renderer.component";

const commonDefs = {
  suppressHeaderMenuButton: true,
  mainMenuItems: [
    "sortAscending",
    "sortDescending",
    "autoSizeThis",
    "autoSizeAll",
    "columnChooser",
    "resetColumns",
  ],
};

function amountComparator(valueA: number, valueB: number) {
  return valueA - valueB;
}

function amountValueGetter(params: any) {
  if (params.data?.quantity) {
    return params.data.quantity.actualQuantity.amount;
  }
}

export const transactionColumnDefinitions: ColDef[] = [
  {
    headerName: " ",
    field: "isSelected",
    maxWidth: 30,
    cellClass: "_sCheckBox",
    suppressColumnsToolPanel: true,
    cellRenderer: CheckBoxCellRenderer,
    ...commonDefs,
  },
  {
    headerName: "Transaction Date",
    field: "transactionDate",
    suppressColumnsToolPanel: true,
    valueFormatter: (params) => {
      const glossDate = new GlossDate();
      const transactionViewGDate = params.data?.glossDate;
      return transactionViewGDate ? glossDate.getTableDate(transactionViewGDate) : "";
    },
    cellDataType: "text",
    ...commonDefs,
    sort: "desc",
  },
  {
    headerName: "Institution",
    field: "accountId",
    cellRenderer: InstitutionCellRenderer,
    cellDataType: "text",
    ...commonDefs,
  },
  {
    headerName: "Account",
    field: "accountId",
    suppressColumnsToolPanel: true,
    cellRenderer: BookCellRenderer,
    cellDataType: "text",
    ...commonDefs,
  },
  {
    headerName: "Product",
    field: "accountId",
    hide: true,
    cellRenderer: ProductCellRenderer,
    cellDataType: "text",
    ...commonDefs,
  },
  {
    headerName: "Description",
    field: "description",
    cellDataType: "text",
    ...commonDefs,
  },
  {
    headerName: "Categories",
    field: "categories",
    ...commonDefs,
    cellRenderer: CategoryCellRenderer,
    cellDataType: "text",
  },
  {
    headerName: "Classification",
    field: "classifications",
    hide: true,
    ...commonDefs,
    valueFormatter: (p: any) => "",
    cellDataType: "text",
    cellRenderer: ClassificationCellRenderer,
  },
  {
    headerName: "In",
    field: "in",
    ...commonDefs,
    valueFormatter: (p: any) => "",
    cellRenderer: InAmountCellRenderer,
    valueGetter: amountValueGetter,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "Out",
    field: "out",
    ...commonDefs,
    valueFormatter: (p: any) => "",
    cellRenderer: OutAmountCellRenderer,
    valueGetter: amountValueGetter,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "In (Normalized)",
    field: "in_normalized",
    hide: true,
    suppressColumnsToolPanel: true,
    ...commonDefs,
    valueFormatter: (p: any) => "",
    cellRenderer: NormalizedInAmountCellRenderer,
    valueGetter: amountValueGetter,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "Out (Normalized)",
    field: "out_normalized",
    hide: true,
    suppressColumnsToolPanel: true,
    ...commonDefs,
    valueFormatter: (p: any) => "",
    cellRenderer: NormalizedOutAmountCellRenderer,
    valueGetter: amountValueGetter,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "Aggregate",
    field: "aggregate",
    hide: true,
    ...commonDefs,
    valueFormatter: (p: any) => "",
    cellDataType: "text",
    cellRenderer: AggregateAmountCellRenderer,
  },
  {
    headerName: "Balance",
    field: "balance",
    suppressColumnsToolPanel: true,
    ...commonDefs,
    valueFormatter: (p: any) => "",
    cellRenderer: BalanceCellRenderer,
    valueGetter: (params: any) => params.data?.dailyBalance,
    cellDataType: "text",
    comparator: (valueA, valueB) => {
      return valueA - valueB;
    },
  },
  {
    headerName: "Currency",
    field: "currency",
    hide: true,
    ...commonDefs,
    cellDataType: "text",
    valueGetter: (params: any) => params.data?.quantity?.currency,
  },
];
