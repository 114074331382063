import { Injectable, Injector } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import {
  ChildNodes,
  dayGranularity,
  GranularityProperty,
  GroupingNodeType,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { AllocationGroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/allocationGroupingNode";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";
import { SymbolConversionService } from "@bitwarden/web-vault/app/services/DataCalculationService/symbol/symbol-conversion.service";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";

import { Transaction } from "../../../models/data/blobby/transaction.data";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class TransactionCalculationService implements CalculationServiceAbstraction {
  // private _groupedBalances: TransactionGrouping; // cached grouping balance object built from the getBalance function
  private _groupedBalances: BalanceGrouping;

  constructor(
    private symbolConversionService: SymbolConversionService,
    private preferenceService: PreferenceService,
    private logger: LogService,
    private perfService: PerformanceService,
    private categoryService: CategoryService
  ) {}

  /**
   * Builds a complete grouped object of the most common variations of grouping such as granularity, accounts
   * allocations, direction and sums up the balances in each of those groupings to be stored in the variable
   * _groupedBalances on this service.
   * @param allTransactions
   * @param forceRefresh - refresh the grouped balance object instead of using the cached version
   * @param createChildren
   * @param limitChildren
   * @param granularityList
   */
  async getBalance(
    allTransactions: Transaction[],
    forceRefresh = false,
    createChildren = true,
    limitChildren?: Array<GroupingNodeType>,
    granularityList?: Array<GranularityProperty>,
    fillDaily = false
  ): Promise<BalanceGrouping> {
    this.perfService.mark("TransactionCalculationService::getBalance");
    if (!this._groupedBalances || forceRefresh) {
      const balanceGrouping = new BalanceGrouping(allTransactions, this.preferenceService);
      await balanceGrouping.buildBalanceGrouping(
        false,
        allTransactions,
        true,
        createChildren,
        limitChildren,
        granularityList,
        fillDaily
      );
      this._groupedBalances = balanceGrouping;
    }
    this.perfService.markEnd();
    return this._groupedBalances;
  }

  /**
   * Force the _groupedBalance to be rebuilt with the transactions passed in as a parameter
   * @param allTransactions
   */
  async recalculateBalance(
    allTransactions: Transaction[],
    createChildren = true,
    limitChildren?: Array<GroupingNodeType>,
    granularityList?: Array<GranularityProperty>,
    fillDaily = false
  ) {
    this.logger.debug("TransactionCalculationService: recalculateBalance");
    return await this.getBalance(
      allTransactions,
      true,
      createChildren,
      limitChildren,
      granularityList,
      fillDaily
    );
  }

  /**
   * Returns the cached version of the groupedBalances without refreshing any transaction data.
   */
  async getCachedBalance() {
    return this._groupedBalances;
  }

  /**
   * Given a Transaction Grouping function, an accountID, the required currency and date,
   * return the balance number for that currency.
   * @param balanceGrouping
   * @param accountID
   * @param currency
   * @param balanceDate
   */
  getAccountCurrencyBalanceByDate(
    balanceGrouping: BalanceGrouping,
    accountID: string,
    currency: string,
    balanceDate: Date
  ): number {
    if (!balanceGrouping) {
      return 0;
    }

    if (balanceGrouping.dateEnd && balanceDate > balanceGrouping.dateEnd) {
      balanceDate = balanceGrouping.dateEnd;
    }

    const dayKey = balanceGrouping.getDateGroupingKey(balanceDate, dayGranularity);

    if (!balanceGrouping.granularity?.[dayGranularity]?.[dayKey]) {
      return 0;
    }

    const groupingNode = balanceGrouping.granularity[dayGranularity][dayKey];

    if (!groupingNode?.children?.account?.[accountID]?.balance) {
      return 0;
    }

    const accountBalance = groupingNode.children.account[accountID].balance;

    const symbolBalance = accountBalance.getRunningTotalSymbol(currency);

    if (!symbolBalance) {
      return 0;
    }

    return symbolBalance.symbolAmount.amount;
  }

  /**
   * Given a Balance Grouping, return the balances seperated into accounts for that particular date.
   * If the date asked for is after the endDate of the balance grouping, then return the final balance.
   * @method getBalanceGroupingByAccountsOnDate
   * @param {BalanceGrouping} balanceGrouping - The BalanceGrouping object from which to extract the balances
   * @param {Date} balanceDate - The specific date for which to get the balances
   * @param {GranularityProperty} [granularity=dayGranularity] - The granularity property, defaults to dayGranularity
   * @returns {Record<string, GroupingNode>} - Returns an object where the keys are account IDs and the values are GroupingNode objects
   */
  getBalanceGroupingByAccountsOnDate(
    balanceGrouping: BalanceGrouping,
    balanceDate: Date,
    granularity: GranularityProperty = dayGranularity
  ): Record<string, GroupingNode> {
    if (!balanceGrouping) {
      return {};
    }

    if (balanceGrouping.dateEnd && balanceDate > balanceGrouping.dateEnd) {
      balanceDate = balanceGrouping.dateEnd;
    }

    const dayKey = balanceGrouping.getDateGroupingKey(balanceDate, granularity);

    if (balanceGrouping.granularity?.[granularity]?.[dayKey]?.["children"]?.["account"]) {
      return balanceGrouping.granularity[granularity][dayKey]["children"]["account"];
    }

    return {};
  }

  /**
   * Given a Transaction Grouping function and a date,
   * return the normalized balance number for that date.
   * @param balanceGrouping
   * @param accountID
   * @param currency
   * @param balanceDate
   */
  getNormalizedBalanceByDate(balanceGrouping: BalanceGrouping, balanceDate: Date): number {
    if (!balanceGrouping) {
      return 0;
    }

    if (balanceGrouping.dateEnd && balanceDate > balanceGrouping.dateEnd) {
      balanceDate = balanceGrouping.dateEnd;
    }

    const dayKey = balanceGrouping.getDateGroupingKey(balanceDate, dayGranularity);

    if (!balanceGrouping.granularity?.[dayGranularity]?.[dayKey]) {
      return null;
    }

    const groupingNode = balanceGrouping.granularity[dayGranularity][dayKey];

    if (!groupingNode?.balance?.runningTotalNormalizedValue) {
      return null;
    }

    return groupingNode.balance.runningTotalNormalizedValue.amount;
  }

  /**
   * Given a Balance Grouping, return the balance grouping nodes filtered and seperated into accounts for that
   * particular date.
   * @param balanceGrouping
   * @param granularity
   * @param groupingKey
   * @param accounts
   */
  getFilteredAccountBalances(
    balanceGrouping: BalanceGrouping,
    granularity: GranularityProperty,
    groupingKey: string,
    accounts: Array<Book>
  ): Record<string, GroupingNode> {
    const accountBalances: Record<string, GroupingNode> = {};
    if (!balanceGrouping.granularity?.[granularity]?.[groupingKey]?.children?.account) {
      return accountBalances;
    }

    const childAccounts = balanceGrouping.granularity[granularity][groupingKey].children.account;

    // TODO: add back in category-renderer and classification filtering later
    for (const accountID in childAccounts) {
      if (this.filteredAccount(accountID, accounts)) {
        accountBalances[accountID] = childAccounts[accountID];
      }
    }

    return accountBalances;
  }

  /**
   * Given a balanceGrouping object, extract the correct balance based on the parameters
   * filtering on accounts, direction, categories and classifications
   * @param balanceGrouping
   * @param granularity
   * @param groupingKey
   * @param accounts
   * @param categories
   * @param classifications
   */
  getFilteredBalance(
    balanceGrouping: BalanceGrouping,
    granularity: GranularityProperty,
    groupingKey: string,
    accounts: Array<Book>,
    categories: Array<Category>,
    classifications: Array<Classification>
  ): GlossBalance {
    if (!balanceGrouping.granularity?.[granularity]?.[groupingKey]?.balance) {
      return;
    }
    const granularityGrouping = balanceGrouping.granularity[granularity][groupingKey];
    if (!accounts && !categories && !classifications) {
      if (granularityGrouping.balance instanceof GlossBalance) {
        return granularityGrouping.balance;
      }
    } else if (categories || classifications) {
      return this.sumAllocationBalances(granularityGrouping, accounts, categories, classifications);
    } else {
      return this.sumAccountBalances(granularityGrouping, accounts);
    }
    return;
  }

  sumAllocationBalances(
    granularityGrouping: GroupingNode,
    accounts: Array<Book>,
    categories: Array<Category>,
    classifications: Array<Classification>
  ): GlossBalance {
    const allocationBalanceSum = new GlossBalance();
    let childAccounts = {} as ChildNodes;
    if (granularityGrouping?.children?.account) {
      childAccounts = granularityGrouping.children.account;
    }
    for (const accountID in childAccounts) {
      if (this.filteredAccount(accountID, accounts)) {
        const accountNode = childAccounts[accountID];
        let childAllocations = {} as ChildNodes;
        if (accountNode?.children?.allocation) {
          childAllocations = accountNode.children.allocation;
        }
        for (const childAllocationID in childAllocations) {
          const allocationNode = childAllocations[childAllocationID];
          if (allocationNode instanceof AllocationGroupingNode) {
            const categoryCheck = this.filteredCategory(allocationNode.category, categories);
            const classificationCheck = this.filteredClassification(
              allocationNode.classification,
              classifications
            );
            if (categoryCheck && classificationCheck) {
              allocationBalanceSum.add(allocationNode.balance);
            }
          }
        }
      }
    }
    return allocationBalanceSum;
  }

  sumAccountBalances(granularityGrouping: GroupingNode, accounts: Array<Book>): GlossBalance {
    let childAccounts = {} as ChildNodes;
    if (granularityGrouping?.children?.account) {
      childAccounts = granularityGrouping.children.account;
    }
    const accountBalanceSum = new GlossBalance();
    for (const accountID in childAccounts) {
      if (this.filteredAccount(accountID, accounts)) {
        const accountNode = childAccounts[accountID];
        accountBalanceSum.add(accountNode.balance);
      }
    }
    return accountBalanceSum;
  }

  /**
   * Sum the balances of the day granularities that fall within these dates
   *
   * @param balanceGrouping
   * @param startDate - Does include anything that falls on the start date
   * @param endDate - Does include anything that falls on the end date
   */
  sumBalances(balanceGrouping: BalanceGrouping, startDate: Date, endDate: Date) {
    const directionSum: GlossBalance = new GlossBalance();
    if (balanceGrouping?.granularity?.[dayGranularity]) {
      for (const groupingNodeKey in balanceGrouping.granularity[dayGranularity]) {
        if (
          new Date(new Date(groupingNodeKey).toDateString()).getTime() >= startDate.getTime() &&
          new Date(new Date(groupingNodeKey).toDateString()).getTime() <= endDate.getTime()
        ) {
          directionSum.addGranularityBalances(
            balanceGrouping.granularity[dayGranularity][groupingNodeKey].balance
          );
        } else if (new Date(groupingNodeKey) > endDate) {
          return directionSum;
        }
      }
    }
    return directionSum;
  }

  /**
   * Given a balanceGrouping object, extract the correct balance based on the parameters
   * filtering on accounts, direction, categories and classifications
   * @param balanceGrouping
   * @param granularity
   * @param groupingKey
   * @param accounts
   * @param direction
   * @param categories
   * @param classifications
   */
  getFilteredNormalizedBalance(
    balanceGrouping: BalanceGrouping,
    granularity: GranularityProperty,
    groupingKey: string,
    accounts: Array<Book>,
    direction: Array<string>,
    categories: Array<Category>,
    classifications: Array<Classification>
  ): number {
    let normalizedBalance = 0;
    const balance = this.getFilteredBalance(
      balanceGrouping,
      granularity,
      groupingKey,
      accounts,
      categories,
      classifications
    );

    if (!direction) {
      normalizedBalance = balance.runningTotalNormalizedValue.amount;
    } else if (direction.includes(TransactionDirection.In)) {
      normalizedBalance = balance.runningTotalNormalizedIn.amount;
    } else if (direction.includes(TransactionDirection.Out)) {
      normalizedBalance = balance.runningTotalNormalizedOut.amount;
    }
    return normalizedBalance;
  }

  getFilteredDirection(
    balanceGrouping: BalanceGrouping,
    granularity: GranularityProperty,
    groupingKey: string,
    accounts: Array<Book>,
    direction: Array<string>,
    categories: Array<Category>,
    classifications: Array<Classification>
  ): Record<string, number> {
    const directionBalances = { IN: 0, OUT: 0 };

    const normalizedBalanceIn = new GlossNumber();
    const normalizedBalanceOut = new GlossNumber();
    const filteredBalance = this.getFilteredBalance(
      balanceGrouping,
      granularity,
      groupingKey,
      accounts,
      categories,
      classifications
    );

    GlossBalance.addNormalizedFromPair(normalizedBalanceIn, filteredBalance.in);
    GlossBalance.addNormalizedFromPair(normalizedBalanceOut, filteredBalance.out);

    if (!direction || direction.includes(TransactionDirection.In)) {
      directionBalances.IN = normalizedBalanceIn.amount;
    }
    if (!direction || direction.includes(TransactionDirection.Out)) {
      directionBalances.OUT = normalizedBalanceOut.amount;
    }
    return directionBalances;
  }

  /**
   * Return true if we should include the accountID in our dataset if accounts is the array of
   * accounts selected in the filter. Note if accounts is null then we don't apply any filtering on accounts.
   * @param accountID
   * @param accounts
   */
  filteredAccount(accountID: string, accounts: Array<Book>) {
    if (!accounts) {
      return true;
    }
    return accounts.find((account) => account.id === accountID);
  }

  /**
   * Return true if we should include the classificationID in our dataset if classifications is the array of
   * classifications selected in the filter. Note if classifications is null then we don't apply any
   * filtering on classifications.
   * @param classificationID
   * @param classifications
   */
  filteredClassification(classificationID: string, classifications: Array<Classification>) {
    if (!classifications) {
      return true;
    }
    return classifications.find((classification) => classification.id === classificationID);
  }

  /**
   * Return true if we should include the categoryID in our dataset if categories is the array of
   * categories selected in the filter. Note if categories is null then we don't apply any
   * filtering on categories.
   * @param categoryID
   * @param categories
   */
  filteredCategory(categoryID: string, categories: Array<Category>) {
    if (!categories) {
      return true;
    }
    return categories.find((category) => category.id === categoryID);
  }

  /**
   * createStartingTransactionsFromBalance - Given a list of GroupingNodes which are the balance at the starting date
   *                                         create transactions for each account and symbol so that they can be added
   *                                         to the BalanceGrouping
   */
  async createStartingTransactionsFromBalance(
    startDate: Date,
    startingBalances: Record<string, GroupingNode>,
    description: string,
    injector: Injector
  ): Promise<Array<Transaction>> {
    const transactions: Array<Transaction> = [];
    const transactionNormalizeService = injector.get(TransactionNormalizeService);
    const classificationService = injector.get(ClassificationService);
    const defaultSplitClassification =
      await classificationService.createDefaultSplitClassification();

    for (const accountID in startingBalances) {
      const symbolBalances = startingBalances[accountID].balance.runningTotalValue;
      for (const symbol in symbolBalances) {
        const symbolAmount = symbolBalances[symbol].symbolAmount.amount;
        const mockTransaction = await this.createFakeTransaction(
          accountID,
          symbolAmount,
          symbol,
          startDate,
          description,
          transactionNormalizeService,
          defaultSplitClassification
        );
        transactions.push(mockTransaction);
      }
    }
    return transactions;
  }

  // todo this should be moved in the TransactionResponse class so it can get updated if the model changes
  async createFakeTransaction(
    accountID: string,
    symbolAmount: number,
    symbol: string,
    transactionDate: Date,
    description: string,
    transactionNormalizeService: TransactionNormalizeService,
    defaultSplitClassification: SplitClassificationType[],
    linkedTo?: Array<string>
  ): Promise<Transaction> {
    const fakeTransactionResponse = new TransactionResponse({
      __v: 1,
      accountId: accountID,
      description: description,
      quantity: symbolAmount,
      symbol: symbol,
      date: transactionDate.toDateString(),
      definition: TransactionStatusEnum.fake,
      _linkedTo: linkedTo,
    });
    const fakeTransaction = new Transaction(fakeTransactionResponse);
    fakeTransaction.classifications = defaultSplitClassification;
    fakeTransaction.categories = await this.categoryService.createDefaultSplitCategory();

    await transactionNormalizeService.normalizeImportedTransaction(fakeTransaction);

    // set the currency of the quantity
    if (!fakeTransaction.quantity.currency && fakeTransactionResponse.valuation.value.symbol) {
      fakeTransaction.quantity.currency = fakeTransactionResponse.valuation.value.symbol;
    }
    // set the valuation price
    if (!fakeTransaction.valuationPrice && fakeTransactionResponse.valuation.symbolValue) {
      fakeTransaction.valuationPrice = fakeTransactionResponse.valuation.symbolValue;
    }
    return fakeTransaction;
  }

  // TODO: Need to build out functions to be able to add more groupings to the existing grouping set as we discover
  // more types that we need to group by for newer graphs.

  // TODO: Need to build out functions to add different estimates to existing groupings without affecting the baseline
  // integrity of the chached grouping functionality.
}
