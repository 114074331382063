import { CurrencyPipe } from "@angular/common";
import { Component, EventEmitter, Injector, Input, Output, Renderer2 } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import "./transaction-table-tabs.scss";
import { ScenarioDifferenceMessageComponent } from "@bitwarden/web-vault/app/components/scenario-difference-message/scenario-difference-message.component";
import { glossRouteInfo } from "@bitwarden/web-vault/app/layouts/sections-info-texts";
import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import {
  ScenarioAccountInfo,
  ScenarioData,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { SharedService } from "@bitwarden/web-vault/app/shared/sharedAppEvent.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

@Component({
  selector: "app-transaction-table-tabs",
  templateUrl: "transaction-table-tabs.component.html",
  styles: ["transaction-table-tabs.scss"],
  providers: [CurrencyPipe],
})
export class TransactionTableTabsComponent {
  inDevMode = HelperCommon.isDevEnv();
  @Output() linkConversion = new EventEmitter<any>();
  @Output() linkTransfer = new EventEmitter<any>();
  @Output() unlinkTransactions = new EventEmitter<any>();
  @Output() deleteTransaction = new EventEmitter<any>();
  @Output() resetColumns = new EventEmitter<any>();

  @Input() isLinkButtonEnabled: boolean;

  private destroy$ = new Subject<void>();
  tabs: Array<{ key: number; content: string; type: string; class: string }>;
  graphData: Array<GraphDataSet>;
  scenarioData: ScenarioData;
  dashboardService: DashboardService;
  hasTransactions = false;

  tooltipInfo: string;
  showHelpTooltip = false;
  xPosition: number;
  yPosition: number;
  pointer: string;

  trianglePointerOffset = 50;
  trianglePointerHeight = 16;

  differenceScenarioRef: MatDialogRef<ScenarioDifferenceMessageComponent>;

  selectedTabKey: string | null = null;

  constructor(
    private injector: Injector,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private currencyPipe: CurrencyPipe
  ) {
    this.dashboardService = this.injector.get(DashboardService);
    this.subscribeToDashboardObservables();
  }

  tabName(tab: { key: number; content: string; type: string; class: string }) {
    if (this.tabs.length > 3 && this.isMobile() && !tab.class.includes("selected")) {
      return tab.content.slice(0, 3) + "..." + (tab.type === "transaction" ? "" : tab.key + 1);
    } else {
      return tab.content;
    }
  }

  tabContainerByDevice(tab: { key: number; content: string; type: string; class: string }) {
    if (this.isDesktop()) {
      return tab.class + " inDesktop";
    }
    if (this.isMobile()) {
      return tab.class;
    }
    if (this.isTablet()) {
      return tab.class;
    }
  }

  tabStyle(tab: { key: number; content: string; type: string; class: string }, elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      this.renderer.setStyle(element, "width", 100);
    }
    if (this.isDesktop() || (this.isMobile() && tab.class.includes("selected"))) {
      return tab.class;
    }
    return tab.class;
  }

  // shining effect on scenario 1 tooltip
  highlightScenarioButton(scenarioNumber: number) {
    const scenarioButton = document.getElementById(`scenario-${scenarioNumber}-help-button`);

    if (scenarioButton) {
      scenarioButton.classList.add("tw-animate-ping", "opacity-100");

      const removeHighlight = () => {
        scenarioButton.classList.replace("tw-animate-ping", "bg-transparent");
        scenarioButton.classList.replace("opacity-100", "opacity-25"); // Adjust as needed
        scenarioButton.removeEventListener("click", removeHighlight); // Clean up the event listener
      };

      scenarioButton.addEventListener("click", removeHighlight);
    }
  }

  async subscribeToDashboardObservables() {
    this.dashboardService.graphData$.pipe(takeUntil(this.destroy$)).subscribe((graphData) => {
      this.graphData = this.dashboardService.isVaultPurge ? [] : graphData;
      this.buildTabData(false);
    });

    this.dashboardService.scenarioData$.pipe(takeUntil(this.destroy$)).subscribe((scenarioData) => {
      this.scenarioData = this.dashboardService.isVaultPurge
        ? { balance: [], scenario: [] }
        : scenarioData;
      if (this.scenarioData.scenario.length > 0) {
        this.graphData = this.scenarioData.balance;
        this.buildTabData(true);

        if (localStorage.getItem("startTutorial") === "true") {
          this.openScenarioDifferenceMessage();
          localStorage.removeItem("startTutorial");
        }
      }
    });
  }

  openScenarioDifferenceMessage() {
    const dialogRef = this.dialog.open(ScenarioDifferenceMessageComponent, {
      maxWidth: "800px",
      data: { scenarioData: this.scenarioData },
      disableClose: true,
      panelClass: ["scenario-difference-dialog", "xs:tw-h-full"],
      autoFocus: false,
    });
    this.differenceScenarioRef = dialogRef;
    // Use Renderer2 to add the class to the mat-dialog-container element
    dialogRef
      .afterOpened()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const matDialogContainer = document.querySelector("mat-dialog-container");
        if (matDialogContainer && this.isMobile()) {
          this.renderer.addClass(matDialogContainer, "mobile-mat-dialog-container");
        } else {
          this.renderer.addClass(matDialogContainer, "desktop-mat-dialog-container");
        }
      });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.clickOnScenario3Button();
      });
  }

  // todo: needs improvement but logic is not use set timeout
  clickOnScenario3Button() {
    const onboardingStartPoint = document.getElementById("scenario-3-help-button");
    if (onboardingStartPoint) {
      onboardingStartPoint.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => {
        const helpButtonForScenario3 = document.getElementById("scenario-3-help-button");
        if (helpButtonForScenario3) {
          helpButtonForScenario3.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
            })
          );
        }
        localStorage.removeItem("startTutorial");
      }, 500);
    }
    this.highlightScenarioButton(1);
  }

  async updateTab(type: string, key: number) {
    // update the scenario tab on the dashboard service
    await this.dashboardService.updateTransactionsTable(type, key);

    this.sharedService.updateGridTabType(type);

    for (const key in this.tabs) {
      this.tabs[key].class = "tabs";
    }

    if (type === "transaction") {
      this.tabs[0].class = "tabs selected";
    } else if (this.hasTransactions) {
      this.tabs[key + 1].class = "tabs selected";
    } else {
      this.tabs[key].class = "tabs selected";
    }
  }

  async openTabHelp(e: MouseEvent, type: string, key: number) {
    this.tooltipInfo = this.getScenarioHelpText(type, key);
    this.showHelpTooltip = true;

    const target = e.currentTarget as HTMLElement;
    const targetRect = target.getBoundingClientRect();

    this.pointer = "bottom";
    this.xPosition = targetRect.right - this.trianglePointerOffset - targetRect.width / 2;
    this.yPosition = targetRect.top - this.trianglePointerHeight;
  }

  onTooltipClose() {
    this.showHelpTooltip = false;
  }

  private buildTabData(hasScenario: boolean) {
    const tabData = [];

    const transactionTab = {
      content: "Transactions",
      type: "transaction",
      key: 0,
      class: "tabs transaction-tab",
    };

    this.hasTransactions = false;
    if (!hasScenario) {
      tabData.push(transactionTab);
    } else {
      if (this.graphData.length > 0) {
        tabData.push(transactionTab);
        this.hasTransactions = true;
      }

      for (let i = 0; i < this.scenarioData.scenario.length; i++) {
        const isSelected = i === 2;
        const scenarioTab = {
          content: "Path " + (i + 1),
          type: "scenario",
          key: i,
          class: isSelected ? "tabs selected" : "tabs",
          id: `scenario-${i + 1}-tab`,
        };
        tabData.push(scenarioTab);
      }
    }
    this.tabs = tabData;
  }

  private getScenarioHelpText(type: string, key: number) {
    // extract the scenario help text from
    const helpInfo = glossRouteInfo[GlossRouteEnum.dashboard];
    let scenarioHelp;
    if (typeof helpInfo === "object") {
      if (helpInfo?.scenarioHelp) {
        scenarioHelp = helpInfo.scenarioHelp;
      }
    }
    let helpText = "";
    if (type === "scenario") {
      helpText += scenarioHelp[key];
    }
    helpText += this.getScenarioDetails(type, key);

    return helpText;
  }

  private getScenarioDetails(type: string, key: number) {
    let scenarioText = "";
    if (
      this.scenarioData &&
      this.scenarioData.scenario.length > 0 &&
      this.scenarioData.scenario?.[key]
    ) {
      const scenarioDetails = this.scenarioData.scenario[key];
      if (scenarioDetails?.helpInfo) {
        scenarioText += `<div class='scenario-details'>`;
        if (scenarioDetails.helpInfo?.transferAmount) {
          const formattedAmount = this.currencyPipe.transform(
            scenarioDetails.helpInfo.transferAmount,
            "AUD",
            "symbol"
          );
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Total Transfer Amount</div>` +
            `<div class="scenario-info-grouping short">` +
            `<div class="scenario-help-info">${formattedAmount}</div>` +
            `</div></div>`;
        }
        if (scenarioDetails.helpInfo?.transferToAccount) {
          const accountName = this.getAccount(scenarioDetails.helpInfo?.transferToAccount);
          const link = this.getUrl(scenarioDetails.helpInfo?.transferToAccount);
          const linkToBank = this.generateLinkToBank(link);
          const rate = this.getRate(scenarioDetails.helpInfo?.transferToAccount);
          const institutionName = this.getInstitutionName(
            scenarioDetails.helpInfo?.transferToAccount
          );
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Transfer to</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div class="scenario-help-info">${institutionName} ${accountName} ${rate} ${linkToBank}</div>` +
            `</div></div>`;
        }
        if (
          scenarioDetails.helpInfo?.transferFromAccount &&
          scenarioDetails.helpInfo.transferFromAccount.length > 0
        ) {
          const existingAccounts = scenarioDetails.helpInfo.transferFromAccount.length;
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Transfer From</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div class="scenario-help-info-box">` +
            `<div class="scenario-help-info strong">${existingAccounts} Existing Accounts</div>` +
            `</div>`;
          for (const account of scenarioDetails.helpInfo.transferFromAccount) {
            const accountName = this.getAccount(account);
            const link = this.getUrl(account);
            const linkToBank = this.generateLinkToBank(link);
            const rate = this.getRate(account);
            const institutionName = this.getInstitutionName(account);
            scenarioText +=
              `<div class="scenario-help-info-box">` +
              `<div class="scenario-help-info"><div>${institutionName} ${accountName} ${rate}</div> ${linkToBank}</div></div>`;
          }
          scenarioText += `</div></div>`;
        }
        if (scenarioDetails.helpInfo?.totalBalance) {
          const totalBalance = scenarioDetails.helpInfo.totalBalance;
          const formattedBalance = this.currencyPipe.transform(totalBalance, "AUD", "symbol");
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Total Current Balance</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div class="scenario-help-info">${formattedBalance}</div>` +
            `</div></div>`;
        }
        if (scenarioDetails.helpInfo?.existingAccounts) {
          const totalAccounts = scenarioDetails.helpInfo?.existingAccounts.length;
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Current Account</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div class="scenario-help-info-box">` +
            `<div class="scenario-help-info strong">${totalAccounts} Existing Accounts</div>` +
            `</div>`;
          for (const account of scenarioDetails.helpInfo.existingAccounts) {
            const rate = this.getRate(account);
            const link = this.getUrl(account);
            const linkToBank = this.generateLinkToBank(link);
            const institutionName = this.getInstitutionName(account);
            scenarioText +=
              `<div class="scenario-help-info-box">` +
              `<div class="scenario-help-info"><div>${institutionName} ${account.accountName} ${rate}</div> ${linkToBank}</div></div>`;
          }
          scenarioText += `</div></div>`;
        }
        scenarioText += `</div>`;
      }
    }
    return scenarioText;
  }

  generateLinkToBank(link: string) {
    const bankLink: string = link ? link : `#`;
    const newTab: string = link ? `target="_blank"` : ``;
    return `<div
    style="display: flex;
  flex-direction: row-reverse;"
  >
  <a href="${bankLink}" ${newTab}>
    <svg
        style="filter: invert(74%) sepia(5%) saturate(10%) hue-rotate(342deg) brightness(91%) contrast(88%); "
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 9L21 3M21 3H15M21 3L13 11M10 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3 7.27976 3 8.11984 3 9.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V14"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
  </div>`;
  }

  getInstitutionName(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountBank ? accountInfo.accountBank : "";
  }

  getUrl(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountUrl ? accountInfo.accountUrl : "";
  }

  getAccount(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountName ? accountInfo.accountName : "";
  }

  getRate(accountInfo: ScenarioAccountInfo) {
    if (accountInfo.rate && accountInfo.rate.length > 0) {
      const maxRate = accountInfo.rate.reduce((max, current) => {
        return current.rate > max ? current.rate : max;
      }, accountInfo.rate[0].rate);
      const minRate = accountInfo.rate.reduce((min, current) => {
        return current.rate < min ? current.rate : min;
      }, accountInfo.rate[0].rate);

      const rate = accountInfo.rate.length > 1 ? `${minRate} - ${maxRate}` : `${maxRate}`;
      return `(${rate}% p.a.)`;
    } else {
      return "(unknown account type)";
    }
  }

  ngOnDestroy() {
    this.showHelpTooltip = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  callLinkConversion() {
    this.linkConversion.emit();
  }

  callDeleteTransaction() {
    this.deleteTransaction.emit();
  }

  callLinkTransfer() {
    this.linkTransfer.emit();
  }

  callUnlinkTransactions() {
    this.unlinkTransactions.emit();
  }

  callIsLinkButtonEnabled(): boolean {
    return this.isLinkButtonEnabled;
  }

  toggleSelected(tabKey: string | null) {
    this.selectedTabKey = tabKey;
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isTablet() {
    return this.deviceService.isTablet();
  }

  isDesktop() {
    return this.deviceService.isDesktop();
  }
}
