import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import {
  InstitutionAccount,
  InstitutionInterest,
} from "@bitwarden/web-vault/app/models/types/institution.type";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";

import { AccountCreationAction } from "../data/possible-estimate-actions/account-creation.action";
import { InterestAction } from "../data/possible-estimate-actions/interest-action";
import { TransactionTransferAction } from "../data/possible-estimate-actions/transaction-transfer.action";

export const EstimateActionsTypeClasses = {
  accountCreation: AccountCreationAction,
  transactionTransfer: TransactionTransferAction,
  interestCreation: InterestAction,

  // interest = "interest-action" /** Interest calculation*/,
  // transactionTemplate = "transaction-template" /** For a specific template: When there is one we should change name accordingly*/,
  // recurringFixed = "recurring-fixed" /** Calculating the same transaction for the given period */,
  // nonRecurring = "non-recurring" /** Generating only one transaction. Can be like recurringFixed with both startDate and endDate being the same */,
};

export type EstimateActionsTypeProperties = typeof EstimateActionsTypeClasses;
export type EstimateActionsType = keyof EstimateActionsTypeProperties; // accountCreation | transactionTransfer

export const accountCreation = "accountCreation" as EstimateActionsType;
export const transactionTransfer = "transactionTransfer" as EstimateActionsType;
export const interestCreation = "interestCreation" as EstimateActionsType;

// Types relating to input and output of particular estimate actions:
export type AccountCreationParameters = {
  accountName: string; // account we are creating
  currency: string; // default account currency
  institution: Institution; // list of institutions
  newInstitutionalAccount: InstitutionAccount; // list of new institutional accounts
};

export type AccountCreationOutput = {
  accounts: Array<Book>;
};

export type InterestParameters = {
  account: Book; // account we are creating interest from
  accountBalances?: Record<string, GroupingNode>; // grouping balance up until the startDate of interest calculations
  currency: string; // currency we are calculating interest on
  startDate: Date; // starting date of interest calculations
  endDate: Date; // ending date of interest calculations
  interestRates: InstitutionInterest[]; // set of interest rates that we are applying
  userGeneratedEstimateTransactions: Array<Transaction>; // set of transactions created from other user generated estimates
  groupedBalance?: BalanceGrouping;
  startingTransactions?: Array<Transaction>;
};

export type InterestOutput = {
  transactions: Array<Transaction>;
  groupedBalance: BalanceGrouping; // note this groupedBalance contains only a day granularity
};

export type TransferParameters = {
  accountFrom: Book; // account we are creating the transfer from
  accountTo: Book; // account we are creating the transfer to
  transactionDate: Date; // transaction date
  amount: number;
  symbol: string;
  groupedBalance?: BalanceGrouping;
};

export type TransferOutput = {
  transactions: Array<Transaction>;
  groupedBalance: BalanceGrouping; // note this groupedBalance contains only a day granularity
};

export type InstitutionAccountGroup = {
  institutionsMap: Map<string, Institution>;
  newInstitutionalAccounts: InstitutionAccount[];
};
