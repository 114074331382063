import { StoreModule } from "@ngrx/store";

import { sideMenuFeatureKey, sideMenuReducer } from "../layouts/menu/state/side-menu.reducer";
import { preferenceReducer, settingsFeatureKey } from "../services/DataService/preference/state/preference.reducer";
import { referenceDataFeatureKey, referenceDataReducer } from "../services/DataService/reference-data/state/reference-data.reducer";
import { subscriptionFeatureKey, subscriptionReducer } from "../services/DataService/subscription/state/subscription.reducer";
import { revaluationFeatureKey, revaluationReducer } from "../services/DataService/transaction/state/revaluation.reducer";
import { transactionFeatureKey, transactionReducer } from "../services/DataService/transaction/state/transaction.reducer";

export const AppReducer = [
  StoreModule.forFeature(sideMenuFeatureKey, sideMenuReducer),
  StoreModule.forFeature(settingsFeatureKey, preferenceReducer),
  StoreModule.forFeature(transactionFeatureKey, transactionReducer),
  StoreModule.forFeature(revaluationFeatureKey, revaluationReducer),
  StoreModule.forFeature(referenceDataFeatureKey, referenceDataReducer),
  StoreModule.forFeature(subscriptionFeatureKey, subscriptionReducer),
];
