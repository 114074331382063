import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import {
  EstimateActionsType,
  EstimateActionsTypeClasses,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";

export class EstimateActionsFactory {
  static async createEstimateActions(
    estimateActionType: EstimateActionsType
  ): Promise<EstimateActionData> {
    const estimateActions = new EstimateActionsTypeClasses[estimateActionType](null);

    return estimateActions;
  }
}
