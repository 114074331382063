<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title>
      {{ title }}
      <button
        mat-icon-button
        class="more-button"
        [matMenuTriggerFor]="menu"
        aria-label="Toggle menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item>Expand</button>
        <button mat-menu-item>Remove</button>
      </mat-menu>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content style="display: flex; justify-content: center; align-items: center">
    <div>
      <canvas id="OwnHoldings"></canvas>
    </div>
  </mat-card-content>
</mat-card>
