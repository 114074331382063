import { Component, Input, OnInit } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";

import { TransactionService } from "../../services/DataService/transaction/transaction.service";

@Component({
  selector: "app-income-vs-expenses-card",
  templateUrl: "./income-vs-expenses-card.component.html",
})
export class IncomeVsExpensesCardComponent implements OnInit {
  @Input() title: string;

  constructor(
    private blobbyService: BlobbyService,
    private transactionService: TransactionService
  ) {}

  dataSet: Array<any>;

  async ngOnInit(): Promise<void> {
    const transactions = await this.transactionService.getAll();
    this.parseGraphData(DataTransformer.castToTransactionArray(transactions));
  }

  private parseGraphData(transactions: Array<Transaction>) {
    // only get transactions with direction out
    // sum them up based on the category-renderer split
    const newDataSet: Array<any> = [0, 0];

    if (transactions && Array.isArray(transactions)) {
      transactions.forEach((transaction) => {
        const actualQuantity = transaction.quantity.actualQuantity.amount;
        if (!isNaN(actualQuantity)) {
          if (transaction.direction == TransactionDirection.Out) {
            newDataSet[1] = newDataSet[1] + actualQuantity;
          } else {
            newDataSet[0] = newDataSet[0] + actualQuantity;
          }
        }
      });
    }

    this.dataSet = newDataSet;
  }
}
