// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { BookPipe } from "@bitwarden/web-vault/app/pipes/book.pipe";

@Component({
  selector: "app-book-renderer-cell-renderer",
  template: `{{ value }}`,
})
export class BookCellRenderer implements ICellRendererAngularComp {
  value: string;

  constructor(private bookPipe: BookPipe) {}

  agInit(params: any): void {
    if (params.value) {
      this.bookPipe.getBookName(params.value, params.data._mockAccountName).then((bookName) => {
        this.value = bookName;
      });
    } else {
      this.value = "No account found";
    }
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
