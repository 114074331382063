import { Component } from "@angular/core";

import { CipherService } from "@bitwarden/common/abstractions/cipher.service";
import { SearchService } from "@bitwarden/common/abstractions/search.service";
import { CipherView } from "@bitwarden/common/models/view/cipher.view";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { BlobbyDataTypeEnum as BType } from "@bitwarden/web-vault/app/models/enum/blobbyDataTypeEnum";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.calculation.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";

@Component({
  selector: "app-home",
  templateUrl: "./developer-test.component.html",
})
export class DeveloperTestComponent {
  constructor(
    private cipherService: CipherService,
    private blobbyService: BlobbyService,
    private transactionCalculationService: TransactionCalculationService,
    private transactionService: TransactionService
  ) {}

  panelOpenState = true;
  generateXTransaction = 5;

  transactions: Array<Transaction>;
  references: Array<ReferenceData>;

  ciphers: CipherView[] = [];
  searchService: SearchService;

  async onLoad(event?: MouseEvent) {
    await this.loadOne();
  }

  async getAll(event?: MouseEvent) {
    await this.loadAllTransaction();
  }
  async onSave(event?: MouseEvent) {
    const payload = [] as Transaction[];

    const accounts = ["City Saver", "NAB"];
    const classifications = ["Me", "Join 50/50"];
    const categories = ["Housing", "Transportation", "Food", "Utilities", "Rent"];
    const currencies = ["USD", "CAD", "HKD", "AUD"];

    for (let x = 0; x < this.generateXTransaction; x++) {
      const fakeDate = new Date();
      fakeDate.setFullYear(
        2022,
        Math.round(11 * Math.random()) + 1,
        Math.round(27 * Math.random()) + 1
      );
      fakeDate.setHours(
        Math.round(23 * Math.random()) + 1,
        Math.round(58 * Math.random()) + 1,
        Math.round(58 * Math.random()) + 1
      );

      const direction = Math.random() > 0.9 ? TransactionDirection.In : TransactionDirection.Out;
      const account = accounts[Math.round((accounts.length - 1) * Math.random())] as string;
      const currency = currencies[Math.round((currencies.length - 1) * Math.random())] as string;
      let testClassifications = Array(
        classifications[Math.round((classifications.length - 1) * Math.random())]
      ) as string[];
      let testCategories = Array(
        categories[Math.round((categories.length - 1) * Math.random())]
      ) as string[];
      let quantity = Number(x) * Math.round(100 * Math.random());
      if (direction === TransactionDirection.In) {
        testCategories = Array("Salary");
        quantity *= 5;
        testClassifications = Array("Me");
      }

      const trs = new TransactionResponse({
        transactionDate: fakeDate.toUTCString(),
        Quantity: quantity,
        Direction: direction,
        Description: "This is a descriptive description # " + x,
        Symbol: currency,
        Currency: currency,
        Account: account,
        Classifications: testClassifications,
        Categories: testCategories,
      });

      payload.push(new Transaction(trs));
    }
    await this.blobbyService.save(BType.Transaction, payload);
  }

  private async loadOne() {
    const br = await this.blobbyService.getById(
      BType.Transaction,
      "a576d084-5072-4bb2-8b70-e5bc2c8e9cf1"
    );
    this.transactions = br.transactions;
  }

  private async loadAllTransaction() {
    this.transactions = await this.transactionService.getAll();
    for (const t of this.transactions) {
      // t.setValuation();
      t.setAllocation();
    }
  }
}
