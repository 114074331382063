import { Component } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatDateRangePicker } from "@angular/material/datepicker";

import { DateRangePresetType as preset } from "@bitwarden/web-vault/app/models/enum/dateRangePresetType";

const customPresetsPast = [
  preset["3months"],
  preset["6months"],
  preset.yeartodate,
  preset.last3years,
  preset.last12Months,
];
const customPresetsCurrent = [preset.thismonth, preset.thisyear];
const customPresetsFuture = [preset.nextquarter, preset.nextyear, preset.next5years];
type CustomPreset = typeof customPresetsPast[number];

@Component({
  selector: "app-preset-date-range",
  templateUrl: "./preset-date-range.component.html",
  styles: [":host .mat-form-field {width: 100%;}"],
})
export class PresetDateRangeComponent {
  readonly customPresetsPast = customPresetsPast;
  readonly customPresetsCurrent = customPresetsCurrent;
  readonly customPresetsFuture = customPresetsFuture;

  constructor(private picker: MatDateRangePicker<Date>, private dateAdapter: DateAdapter<Date>) {}
  selectRange(rangeName: CustomPreset): void {
    const [start, end] = this.calculateDateRange(rangeName);
    this.picker.select(start);
    this.picker.select(end);
    // this.picker._applyPendingSelection();
    this.picker.close();
  }

  private calculateDateRange(rangeName: CustomPreset): [start: Date, end: Date] {
    const today = this.today;
    const year = this.dateAdapter.getYear(today);

    switch (rangeName) {
      case preset["3months"]: {
        const start = this.dateAdapter.addCalendarMonths(today, -3);
        return [start, today];
      }
      case preset["6months"]: {
        const start = this.dateAdapter.addCalendarMonths(today, -6);
        return [start, today];
      }
      case preset.yeartodate: {
        const currentYear = new Date().getFullYear();
        const firstDayOfYear = new Date(currentYear, 0, 1);
        return [firstDayOfYear, today];
      }
      case preset.last12Months: {
        const start = this.dateAdapter.addCalendarYears(today, -1);
        return [start, today];
      }
      case preset.last3years: {
        const start = this.dateAdapter.addCalendarYears(today, -3);
        return [start, today];
      }
      case preset.thismonth: {
        const start = this.startMonth(today);
        return [start, today];
      }
      case preset.thisyear: {
        const start = this.dateAdapter.createDate(year, 0, 1);
        return [start, today];
      }
      case preset.nextquarter: {
        const end = this.dateAdapter.addCalendarMonths(today, 3);
        return [today, end];
      }
      case preset.nextyear: {
        const end = this.dateAdapter.addCalendarYears(today, 1);
        return [today, end];
      }
      case preset.next5years: {
        const end = this.dateAdapter.addCalendarYears(today, 5);
        return [today, end];
      }
      default: {
        const start = today;
        const end = today;
        return [start, end];
      }
    }
  }

  private get today(): Date {
    const today = this.dateAdapter.getValidDateOrNull(new Date());
    if (today === null) {
      throw new Error("date creation failed");
    }
    return today;
  }
  private startMonth(forDay: Date): Date {
    const year = this.dateAdapter.getYear(forDay);
    const month = this.dateAdapter.getMonth(forDay);
    return this.dateAdapter.createDate(year, month, 1);
  }
}
