import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-holdings-table-card",
  templateUrl: "./holdings-table-card.component.html",
})
export class HoldingsTableCardComponent implements OnInit {
  @Input() title: string;
  @Input() holdingsData: Array<any>;

  async ngOnInit(): Promise<void> {
    return null;
  }
}
