<div class="page-content">
  <div class="row">
    <div class="col-2 groupings">
      <ul>
        <a bitMenuItem (click)="onLoad()">
          <li class="bwi bwi-fw bwi-user" aria-hidden="true"></li>
          Load
        </a>
        <a bitMenuItem (click)="onSave()">
          <li class="bwi bwi-fw bwi-user" aria-hidden="true"></li>
          Save
        </a>
        <a bitMenuItem (click)="getAll()">
          <li class="bwi bwi-fw bwi-user" aria-hidden="true"></li>
          Get all
        </a>
        <!-- Bitwarden link -->
        <a bitMenuItem routerLink="/sends">
          <i class="bwi bwi-fw bwi-user" aria-hidden="true"></i>
          {{ "send" | i18n }}
        </a>
        <a bitMenuItem routerLink="/tools">
          <i class="bwi bwi-fw bwi-user" aria-hidden="true"></i>
          {{ "tools" | i18n }}
        </a>
        <a bitMenuItem routerLink="/reports">
          <i class="bwi bwi-fw bwi-user" aria-hidden="true"></i>
          {{ "reports" | i18n }}
        </a>
        <a> </a>
      </ul>
    </div>
    <div>
      <ul>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/transactions-dashboard">{{ "Transactions" }}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/holdings-dashboard">{{ "Holdings" }}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/projections-dashboard">{{ "Projections" }}</a>
        </li>
      </ul>
    </div>
    <div class="col-10 groupings">
      <app-date-range-picker></app-date-range-picker>
      <app-transaction-table [transactions]="transactions"></app-transaction-table>
      <mat-form-field class="example-form-field">
        <mat-label>Set Number of Transaction to generate</mat-label>
        <input matInput type="number" [(ngModel)]="generateXTransaction" />
        <button
          *ngIf="generateXTransaction"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          tabindex="-1"
          (click)="generateXTransaction = 10"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title> Transaction List </mat-panel-title>
            <mat-panel-description>
              Currently I am {{ panelOpenState ? "open" : "closed" }}
            </mat-panel-description>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
