import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApmModule } from "@elastic/apm-rum-angular";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AgGridAngular } from "ag-grid-angular";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/components/customer-support-box/customer-support-box.component";
import { AppEffects } from "@bitwarden/web-vault/app/state/app.effects";
import { AppReducer } from "@bitwarden/web-vault/app/state/app.reducer";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { OssRoutingModule } from "./oss-routing.module";
import { OssModule } from "./oss.module";
import { WildcardRoutingModule } from "./wildcard-routing.module";

const NGRX_ENV = process.env.NGRX || {};

@NgModule({
  imports: [
    AgGridAngular,
    CustomerSupportBoxComponent,
    ApmModule,
    OssModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreModule,
    InfiniteScrollModule,
    DragDropModule,
    LayoutModule,
    OssRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    WildcardRoutingModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(NGRX_ENV),
    AppEffects,
    AppReducer,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
