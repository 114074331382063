import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-luxon";

// import {BlobbyService} from "@bitwarden/web-vault/app/services/blobby/blobby.service";

@Component({
  selector: "app-monthly-income-expenses-graph",
  templateUrl: "./monthly-income-expenses-graph.component.html",
})
export class MonthlyIncomeExpensesGraphComponent implements OnInit {
  @Input() dataLabels: Array<any>;
  @Input() dataSetNettIn: Array<any>;
  @Input() dataSetOut: Array<any>;
  title = "Top Expenses";
  chart: Chart;

  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.createGraph();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.dataSetNettIn || changes.dataSetOut) && this.chart) {
      this.chart.destroy();
      this.createGraph();
    }
    /*
    if ((changes.dataSetNettIn || changes.dataSetOut) && this.chart) {
      this.chart.data.datasets = [
        {
          label: 'Nett Income',
          data: this.dataSetNettIn,
          backgroundColor: 'green',
        },
        {
          label: 'Expenses',
          data: this.dataSetOut,
          backgroundColor: 'orange',
        },
      ]

      this.chart.update();
      // this.chart.resize();
    }
     */
  }

  ngOnDestroy() {
    // remove the chart
    if (this.chart) {
      this.chart.destroy();
    }
  }

  createGraph(): void {
    // console.log(this.dataSetNettIn)
    // console.log(this.dataSetOut)
    // console.log(this.dataLabels)

    this.chart = new Chart("monthlyIncomeExpensesChart", {
      type: "bar",
      data: {
        // labels: this.dataLabels,
        datasets: [
          {
            label: "Nett Income",
            data: this.dataSetNettIn,
            backgroundColor: "rgb(34,139,34)",
          },
          {
            label: "Expenses",
            data: this.dataSetOut,
            backgroundColor: "rgb(255,165,0)",
          },
        ],
      },
      options: {
        scales: {
          x: {
            stacked: true,
            type: "time",
            time: {
              parser: "dd/MM/yyyy",
              unit: "month",
              // Luxon format string
              tooltipFormat: "MMM",
              round: "month",
            },
          },
          y: {
            beginAtZero: true,
            stacked: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });
    return null;
  }
}
