import { Component, Input, OnInit } from "@angular/core";

import { GlossButtonOptions } from "@bitwarden/web-vault/app/models/types/general-types";

/**
 * GlossButtonComponent is a reusable button component for the application.
 * It accepts an options object of type GlossButtonOptions as an @Input.
 * The options object can contain iconName, buttonText, and an onClick method.
 *
 * @example
 * <app-gloss-button [options]="{ iconName: 'arrow_back', buttonText: 'Previous', onClick: previous }"></app-gloss-button>
 */

//TODO add disabled state, and loading state
@Component({
  selector: "app-gloss-button",
  templateUrl: "./gloss-button.component.html",
})
export class GlossButtonComponent implements OnInit {
  /**
   * The options object passed to the component. It should be of type GlossButtonOptions.
   * It can contain iconName, buttonText, and an onClick method.
   */
  @Input() options: GlossButtonOptions;

  ngOnInit() {
    const { onClick } = this.options;
    this.onButtonClicked = onClick;
  }

  /**
   * Method to be called when the button is clicked.
   * If an onClick method is provided in the options object, it will be called.
   */
  onButtonClicked() {
    // replaces the default method with the onClick method provided in the options object
  }

  isActionButton() {
    // todo make this array
    return this.options.buttonText === "Next" || this.options.buttonText === "Save";
  }
}
