import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Chart, registerables } from "chart.js";

@Component({
  selector: "app-holdings-owe-card",
  templateUrl: "./holdings-owe-card.component.html",
})
export class HoldingsOweCardComponent implements OnInit {
  @Input() title: string;
  @Input() holdingsOwe: Array<any>;
  @Input() holdingsOweLabels: Array<string>;
  chart: Chart;
  initialised: boolean;

  constructor() {
    Chart.register(...registerables);
    this.initialised = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.holdingsOwe || changes.holdingsOweLabel) && this.chart) {
      this.chart.data = {
        labels: this.holdingsOweLabels,
        datasets: [
          {
            data: this.holdingsOwe,
            hoverOffset: 4,
            backgroundColor: [
              "rgb(139, 0, 1)",
              "rgb(158, 23, 17)",
              "rgb(177, 46, 33)",
              "rgb(195, 70, 50)",
              "rgb(214, 93, 66)",
              "rgb(233, 116, 82)",
            ],
          },
        ],
      };
      this.chart.update();
      this.chart.resize();
    }
  }

  ngOnInit(): void {
    this.chart = new Chart("OweHoldings", {
      type: "pie",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        parsing: {
          key: "nested.value",
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
            align: "start",
          },
        },
      },
    });
    this.initialised = true;
    return null;
  }

  ngOnDestroy() {
    // remove the chart
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
