<app-spinner [showSpinner]="loading"></app-spinner>
<div class="plan-container">
  <div *ngFor="let plan of membershipPlan" class="plan-list">
    <div class="plan-label">
      <h3 class="plan-title">{{ plan.title }}</h3>
      <h1 *ngIf="plan.popular" class="plan-most-popular">{{ plan.popular }}</h1>
    </div>
    <p class="plan-description">{{ plan.description }}</p>
    <h2 class="plan-price">{{ plan.priceLabel }}</h2>
    <span class="plan-price-detail">{{ plan.detail }}<br />{{ plan.billing }}</span>
    <div class="plan-button-container">
      <button class="plan-button" mat-raised-button color="primary" (click)="handlePlanSubmit(plan)">
        {{ "select" | i18n }}
      </button>
      <p>
        <a routerLink="/register" class="plan-trial">{{ "trial" | i18n }}</a>
      </p>
    </div>
  </div>
</div>
