import { Scenario } from "@bitwarden/web-vault/app/models/data/blobby/scenario.data";
import { ScenarioResponse } from "@bitwarden/web-vault/app/models/data/response/scenario.response";
import {
  bestExistingInterestRate,
  bestNewInterestRate,
  currentInterestRate,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
// Todo should move the response to the screnario-response class so it will be easier to change if the model gets a version update
export const CurrentScenario: any = {
  __v: 1,
  _id: crypto.randomUUID(),
  _dateModified: new Date().toUTCString(),
  _dateCreated: new Date().toUTCString(),
  name: "Current Account Growth",
  symbol: "AUD",
  scenarioType: currentInterestRate,
  estimateActionTypes: [],
  estimateActions: [],
};

export const BestExistingAccountScenario: Scenario = new Scenario(
  new ScenarioResponse({
    __v: 1,
    _id: crypto.randomUUID(),
    _dateModified: new Date().toUTCString(),
    _dateCreated: new Date().toUTCString(),
    name: "Best Existing Account",
    symbol: "AUD",
    scenarioType: bestExistingInterestRate,
    estimateActions: [],
    estimateActionTypes: [],
  })
);
export const BestNewAccountScenario: Scenario = new Scenario(
  new ScenarioResponse({
    __v: 1,
    _id: crypto.randomUUID(),
    _dateModified: new Date().toUTCString(),
    _dateCreated: new Date().toUTCString(),
    name: "Top Regional Account",
    symbol: "AUD",
    scenarioType: bestNewInterestRate,
    estimateActionTypes: [],
    estimateActions: [],
  })
);

export const SystemDefaultScenarios: Scenario[] = [
  CurrentScenario,
  BestExistingAccountScenario,
  BestNewAccountScenario,
];
