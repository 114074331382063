<div class="holdings-table-container">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
      <td mat-cell *matCellDef="let element" class="holdings-table__column-text">
        {{ element.account | bookPipe: "getBookName" | async }}
      </td>
    </ng-container>
    <ng-container matColumnDef="classification">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Classification</th>
      <td mat-cell *matCellDef="let element" class="holdings-table__column-text">
        {{ element.classification | classificationPipe: "getClassificationName" | async }}
      </td>
    </ng-container>
    <ng-container matColumnDef="own">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Own</th>
      <td mat-cell *matCellDef="let element" class="holdings-table__column-number">
        <span *ngIf="element.quantity > 0 || element.quantity == 0">{{ element.quantity }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="owe">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Owe</th>
      <td mat-cell *matCellDef="let element" class="holdings-table__column-number">
        <span *ngIf="element.quantity < 0">{{ element.quantity }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of Holdings"
  >
  </mat-paginator>
</div>
