import { Component, Input, OnInit } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";

import { TransactionService } from "../../services/DataService/transaction/transaction.service";

@Component({
  selector: "app-top-expenses-card",
  templateUrl: "./top-expenses-card.component.html",
})
export class TopExpensesCardComponent implements OnInit {
  @Input() title: string;

  constructor(
    private blobbyService: BlobbyService,
    private transactionService: TransactionService,
    private categoryService: CategoryService
  ) {}

  dataSetOut: Array<any>;
  dataLabels: Array<string>;

  async ngOnInit(): Promise<void> {
    const transactions = await this.transactionService.getAll();
    await this.parseGraphData(DataTransformer.castToTransactionArray(transactions));
  }

  private async parseGraphData(transactions: Array<Transaction>) {
    // only get transactions with direction out
    // sum them up based on the category-renderer split
    const newDataSetOut: Array<any> = [];
    const newDataLabels: Array<string> = [];

    if (transactions && Array.isArray(transactions)) {
      for (const transaction of transactions) {
        const actualQuantity = transaction.quantity.actualQuantity.amount;
        if (transaction.direction == TransactionDirection.Out) {
          const categoryList = transaction.categories;
          let category = "No Category";
          if (categoryList) {
            // TODO: come back and fix this when we have allocations
            if (Array.isArray(categoryList) && categoryList.length > 0) {
              const firstCategory = await this.categoryService.get(categoryList[0].categoryId);
              if (firstCategory) {
                category = firstCategory.name;
              }
            }
          }
          if (!newDataLabels.includes(category)) {
            newDataLabels.push(category);
            newDataSetOut.push({ quantity: actualQuantity, category: category });
          } else {
            const index = newDataLabels.indexOf(category);
            newDataSetOut[index].quantity = newDataSetOut[index].quantity + actualQuantity;
          }
        }
      }
    }

    newDataSetOut.sort((a, b): number => {
      if (a.quantity > b.quantity) {
        return -1;
      } else if (a.quantity < b.quantity) {
        return 1;
      } else {
        return a.category.localeCompare(b.category);
      }
    });

    const finalDataSetOut: Array<any> = [];
    const finalDataLabels: Array<string> = [];

    newDataSetOut.forEach((element, index) => {
      finalDataSetOut[index] = -element.quantity;
      finalDataLabels[index] = element.category;
    });

    this.dataSetOut = finalDataSetOut.slice(0, 10);
    this.dataLabels = finalDataLabels.slice(0, 10);
  }
}
