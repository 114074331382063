import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { UserVerificationService } from "@bitwarden/common/abstractions/userVerification/userVerification.service.abstraction";
import { Verification } from "@bitwarden/common/types/verification";
import { BasiqIoMapper } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/basiq-io/basiq-io-mapper";
import { Preference } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { BlobbyDataTypeEnum } from "@bitwarden/web-vault/app/models/enum/blobbyDataTypeEnum";
import { StoreState } from "@bitwarden/web-vault/app/services/DataService/state-management/state.management";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import Actions from "@bitwarden/web-vault/app/state/app.actions";

@Component({
  selector: "app-purge-vault",
  templateUrl: "purge-vault.component.html",
})
export class PurgeVaultComponent {
  @Input() organizationId?: string = null;

  masterPassword: Verification;
  formPromise: Promise<unknown>;

  constructor(
    private apiService: ApiService,
    private i18nService: I18nService,
    private platformUtilsService: PlatformUtilsService,
    private userVerificationService: UserVerificationService,
    private router: Router,
    private logService: LogService,
    private blobbyService: BlobbyService,
    private dashboardService: DashboardService,
    private store: Store<StoreState>
  ) {}

  async submit() {
    try {
      const blobbyResponse = await this.blobbyService.getAll(BlobbyDataTypeEnum.Preference);
      const existingPref = blobbyResponse.preferences[0];
      localStorage.setItem("basiqUser", JSON.stringify(existingPref.basiqUser));
      this.formPromise = this.userVerificationService
        .buildRequest(this.masterPassword)
        .then((request) => this.apiService.postPurgeCiphers(request, this.organizationId));

      await this.formPromise;
      await this.refreshAppService();

      await this.clearState(existingPref);
      this.platformUtilsService.showToast("success", null, this.i18nService.t("vaultPurged"));
      BasiqIoMapper.refresh = true;
      if (this.organizationId != null) {
        this.router.navigate(["organizations", this.organizationId, "vault"]);
      } else {
        this.router.navigate(["primary-dashboard"]);
      }
    } catch (e) {
      this.logService.error(e);
    }
  }

  async refreshAppService() {
    await this.blobbyService.refresh();
    await this.dashboardService.refresh(true);
  }

  private async clearState(existingPreference: Preference) {
    const { PreferenceActions, TransactionActions, RevaluationActions } = Actions;

    this.store.dispatch(PreferenceActions.clear());
    this.store.dispatch(TransactionActions.clear());
    this.store.dispatch(RevaluationActions.clear());

    /*TODO : Ask Ronald how to do it with state management . The idea is to keep basiq user when purged */
    const blobbyResponse = await this.blobbyService.getAll(BlobbyDataTypeEnum.Preference);
    const preference = blobbyResponse.preferences[0];
    preference.basiqUser = existingPreference.basiqUser;
    await this.blobbyService.update(BlobbyDataTypeEnum.Preference, preference);
  }
}
