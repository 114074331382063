import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import {
  BasiqToken,
  BasiqConnection,
  BasiqConnectionResponse,
  BasiqConsentReturnObject,
  BasiqUserType,
  BasiqInstitution,
  BasiqResponse,
  BasiqJob,
} from "@bitwarden/web-vault/app/models/types/basiq.types";
import { BasiqConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { GlossApiProxyRequest } from "@bitwarden/web-vault/app/shared/utils/helper.glos-api-call/gloss-api-proxy-request";

import { DataRepositoryService } from "../DataRepository/data-repository.service";

const basiqConfig = process.env.BASIQ as unknown as BasiqConfigType;
export type GlossApiRequest = {
  method: "POST" | "GET" | "PUT" | "DELETE";
  path: string;
  data: Record<string, any>;
  authed: boolean;
  hasResponse: boolean;
  url: string;
};
@Injectable({
  providedIn: "root",
})
export class BasiqIoConfigService {
  readonly product: Array<string> = ["transactions"];
  private readonly glossApiUrl: string;

  constructor(
    private http: HttpClient,
    private dataRepositoryService: DataRepositoryService,
    private apiService: ApiService,
    private globalService: GlobalService,
    private logger: LogService
  ) {
    this.glossApiUrl = `${basiqConfig.url}/${basiqConfig.apiStage}`;
  }

  /**
   * @async
   * @param {BasiqUserType} basiqUser - The Basiq user for whom to fetch account information.
   * @returns {Promise<BasiqResponse>} - A promise that resolves to the Basiq response containing account information.
   * @throws {Error} - Throws an error if the request fails, logging the error and showing a warning message.
   */
  async getAccounts(basiqUser: BasiqUserType): Promise<BasiqResponse> {
    try {
      const data = {
        endpoint: `/users/${basiqUser.id}/accounts/`,
        action: "GET",
      };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to retrieve Consent: ${error}`);
      const d = error.response.data[0];
      this.globalService.showMessageAsIs("warning", d.title, d.detail);
    }
  }

  /**
   * @async
   * @param {string} endpoint - The endpoint from which to fetch transaction information.
   * @returns {Promise<any>} - A promise that resolves to the transaction data.
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getTransactions(endpoint: string): Promise<BasiqResponse> {
    try {
      const data = { endpoint };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }

  /**
   * @async
   * @param {BasiqUserType} basiqUser - The Basiq user for whom to fetch connections.
   * @returns {Promise<BasiqResponse>} - A promise that resolves to the Basiq response containing the connections.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getConnections(basiqUser: BasiqUserType): Promise<BasiqResponse> {
    try {
      const data = { endpoint: `/users/${basiqUser.id}/connections/` };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }

  async getAccountConnections(
    basiqUser: BasiqUserType,
    accountView: AccountView
  ): Promise<BasiqConnectionResponse> {
    try {
      const data = {
        endpoint: `/users/${basiqUser.id}/connections/?filter=status.eq%28%27active%27%29&institution.id.eq.eq%28%27${accountView.institution.basiqId}%27%29`,
      };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }

  /**
   * @async
   * @param {BasiqUserType} basiqUser - The Basiq user for whom to fetch the connection.
   * @param {string} connectionId - The ID of the connection to fetch.
   * @returns {Promise<BasiqConnectionResponse>} - A promise that resolves to the Basiq response containing the connection.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getConnectionById(
    basiqUser: BasiqUserType,
    connectionId: string
  ): Promise<BasiqConnectionResponse> {
    const data = { endpoint: `/users/${basiqUser.id}/connections/${connectionId}` };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @param {string} institutionId - The ID of the institution to fetch.
   * @returns {Promise<BasiqInstitution>} - A promise that resolves to the Basiq response containing the institution.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getInstitutionById(institutionId: string): Promise<BasiqInstitution> {
    const data = { endpoint: `/institutions/${institutionId}` };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @param {string} jobId - The ID of the job to fetch.
   * @returns {Promise<BasiqJob>} - A promise that resolves to the Basiq response containing the job.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.

   */
  async getJob(jobId: string): Promise<BasiqJob> {
    const data = { endpoint: `/jobs/${jobId}` };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @param {BasiqUserType} basiqUser - The Basiq user for whom to fetch the consent.
   * @returns {Promise<BasiqConsentReturnObject>} - A promise that resolves to the Basiq response containing the consent.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.

   */
  async getConsent(basiqUser: BasiqUserType): Promise<BasiqConsentReturnObject> {
    const data = { endpoint: `/users/${basiqUser.id}/consents` };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @returns {Promise<BasiqUserType>} - A promise that resolves to the newly created Basiq user.
   *
   * @throws {Error} - Throws an error if the request to the Basiq API fails, logging the error.
   */
  async createBasiqIoUser(): Promise<BasiqUserType> {
    try {
      const path = basiqConfig.endpoint.create_user;
      return await this.dataRepositoryService.send(
        "POST",
        path,
        null,
        true,
        true,
        this.glossApiUrl
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Plaid token:", error);
      throw error;
    }
  }

  /**
   * @async
   * @param {BasiqConnection} connection - The connection to be refreshed.
   * @param {BasiqUserType} basiqUser - The Basiq user associated with the connection.
   * @returns {Promise<BasiqJob>} - A promise that resolves to the Basiq job associated with the refresh operation.
   *
   * @throws {Error} - Throws an error if the request to the Basiq API fails, logging the error.
   */
  async refreshConnection(
    connection: BasiqConnection,
    basiqUser: BasiqUserType
  ): Promise<BasiqJob> {
    const data = {
      endpoint: `/users/${basiqUser.id}/connections/${connection.id}/refresh`,
      action: "POST",
    };
    return await this.getProxyGlossApi(data);
  }

  async createClientAccessToken(basiqUser: BasiqUserType): Promise<BasiqToken> {
    try {
      const path = basiqConfig.endpoint.create_client_access_token;

      return await this.dataRepositoryService.send(
        "POST",
        path,
        {
          userData: basiqUser,
        },
        true,
        true,
        this.glossApiUrl
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Plaid token:", error);
      throw error;
    }
  }

  async retrieveTransactions(
    basiqUser: BasiqUserType,
    limit: number,
    lastTransactionDate: string,
    account: Book,
    nextPath?: string
  ): Promise<BasiqResponse> {
    try {
      const path = this.getBasiqPathToTransactions(
        nextPath,
        basiqUser,
        limit,
        lastTransactionDate,
        account
      );
      return await this.getTransactions(path);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Basiq token:", error);
    }
  }

  getBasiqPathToTransactions(
    nextPath: string,
    basiqUser: BasiqUserType,
    limit: number,
    lastTransactionDate: string,
    account: Book
  ) {
    let path = `/users/${basiqUser.id}/transactions`;
    let connector = "?";
    if (nextPath) {
      return nextPath
        .replace(basiqConfig.basiqUrl, "")
        .replace(/\(/g, "%28")
        .replace(/'/g, "%27")
        .replace(/\)/g, "%29");
    }

    if (limit) {
      path = `${path}${connector}limit=${limit}`;
      connector = "&";
    }

    if (account) {
      path = `${path}${connector}filter=account.id.eq%28%27${account.id}%27%29`;
      connector = ",";
    }

    if (lastTransactionDate) {
      /*TODO : do a proper encoding here . Some how encodeURL is not working */
      const encodedValue = `%28%27${lastTransactionDate}%27%29`;
      path = `${path}${connector}transaction.postDate.gteq${encodedValue}`;
    }

    return path;
  }

  async getProxyGlossApi(data: any) {
    try {
      const glossRequestObject = new GlossApiProxyRequest(data);
      return await this.dataRepositoryService.send(
        glossRequestObject.method,
        glossRequestObject.path,
        glossRequestObject.data,
        glossRequestObject.authed,
        glossRequestObject.hasResponse,
        glossRequestObject.url,
        glossRequestObject.alterHeaders
      );
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }
}
