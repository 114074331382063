// todo create our own implementation of rounding with precision and drop the need of mathjs
import * as math from "mathjs";
/**
 * Gloss number will be used throughout to system to represent a quantity or an amount.
 * It makes sense that most numbers need to be tied to a symbol be that a quantity or a
 * currency amount and the GlossNumber is how we will keep that consistent across the
 * Gloss.
 * This will allow us to handle Balances and summations more elegantly in a more
 * uniform manner.
 */
export class GlossNumber {
  private _amount: number;
  private _symbol: string;
  private _precision: number;

  constructor() {
    this.setDefaults();
  }

  get amount(): number {
    return math.round(this._amount, this._precision);
  }

  set amount(value: number) {
    this._amount = value;
  }

  get symbol(): string {
    return this._symbol;
  }

  set symbol(value: string) {
    this._symbol = value;
  }

  get precision(): number {
    return this._precision;
  }

  set precision(value: number) {
    this._precision = value;
  }

  /**
   * Sets the default values for the GlossNumber
   * @private
   */
  private setDefaults() {
    this.amount = 0;
    this.precision = 8;
  }

  /**
   * Get the default precision for this symbol and set this as the precision for this gloss number
   * @param symbol
   */
  setDefaultPrecision() {
    if (this.symbol) {
      // get the precision for the symbol
      const precision = 8;
      // TODO: fix this when the symbolService is up and running correctly with precisions
      // const precision = symbolService.getPrecision(this._symbol)
      this.precision = precision;
    }
  }

  /**
   * Updated the applyPrecision function to be for display as opposed to number storage.
   * Need to decide if we save to a finite number of decimal places once we overhaul glossNumber
   * to use the BigNumber library
   * @private
   */
  private applyPrecisionForDisplay() {
    this.amount = Number(this.amount.toFixed(this.precision));
  }

  setToGlossNumberObj(response: Record<string, any> | GlossNumber) {
    return response instanceof GlossNumber
      ? this.setFromObject(response)
      : this.setFromQueryString(response);
  }

  setFromObject(glossNumber: GlossNumber) {
    return glossNumber;
  }

  setFromQueryString(response: Record<string, any>) {
    if (response.amount || response._amount) {
      this.amount = response.amount || response._amount;
    }
    if (response.symbol || response._symbol) {
      this.symbol = response.symbol || response._symbol;
    }
    return this;
  }

  add(additionalAmount: GlossNumber) {
    // check that the symbols are the same
    if (this.symbol === additionalAmount.symbol) {
      this.amount = this.amount + additionalAmount.amount;
      // Note: removed precision for now to see if it helps the javascript decimal error
      // this.applyPrecision();
    } else {
      return new Error("Cannot add two Gloss Numbers which have different symbols");
    }
  }

  subtract(subAmount: GlossNumber) {
    // check that the symbols are the same
    if (this.symbol === subAmount.symbol) {
      this.amount = this.amount - subAmount.amount;
      // Note: removed precision for now to see if it helps the javascript decimal error
      // this.applyPrecision();
    } else {
      return new Error("Cannot add two Gloss Numbers which have different symbols");
    }
  }

  copyFrom(copy: GlossNumber) {
    this.amount = copy.amount;
    this.symbol = copy.symbol;
    this.precision = copy.precision;
  }

  copy(): GlossNumber {
    const copyGlossNum = new GlossNumber();
    copyGlossNum.amount = this.amount;
    copyGlossNum.symbol = this.symbol;
    copyGlossNum.precision = this.precision;
    return copyGlossNum;
  }
}
