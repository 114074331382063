import { Component, Inject, Injector, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import _ from "lodash";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import {
  previousButtonOptions,
  checkButtonOptions,
  currencyInputOptions,
  institutionInputOptions,
  accountInputOptions,
} from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/accounts-add-edit/component.options";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { BookResponse } from "@bitwarden/web-vault/app/models/data/response/book.response";
import {
  GlossButtonOptions,
  GlossInputOptions,
  Origin,
} from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { EstimateActionsService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-actions-service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
// import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

import { Book } from "../../../../models/data/blobby/book.data";
import { Category } from "../../../../models/data/blobby/category.data";
import { Classification } from "../../../../models/data/blobby/classification.data";
import { BookService } from "../../../../services/DataService/book/book.service";
@Component({
  selector: "account-add-edit",
  templateUrl: "account-add-edit.component.html",
})
export class AccountAddEditComponent implements OnInit {
  form: FormGroup;
  accountName: string;
  accountId: string;
  cat: string;
  // filteredCategories: Array<Category>;
  categories: Array<Category>;
  accountBalance: number;
  currency: string;
  // filteredClassifications: Array<Classification>;
  classifications: Array<Classification>;
  institutions: Array<Institution>;
  accountTypes: Array<InstitutionAccount>;
  loading = true;
  editMode = false;
  isInstitutionDisabled = false;
  isCategoryOpen = false;
  isClassificationOpen = false;
  title: string;
  selectedClassifications: SplitClassificationType[] = [];
  selectedCategories: SplitCategoryType[] = [];
  selectedInstitution: Institution;
  selectedAccountType: InstitutionAccount;

  private classificationService: ClassificationService;
  private categoryService: CategoryService;

  previousButtonOptions: GlossButtonOptions = {
    ...previousButtonOptions,
    onClick: this.goBack.bind(this),
  };

  checkButtonOptions: GlossButtonOptions = {
    ...checkButtonOptions,
    onClick: this.submit.bind(this),
  };

  accountInputOptions: GlossInputOptions = {
    ...accountInputOptions,
    inputBlurred: (value: string) => this.accountNameSet(value),
    inputCleared: () => this.nameCleared(),
  };

  currencyInputOptions: GlossInputOptions = currencyInputOptions;

  institutionInputOptions: GlossInputOptions = institutionInputOptions;

  @ViewChild("container", { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      account: AccountView;
      actionSucceeded: CallableFunction;
      delete: CallableFunction;
      institution: Institution;
      accountType: InstitutionAccount;
      closeDialogue: CallableFunction;
      openInstitutionSelectionModal: CallableFunction;
    },
    private bookService: BookService,
    private estimateService: EstimateActionsService,
    private globalService: GlobalService,
    private dataRepositoryService: DataRepositoryService,
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService,
    private transactionService: TransactionService,
    private transactionNormalizeService: TransactionNormalizeService,
    private injector: Injector
  ) {
    this.editMode = !!this.data.account;
  }

  async ngOnInit() {
    await this.setForm();

    this.setInstitution();
    this.initializeForm();

    this.institutionInputOptions.value = this.selectedInstitution.name;
    this.loading = false;
  }

  async setForm() {
    if (this.editMode) {
      await this.setFormToUpdate();
    } else {
      await this.setFormToCreate();
    }
  }

  async setFormToUpdate() {
    this.title = "Edit account";
    await this.getData();
    this.accountInputOptions = { ...this.accountInputOptions, value: this.data.account.name };
    this.currencyInputOptions = { ...this.currencyInputOptions, value: this.data.account.currency };
    this.institutionInputOptions = {
      ...this.institutionInputOptions,
      value: this.data.account.institution.name,
    };
    this.selectedInstitution = this.data.account.institution;
    this.selectedAccountType = this.data.account.institutionAccountType;
    this.accountName = this.data.account.name;
    this.accountTypes = this.selectedInstitution.availableAccounts;
    this.selectedCategories = this.data.account.defaultCategories;
    this.selectedClassifications = this.data.account.defaultClassifications;
  }

  async setFormToCreate() {
    this.title = "Add Account";
    this.selectedInstitution = this.data.institution;
    this.accountTypes = this.selectedInstitution.availableAccounts;
    this.accountInputOptions.value = "";
    this.currencyInputOptions.value = "AUD";
    this.institutionInputOptions.value = this.selectedInstitution.name;

    this.isInstitutionDisabled = true;
    await this.getData();
  }

  async getData() {
    this.categories = await this.dataRepositoryService.getAllCategories();
    this.classifications = await this.dataRepositoryService.getAllClassifications();
    this.institutions = await this.dataRepositoryService.getAllInstitutions();
  }

  setInstitution() {
    if (this.editMode) {
      this.selectedInstitution = this.data.account.institution;
      this.accountTypes = this.selectedInstitution.availableAccounts;
      this.selectedAccountType = this.data.account.institutionAccountType;
    } else {
      this.selectedInstitution = this.data.institution;
      this.accountTypes = this.selectedInstitution.availableAccounts;
    }
  }

  goBack() {
    if (this.data.closeDialogue && this.data.openInstitutionSelectionModal) {
      this.data.closeDialogue();
      this.data.openInstitutionSelectionModal();
    } else {
      /*TODO tell user to start over instead of saying something went wrong!!*/
      this.globalService.showWarningMessage("warning", "somethingWentWrong");
    }
  }

  private getEmptyFormControls(): any {
    const { origin } = this.data.account ?? { origin: "manual" };
    const isDisabled = origin !== Origin.manual;

    return {
      accountName: [{ value: "", disabled: isDisabled }, Validators.required],
      selectedCategory: [{ value: null }],
      selectedClassification: [{ value: null }],
      selectedInstitution: [{ value: this.selectedInstitution, disabled: isDisabled }],
      selectedAccountType: null,
      categories: null,
      classifications: null,
    };
  }

  private filledFormControls(): any {
    const {
      origin,
      name,
      defaultClassifications,
      defaultCategories,
      institution,
      institutionAccountType,
    } = this.data.account;
    const isDisabled = origin !== Origin.manual;
    return {
      accountName: [{ value: name, disabled: isDisabled }, Validators.required],
      selectedCategory: defaultCategories,
      selectedClassification: defaultClassifications,
      selectedInstitution: [{ value: institution, disabled: isDisabled }],
      selectedAccountType: institutionAccountType,
      categories: null,
      classifications: null,
    };
  }

  private getFormControls() {
    if (this.editMode) {
      return this.filledFormControls();
    } else {
      return this.getEmptyFormControls();
    }
  }

  initializeForm() {
    const formControls = this.getFormControls();
    this.form = this.formBuilder.group(formControls);

    this.categories.forEach((cat, index) => {
      this.form.addControl(`category_${cat.id}`, new FormControl(0));
    });

    this.classifications.forEach((cat, index) => {
      this.form.addControl(`classification_${cat.id}`, new FormControl(0));
    });
  }

  accountNameSet(name: string) {
    this.accountName = name;
  }

  nameCleared() {
    this.accountName = null;
  }

  async submit() {
    if (!this.accountName || this.accountName === "") {
      this.globalService.showErrorMessage("errorOccurred", "accountNameEmpty");
      return;
    }

    if (!this.selectedAccountType) {
      this.globalService.showErrorMessage("errorOccurred", "accountTypeEmpty");
      return;
    }

    this.loading = true;
    if (this.editMode) {
      await this.updateAccount();
    } else {
      await this.createAccount();
    }

    this.loading = false;
  }

  getSelectedSplitCategories(): SplitCategoryType[] {
    const splitCategories: SplitCategoryType[] = [];
    for (const key in this.form.controls) {
      if (key.startsWith("category")) {
        const value = this.form.controls[key].value;
        const keySplits = key.split("_");
        if (value !== 0 && keySplits[1]) {
          splitCategories.push({
            categoryId: keySplits[1],
            weight: value,
            roundingDefault: false,
          });
        }
      }
    }

    return splitCategories;
  }

  getSelectedSplitClassifications(): SplitClassificationType[] {
    const splitClassifications: SplitClassificationType[] = [];
    for (const key in this.form.controls) {
      if (key.startsWith("classification")) {
        const value = this.form.controls[key].value;
        const keySplits = key.split("_");
        if (value !== 0 && keySplits[1]) {
          splitClassifications.push({
            classificationId: keySplits[1],
            weight: value,
            roundingDefault: false,
          });
        }
      }
    }
    return splitClassifications;
  }
  private async createAccount() {
    try {
      const isInstitutionAccount = await this.institutionService.isInstitutionExist(
        this.selectedInstitution
      );
      if (!isInstitutionAccount) {
        await this.institutionService.create(this.selectedInstitution);
      }
      const newBook = new Book(new BookResponse(""));
      newBook.name = this.accountName;
      newBook.currency = this.currency;
      /*    newBook.defaultClassifications = this.getSelectedSplitClassifications();
      // if there is no defaultClassification created, then create one called 'Default Classification'
      if (newBook.defaultClassifications.length === 0) {
        newBook.defaultClassifications = await this.setGeneralDefaultClassification();
      }
      newBook.defaultCategories = this.getSelectedSplitCategories();
      // if there is no defaultClassification created, then create one called 'Default Classification'
      if (newBook.defaultCategories.length === 0) {
        newBook.defaultCategories = await this.setGeneralDefaultCategories();
      }*/

      /** todo : if the book-renderer, aka newBook,  has a preferred institution account then link it as well */
      newBook.institutionLink = {
        institutionId: this.selectedInstitution.id,
        institutionAccountId: this.selectedAccountType ? this.selectedAccountType.id : null,
      };

      if (!newBook?.currency) {
        newBook.currency = "AUD";
      }

      const createdAccount = await this.bookService.create(newBook);

      if (createdAccount instanceof Book) {
        await this.estimateService.generateAutoBookEstimates(createdAccount);
        /*       if (createdAccount.balance !== 0) {
          const transaction = await this.transactionService.createAccountAlignmentTransaction(
            createdAccount
          );
          if (transaction instanceof Transaction) {
            await this.transactionNormalizeService.refreshMarketData([transaction]);
          }
        }*/
        this.data.actionSucceeded("createdSuccessfully");
        this.data.closeDialogue();
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  private async updateAccount() {
    try {
      const clonedAccount = _.cloneDeep(this.data.account.originalBook);
      clonedAccount.name = this.accountName;
      /* clonedAccount.defaultClassifications = this.getSelectedSplitClassifications();
      clonedAccount.defaultCategories = this.getSelectedSplitCategories();*/

      clonedAccount.institutionLink = {
        institutionId: this.selectedInstitution.id,
        institutionAccountId: this.selectedAccountType ? this.selectedAccountType.id : null,
      };

      const updatedAccount = await this.bookService.update(clonedAccount, false);
      if (updatedAccount instanceof Book) {
        /*      if (updatedAccount.balance !== 0 && updatedAccount.balance !== this.data.account.balance) {
          const transaction = await this.transactionService.createAccountAlignmentTransaction(
            updatedAccount
          );
          if (transaction instanceof Transaction) {
            await this.transactionNormalizeService.refreshMarketData([transaction]);
          }
        }*/
        this.data.actionSucceeded("updatedSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async deleteAccount() {
    // TODO check if it is a callable
    this.data.delete(this.data.account.originalBook);
  }

  selectCurrency(event: MatSelectChange) {
    const selectedValue = event.value;
    this.form.get("currency")?.setValue(selectedValue);
  }

  onInstitutionSelect(selected: any) {
    this.selectedInstitution = selected.value;
    this.selectedAccountType = null;
    this.accountTypes = this.selectedInstitution.availableAccounts;
  }

  onSelectAccountType(selected: InstitutionAccount) {
    this.selectedAccountType = selected;
  }

  openCategoriesPanel() {
    this.isCategoryOpen = !this.isCategoryOpen;
    this.isClassificationOpen = false;
    if (!this.categories.length) {
      this.globalService.showMessage("info", "categoriesEmpty", "pleaseAddSomeCategories");
    }
  }

  openClassificationsPanel() {
    this.isClassificationOpen = !this.isClassificationOpen;
    this.isCategoryOpen = false;
    if (!this.classifications.length) {
      this.globalService.showMessage(
        "info",
        "classificationsEmpty",
        "pleaseAddSomeClassifications"
      );
    }
  }

  /**
   * Get or create a general default classification to assign to as the default classification
   * for this account that is being created
   */
  async setGeneralDefaultClassification(): Promise<SplitClassificationType[]> {
    this.classificationService = this.injector.get(ClassificationService);
    const defaultClassification =
      await this.classificationService.getGeneralDefaultClassification();
    const defaultSplitClassifications: SplitClassificationType[] = [];
    defaultSplitClassifications.push({
      classificationId: defaultClassification.id,
      weight: 1,
      roundingDefault: true,
      name: defaultClassification.name,
    });
    return defaultSplitClassifications;
  }

  /**
   * Get or create a general default classification to assign to as the default classification
   * for this account that is being created
   */
  async setGeneralDefaultCategories(): Promise<SplitCategoryType[]> {
    this.categoryService = this.injector.get(CategoryService);
    const defaultCategory = await this.categoryService.getGeneralDefaultCategory();
    const defaultSplitCategories: SplitCategoryType[] = [];
    defaultSplitCategories.push({
      categoryId: defaultCategory.id,
      weight: 1,
      roundingDefault: true,
      name: defaultCategory.name,
    });
    return defaultSplitCategories;
  }

  protected readonly Origin = Origin;
  protected readonly process = process;
}
