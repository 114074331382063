import { Component, ViewChild, ViewContainerRef } from "@angular/core";

import { ModalService } from "@bitwarden/angular/services/modal.service";
import { MessagingService } from "@bitwarden/common/abstractions/messaging.service";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";

import { DeauthorizeSessionsComponent } from "./deauthorize-sessions.component";
import { DeleteAccountComponent } from "./delete-account.component";
import { PurgeVaultComponent } from "./purge-vault.component";

@Component({
  selector: "app-account",
  templateUrl: "account.component.html",
})
export class AccountComponent {
  protected readonly RoleScope = RoleScope;

  @ViewChild("deauthorizeSessionsTemplate", { read: ViewContainerRef, static: true })
  deauthModalRef: ViewContainerRef;
  @ViewChild("purgeVaultTemplate", { read: ViewContainerRef, static: true })
  purgeModalRef: ViewContainerRef;
  @ViewChild("deleteAccountTemplate", { read: ViewContainerRef, static: true })
  deleteModalRef: ViewContainerRef;

  constructor(
    private modalService: ModalService,
    private transactionService: TransactionService,
    private messagingService: MessagingService,
    private blobbyService: BlobbyService
  ) {}

  async deauthorizeSessions() {
    await this.modalService.openViewRef(DeauthorizeSessionsComponent, this.deauthModalRef);
  }

  async purgeVault() {
    await this.modalService.openViewRef(PurgeVaultComponent, this.purgeModalRef);
  }

  async deleteAccount() {
    await this.modalService.openViewRef(DeleteAccountComponent, this.deleteModalRef);
  }

  async DumpVault() {
    const transaction = await this.transactionService.getAll();
    const content = JSON.stringify(transaction, null, 2);

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    element.setAttribute("download", "Vault-Transactions.json");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  lock() {
    this.messagingService.send("lockVault");
  }

  logOut() {
    this.blobbyService.setInitialised(false);
    this.messagingService.send("logout");
  }
}
