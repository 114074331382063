import { Component, Input, OnInit } from "@angular/core";

import { Transaction } from "../../models/data/blobby/transaction.data";
import { TransactionView } from "../../models/view/transaction.view";
import { TransactionService } from "../../services/DataService/transaction/transaction.service";
import { BlobbyService } from "../../services/blobby/blobby.service";

@Component({
  selector: "app-transactions-table-card",
  templateUrl: "./transactions-table-card.component.html",
})
export class TransactionsTableCardComponent implements OnInit {
  @Input() title: string;

  transactions: Array<Transaction>;
  transactionsView: Array<TransactionView>;

  constructor(
    private blobbyService: BlobbyService,
    private transactionService: TransactionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.transactions = await this.transactionService.getAll();

    if (this.transactions) {
      this.transactionsView = this.transactions.map(
        (transaction: Transaction) => new TransactionView(transaction)
      );
    }
  }
}
