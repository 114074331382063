import { Component, EventEmitter, Output } from "@angular/core";

import "./currencies.component.style.scss";
import { SystemCurrenciesString } from "@bitwarden/web-vault/app/models/types/system-currencies.type";

/**
 * CountriesComponent is a component that provides a form field for selecting countries.
 * It uses Angular Material's autocomplete feature to suggest countries as the user types.
 * The selected country is emitted as events.
 * TODO: Add a feature to select multiple countries.
 * TODO: Add a feature of custom styling.
 */
@Component({
  selector: "app-currencies",
  templateUrl: "./currencies.component.html",
  styles: ["currencies.component.style.scss"],
})
export class CurrenciesComponent {
  currencies: string[] = SystemCurrenciesString;

  /**
   * Event that is emitted when a currency is selected.
   */
  @Output() selectedCurrency: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Event that is emitted when multiple currencies are selected.
   */
  @Output() selectedCurrencies: EventEmitter<string[]> = new EventEmitter<string[]>();

  /**
   * Emits the selectedCountry event with the provided country.
   * @param {string} currency - The country that was selected.
   */
  currencySelected(currency: string) {
    if (this.selectedCurrency) {
      this.selectedCurrency.emit(currency);
    }
  }
  /**
   * Clears the selection of the country.
   * This method is used to emit a null value to the `selectedCountry` event emitter,
   * indicating that the country selection has been cleared.
   */
  selectionCleared() {
    this.selectedCurrency.emit(null);
  }
}
