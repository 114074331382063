import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Chart, registerables } from "chart.js";

@Component({
  selector: "app-holdings-net-card",
  templateUrl: "./holdings-net-card.component.html",
})
export class HoldingsNetCardComponent implements OnInit {
  @Input() title: string;
  @Input() nettHoldingsLabels: Array<string>;
  @Input() nettHoldings: Array<any>;
  @Input() nettBalance: number;
  chart: Chart;
  initialised: boolean;

  constructor() {
    Chart.register(...registerables);
    this.initialised = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.nettHoldings || changes.nettHoldingsLabels || changes.nettBalance) && this.chart) {
      this.chart.data = {
        labels: this.nettHoldingsLabels,
        datasets: [
          {
            data: this.nettHoldings,
            backgroundColor: ["rgb(139, 0, 1)", "rgb(46, 88, 148)"],
            hoverOffset: 4,
          },
        ],
      };

      this.chart.update();
      this.chart.resize();
    }
  }

  ngOnInit(): void {
    this.chart = new Chart("NetHoldings", {
      type: "pie",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        parsing: {
          key: "nested.value",
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
            align: "start",
          },
        },
      },
    });
    this.initialised = true;
    return null;
  }

  ngOnDestroy() {
    // remove the chart
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
