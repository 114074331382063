import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { NormalizedPair } from "@bitwarden/web-vault/app/models/data/shared/normalized-pair";
import { GlossBalanceUtils } from "@bitwarden/web-vault/app/models/data/utils/glossBalance.utils";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

/**
 * GlossBalance - This object is used to represent the values that relate to different
 *                granularities, their running summations split by symbols.
 *                The in, out and value parameters can represent a single transaction
 *                or it can relate to an entire granularity such as a day, month or year.
 *                The runningTotalIn, runningTotalOut and runningTotalValue parameters
 *                are used to record the balance including the values in the in, out and value
 *                for this GlossBalance object.
 *                The runningTotalNormalizedIn, runningTotalNormalizedOut and runningTotalNormalizedValue
 *                parameters represent the summation of all symbols up to and including this GroupedBalance.
 *
 */

export class GlossBalance extends GlossBalanceUtils {
  // TODO: remove
  private _normalized = 0;

  // TODO: remove
  private _currencyBalances: Record<string, number> = {};

  // VALUES FOR THIS TRANSACTION OR GRANULARITY INTERVAL KEYED ON SYMBOL
  private _in: Record<string, NormalizedPair> = {};
  private _out: Record<string, NormalizedPair> = {};
  private _value: Record<string, NormalizedPair> = {}; // combines in and out together

  // RUNNING BALANCES FOR THIS GRANULARITY INTERVAL KEYED ON SYMBOL
  private _runningTotalIn: Record<string, NormalizedPair> = {};
  private _runningTotalOut: Record<string, NormalizedPair> = {};
  private _runningTotalValue: Record<string, NormalizedPair> = {}; // combines in and out together

  // RUNNING NORMALIZED TOTALS FOR THIS GRANULARITY INTERVAL
  private _runningTotalNormalizedIn: GlossNumber = new GlossNumber();
  private _runningTotalNormalizedOut: GlossNumber = new GlossNumber();
  private _runningTotalNormalizedValue: GlossNumber = new GlossNumber(); // combines in and out together

  // TODO: remove
  get normalized(): number {
    return this._normalized;
  }

  // TODO: remove
  set normalized(value: number) {
    this._normalized = value;
  }

  // TODO: remove
  get currencyBalances(): Record<string, number> {
    return this._currencyBalances;
  }

  // TODO: remove
  set currencyBalances(value: Record<string, number>) {
    this._currencyBalances = value;
  }

  /*
  add(balance: GlossBalance) {
    if (!isNaN(balance.normalized)) {
      this.normalized += balance.normalized;
    }
    if (balance.currencyBalances) {
      // go through each
      const currencies = this.getCurrencies();
      const newCurrencies = balance.getCurrencies();
      for (const currency of newCurrencies) {
        if (currencies.includes(currency)) {
          this.currencyBalances[currency] =
            this.currencyBalances[currency] + balance.currencyBalances[currency];
        } else {
          this.currencyBalances[currency] = balance.currencyBalances[currency];
        }
      }
    }
  }
   */

  setToBalanceObj(response: Record<string, any>) {
    return response instanceof GlossBalance
      ? this.setFromObject(response)
      : this.setFromQueryString(response);
  }

  private setFromObject(balance: GlossBalance) {
    return balance;
  }

  private setFromQueryString(response: Record<string, any>) {
    // convert the record to be a glossBalance from existing pieces
    if (typeof response === "object") {
      if (response._in && typeof response._in === "object") {
        this.processNormalizedPairResponseRecord(this.in, response._in);
      }
      if (response._out && typeof response._out === "object") {
        this.processNormalizedPairResponseRecord(this.out, response._out);
      }
      if (response._value && typeof response._value === "object") {
        this.processNormalizedPairResponseRecord(this.value, response._value);
      }
      if (response._runningTotalIn && typeof response._runningTotalIn === "object") {
        this.processNormalizedPairResponseRecord(this.runningTotalIn, response._runningTotalIn);
      }
      if (response._runningTotalOut && typeof response._runningTotalOut === "object") {
        this.processNormalizedPairResponseRecord(this.runningTotalOut, response._runningTotalOut);
      }
      if (response._runningTotalValue && typeof response._runningTotalValue === "object") {
        this.processNormalizedPairResponseRecord(
          this.runningTotalValue,
          response._runningTotalValue
        );
      }
      if (
        response._runningTotalNormalizedIn &&
        typeof response._runningTotalNormalizedIn === "object"
      ) {
        this.runningTotalNormalizedIn = new GlossNumber().setToGlossNumberObj(
          response._runningTotalNormalizedIn
        );
      }
      if (
        response._runningTotalNormalizedOut &&
        typeof response._runningTotalNormalizedOut === "object"
      ) {
        this.runningTotalNormalizedOut = new GlossNumber().setToGlossNumberObj(
          response._runningTotalNormalizedOut
        );
      }
      if (
        response._runningTotalNormalizedValue &&
        typeof response._runningTotalNormalizedValue === "object"
      ) {
        this.runningTotalNormalizedValue = new GlossNumber().setToGlossNumberObj(
          response._runningTotalNormalizedValue
        );
      }
    }
    // if glossBalance was not on the response then we need to wait for setGlossBalance to
    // be called on the Transaction after the quantity and valuation have been correctly configured
    return this;
  }

  private processNormalizedPairResponseRecord(
    glossBalanceProp: Record<string, NormalizedPair>,
    responseRecord: Record<string, any>
  ) {
    for (const symbol in responseRecord) {
      const symbolInResponse = responseRecord[symbol];
      glossBalanceProp[symbol] = new NormalizedPair().setToNormalizedPairObj(symbolInResponse);
    }
  }

  private getCurrencies() {
    return Object.keys(this.currencyBalances);
  }

  get in(): Record<string, NormalizedPair> {
    return this._in;
  }

  set in(inNormalizedPairs: Record<string, NormalizedPair>) {
    this._in = inNormalizedPairs;
  }

  get out(): Record<string, NormalizedPair> {
    return this._out;
  }

  set out(outNormalizedPairs: Record<string, NormalizedPair>) {
    this._out = outNormalizedPairs;
  }

  get value(): Record<string, NormalizedPair> {
    return this._value;
  }

  set value(normalizedPairs: Record<string, NormalizedPair>) {
    this._value = normalizedPairs;
  }

  get runningTotalIn(): Record<string, NormalizedPair> {
    return this._runningTotalIn;
  }

  set runningTotalIn(inNormalizedPairs: Record<string, NormalizedPair>) {
    this._runningTotalIn = inNormalizedPairs;
  }

  get runningTotalOut(): Record<string, NormalizedPair> {
    return this._runningTotalOut;
  }

  set runningTotalOut(outNormalizedPairs: Record<string, NormalizedPair>) {
    this._runningTotalOut = outNormalizedPairs;
  }

  get runningTotalValue(): Record<string, NormalizedPair> {
    return this._runningTotalValue;
  }

  set runningTotalValue(normalizedPairs: Record<string, NormalizedPair>) {
    this._runningTotalValue = normalizedPairs;
  }

  get runningTotalNormalizedIn(): GlossNumber {
    return this._runningTotalNormalizedIn;
  }

  set runningTotalNormalizedIn(normalizedPair: GlossNumber) {
    this._runningTotalNormalizedIn = normalizedPair;
  }

  get runningTotalNormalizedOut(): GlossNumber {
    return this._runningTotalNormalizedOut;
  }

  set runningTotalNormalizedOut(normalizedPair: GlossNumber) {
    this._runningTotalNormalizedOut = normalizedPair;
  }

  get runningTotalNormalizedValue(): GlossNumber {
    return this._runningTotalNormalizedValue;
  }

  set runningTotalNormalizedValue(normalizedPair: GlossNumber) {
    this._runningTotalNormalizedValue = normalizedPair;
  }

  /**
   * Helper Functions for GlossBalance
   */
  add(additionalBalance: GlossBalance) {
    this.addGranularityBalances(additionalBalance);
    this.addRunningBalances(additionalBalance);
  }

  addGranularityBalances(additionalBalance: GlossBalance) {
    // add any matching symbols for the current granularity interval
    GlossBalance.addToNormalizedPairRecords(this.in, additionalBalance.in);
    GlossBalance.addToNormalizedPairRecords(this.out, additionalBalance.out);
    GlossBalance.addToNormalizedPairRecords(this.value, additionalBalance.value);
  }

  addRunningBalances(additionalBalance: GlossBalance) {
    // add any matching symbols for the running totals
    GlossBalance.addToNormalizedPairRecords(this.runningTotalIn, additionalBalance.runningTotalIn);
    GlossBalance.addToNormalizedPairRecords(
      this.runningTotalOut,
      additionalBalance.runningTotalOut
    );
    GlossBalance.addToNormalizedPairRecords(
      this.runningTotalValue,
      additionalBalance.runningTotalValue
    );

    // Copy the normalized symbols if they are not set on this GlossBalance
    this.copyNormalizedSymbol(additionalBalance);

    // add normalized glossNumber to the normalized running total
    this.runningTotalNormalizedIn.add(additionalBalance.runningTotalNormalizedIn);
    this.runningTotalNormalizedOut.add(additionalBalance.runningTotalNormalizedOut);
    this.runningTotalNormalizedValue.add(additionalBalance.runningTotalNormalizedValue);
  }

  /**
   * If this is a new Gloss Balance, we can call this function to copy the normalized symbol across
   * from the object we are adding from.
   * @param additionalBalance
   */
  copyNormalizedSymbol(additionalBalance: GlossBalance) {
    if (!this.runningTotalNormalizedIn.symbol) {
      this.runningTotalNormalizedIn.symbol = additionalBalance.runningTotalNormalizedValue.symbol;
    }
    if (!this.runningTotalNormalizedOut.symbol) {
      this.runningTotalNormalizedOut.symbol = additionalBalance.runningTotalNormalizedValue.symbol;
    }
    if (!this.runningTotalNormalizedValue.symbol) {
      this.runningTotalNormalizedValue.symbol =
        additionalBalance.runningTotalNormalizedValue.symbol;
    }
  }

  // TODO: build out these functions
  getDirectionBySymbolAmount(direction: TransactionDirection, symbol: string): GlossNumber {
    return;
  }

  getSymbolAmount(symbol: string): GlossNumber {
    return this.runningTotalValue[symbol].symbolAmount;
  }

  getRunningDirectionBySymbol(direction: TransactionDirection, symbol: string): GlossNumber {
    return;
  }

  getRunningTotalSymbol(symbol: string): NormalizedPair {
    return this.runningTotalValue[symbol] ? this.runningTotalValue[symbol] : null;
  }

  getRunningNormalizedDirection(direction: TransactionDirection): GlossNumber {
    return;
  }

  getActualAmountOfSymbol(symbol: string): number {
    return this.getSymbolAmount(symbol).amount;
  }
}
