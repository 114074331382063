<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-py-8 tw-px-8 xs:tw-px-2"
  >
    <div>
      <img class="logo logo-themed" alt="Gloss" />
      <p class="tw-mb-4 tw-text-center tw-text-xl">{{ "passwordHint" | i18n }}</p>
    </div>
    <div
      class="tw-border-secondary-300 tw-mt-3 tw-flex tw-flex-col tw-rounded-3xl tw-bg-background tw-p-6 tw-shadow-xl xs:tw-flex-none"
    >
      <label for="email">{{ "emailAddress" | i18n }}</label>
      <input
        id="email"
        type="text"
        name="Email"
        [(ngModel)]="email"
        required
        appAutofocus
        inputmode="email"
        appInputVerbatim="false"
      />
      <small class="tw-mt-2">{{ "enterEmailToGetHint" | i18n }}</small>
      <hr />
      <div class="tw-flex xs:tw-flex-col">
        <button
          type="submit"
          class="btn btn-primary btn-submit tw-w-full"
          [disabled]="form.loading"
        >
          <span [hidden]="form.loading">{{ "submit" | i18n }}</span>
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        </button>
        <a
          routerLink="/login"
          class="btn btn-outline-secondary tw-ml-2 tw-w-full xs:tw-ml-0 xs:tw-mt-1"
        >
          {{ "cancel" | i18n }}
        </a>
      </div>
    </div>
  </div>
</form>
