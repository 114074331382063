import {
  eachDayOfInterval,
  format as dateFnsFormat,
  getDate,
  getMonth,
  isAfter,
  isBefore,
  setMonth,
  subDays,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  addDays,
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
} from "date-fns";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GranularityBucketKeyFormat } from "@bitwarden/web-vault/app/models/enum/granularityBucketKeysDateFormatEnum";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import {
  GranularityProperty,
  GranularityType,
  dayGranularity,
  granularityProperties,
  monthGranularity,
  quarterGranularity,
  weekGranularity,
  yearGranularity,
  GroupingNodeType,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GroupingNodeFactory } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNodeFactory";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { sortTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/sort";

export class BalanceGrouping {
  private _dateStart: Date = null; // holds the date of the first transaction
  private _dateEnd: Date = null; // holds the date of the last transaction
  private _lastRunningDateEnd: Date = null; // hold the date of that the summing and filling granularity was last run to
  private _weekStartDay = 0;
  private _monthStartDay = 1;
  private _YearMonthStart = 1;
  private _transactions: Array<Transaction>;
  private _granularity: GranularityType; // holds the summing and filling of the granularities
  private _fillDaily = false;
  protected logService: LogService = new ConsoleLogService(false);

  constructor(transactions: Array<Transaction>, private preferenceService: PreferenceService) {
    this.transactions = transactions;
  }

  // <editor-fold desc="Public Functions">
  set transactions(transactions: Array<Transaction>) {
    transactions = transactions.sort(sortTransaction);
    this._transactions = transactions;
  }

  get transactions() {
    return this._transactions;
  }

  get granularity() {
    return this._granularity;
  }

  get lastRunningDateEnd() {
    return this._lastRunningDateEnd;
  }

  set lastRunningDateEnd(lastRunningEndDate: Date) {
    this._lastRunningDateEnd = lastRunningEndDate;
  }

  get dateEnd(): Date {
    return this._dateEnd;
  }

  set endDate(dateEnd: Date) {
    this._dateEnd = dateEnd;
  }

  /**
   * Sums the transaction onto any matching children after the date of the transaction
   * This function is used from the addTransactionToExistingBalanceGrouping function when
   * the transaction is added onto a balance grouping that has already been summed past that date.
   *
   * @param transaction
   * @param transactionDate
   */
  async sumGroupedBalancesAfterDate(transaction: Transaction, transactionDate: Date) {
    for (const dayKey in this.granularity["day"]) {
      const date = new Date(dayKey);
      date.setHours(0, 0, 0, 0);
      if (date.getTime() > new Date(transactionDate.toDateString()).getTime()) {
        const children = this.granularity["day"][dayKey].children;

        for (const childKey in children) {
          const childNodes = children[childKey as GroupingNodeType];

          for (const childNodeKey in childNodes) {
            const child = childNodes[childNodeKey];
            if (child.transactionMatchesGroup(transaction)) {
              child.balance.addRunningBalances(transaction.balance);
            }
          }
        }
      }
    }
  }

  get fillDaily(): boolean {
    return this._fillDaily;
  }

  /**
   * buildBalanceGrouping - Main function to be used to set the granularity property of this
   * balanceGrouping class.
   *
   * Build out all the required granularities in the granularity property.
   * Take the transaction in the transactions array and process each transaction across the
   * granularities.
   *
   * Call this function if you have a list of transactions and want to calculate all the balance numbers for all the
   * different dates and time frames. This will be used in the dashboard to build the graphs.
   *
   */
  async buildBalanceGrouping(
    pushToDataStore: boolean,
    transactions?: Array<Transaction>,
    sumAndFill = true,
    createChildren = true,
    limitChildren?: Array<GroupingNodeType>,
    granularityList?: Array<GranularityProperty>,
    fillDaily = false
  ): Promise<GranularityType> {
    // await this.initializeAll();

    // if a granularity list is not supplied in the parameters, then get all the granularities
    if (!granularityList) {
      granularityList = granularityProperties;
    }

    await this.initializeAll(granularityList);

    if (transactions) {
      transactions = transactions.sort(sortTransaction);
      this.transactions = transactions;
    }

    for (const transaction of this.transactions) {
      const transactionDate = new Date(transaction.transactionDate.date);
      this.updatePeriodRange(transactionDate);
      for (const granularity of granularityList) {
        const groupingKey = this.getDateGroupingKey(transactionDate, granularity);
        await this.processTransactionGrouping(
          transaction,
          transactionDate,
          granularity,
          groupingKey,
          createChildren,
          limitChildren
        );
      }
    }

    if (this.transactions.length > 0) {
      if (sumAndFill === true) {
        await this.sumAndFillGroupedBalances(granularityList, fillDaily);
      }

      // TODO: save to the data store the groupedBalance
      // if (saveToDataStore){
      // save the newly calculated granularity to our NGRX data store
      // }
    }

    return this._granularity;
  }

  async addGranularityToBalance(granularity: GranularityProperty) {
    this.granularity[granularity] = {};
    for (const transaction of this.transactions) {
      const transactionDate = new Date(transaction.transactionDate.date);
      this.updatePeriodRange(transactionDate);
      const groupingKey = this.getDateGroupingKey(transactionDate, granularity);
      await this.processTransactionGrouping(
        transaction,
        transactionDate,
        granularity,
        groupingKey,
        true,
        ["account"]
      );
    }
    if (this.transactions.length > 0) {
      await this.sumAndFillGroupedBalances([granularity], true);
    }
  }

  /**
   * Adds a transaction to this balance grouping object. It is assumed that the transaction comes after the
   * transactions that have already been added to the balance grouping and that the groupings that
   * come before this transaction do not need to be modified.
   *
   * This function is called when we step through each transaction one by one to work out the balance
   * alignment values. We need to add each transaction incrementally so that we can adjust the balance
   * alignment amounts as required.
   *
   * We only need the day granularity for this function because we are only using it for balance alignment purposes.
   *
   * @param transaction
   * @param sumAndFill
   */
  async addTransactionToEndOfBalanceGrouping(
    transaction: Transaction,
    createChildren = false,
    limitChildren?: Array<GroupingNodeType>
  ) {
    if (!this.granularity) {
      await this.initializeAll([dayGranularity]);
    }

    const transactionDate = new Date(transaction.transactionDate.date);
    this.updatePeriodRange(transactionDate);

    const granularity = dayGranularity;

    const groupingKey = this.getDateGroupingKey(transactionDate, granularity);
    // call the function to add the transaction to the grouping
    await this.processTransactionGrouping(
      transaction,
      transactionDate,
      granularity,
      groupingKey,
      createChildren,
      limitChildren
    );
  }

  /**
   * Adds a transaction to this balance grouping object after a sum and fill has already been calculated on its
   * granularities. It is assumed that the transaction comes after the transactions that have
   * already been added to the balance grouping and that the granularities that come before this transaction
   * do not need to be modified.
   *
   * This function is called when we step through each transaction one by one to work out the balance
   * alignment values. We need to add each transaction incrementally so that we can adjust the balance
   * alignment amounts as required. If the transaction is the last transaction of the day or the closing balance
   * transaction, then we use this function as it calls a sum and fill on its granularities at the end.
   *
   * Note that this will only operate for the day granularity as that is all that is needed for balance alignments.
   * A fill is not required as this is only used for balance alignments, but further summing may be required
   * if the lastRunningDateEnd is greater than the transaction date
   *
   * @param transaction
   */
  async addTransactionToExistingBalanceGrouping(
    transaction: Transaction,
    createChildren = false,
    limitChildren?: Array<GroupingNodeType>
  ) {
    if (!this.granularity) {
      await this.initializeAll([dayGranularity]);
    }

    const transactionDate = new Date(transaction.transactionDate.date);

    let sumAfterDate = false;
    if (
      new Date(transactionDate.toDateString()).getTime() <
      new Date(this._lastRunningDateEnd.toDateString()).getTime()
    ) {
      // we will need to add the transaction to the other nodes after this date
      sumAfterDate = true;
    }

    this.updatePeriodRange(transactionDate);

    const granularity = dayGranularity;
    const groupingKey = this.getDateGroupingKey(transactionDate, granularity);

    // adds the transaction to the raw granularity
    await this.processTransactionGrouping(
      transaction,
      transactionDate,
      granularity,
      groupingKey,
      createChildren,
      limitChildren
    );

    if (sumAfterDate) {
      await this.sumGroupedBalancesAfterDate(transaction, transactionDate);
    }
  }

  /**
   * After all the transactions are bucketed into their correct granularities and groupings, this function
   * is called to add together all the balances from the previous grouping to the next grouping.
   * Then any gaps in the groupings are filled so a full set of balances are built.
   */
  async sumAndFillGroupedBalances(granularityList: Array<GranularityProperty>, fillDaily: boolean) {
    // add together the balances that are summable
    await this.sumRunningBalances(granularityList);

    // for every date that has no balance we want to carry the balance forward from the last date
    await this.fillTheGranularityGaps(granularityList, fillDaily);
  }

  /**
   * This function is like sumAndFillGroupedBalances, except it starts the sums for a particular date in time
   * and uses the lastRunningDate as the previous date to add from. A fill is not required as this is only used
   * for balance alignments.
   * @param fromDate
   */
  async sumGroupedBalancesOnDate(fromDate: Date) {
    if (!this.lastRunningDateEnd) {
      this._lastRunningDateEnd = fromDate;
      return;
    }

    const granularity = dayGranularity;
    const granularityGroup = this._granularity[granularity];

    // get the key of the previous grouping
    const previousGroupingKey = this.getDateGroupingKey(this.lastRunningDateEnd, granularity);
    const groupingKey = this.getDateGroupingKey(fromDate, granularity);

    const previousGroupingNode = granularityGroup[previousGroupingKey];
    const currentGroupingNode = granularityGroup[groupingKey];

    if (currentGroupingNode && previousGroupingNode) {
      await currentGroupingNode.addRunning(previousGroupingNode);
    }

    this._lastRunningDateEnd = fromDate;
  }

  // <editor-fold desc="Date Functions">

  /**
   * Return the group key ( bucket ) for the given date on the given granularity
   * @param date
   * @param granularity
   * @private
   * @return the date string as @enum {GranularityBucketKeyFormat} format
   */
  getDateGroupingKey(date: Date, granularity: string): string {
    let result: string;
    if (granularity === dayGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.day);
    } else if (granularity === weekGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.week, {
        weekStartsOn: this._weekStartDay as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      });
    } else if (granularity === monthGranularity) {
      let periodDateStart = date;
      // todo test this logic
      if (getDate(date) < this._monthStartDay) {
        periodDateStart = setMonth(date, getMonth(date) - 1);
      }
      result = dateFnsFormat(periodDateStart, GranularityBucketKeyFormat.month);
    } else if (granularity === quarterGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.quarter);
    } else if (granularity === yearGranularity) {
      // todo manage this._YearMonthStart
      result = dateFnsFormat(date, GranularityBucketKeyFormat.year);
    } else {
      throw new Error(`Granularity ${granularity} not implemented`);
    }

    return result;
  }

  getPreviousGroupingDate(date: Date, granularity: GranularityProperty): Date {
    if (granularity === dayGranularity) {
      return subDays(date, 1);
    } else if (granularity === weekGranularity) {
      return subWeeks(date, 1);
    } else if (granularity === monthGranularity) {
      return subMonths(date, 1);
    } else if (granularity === quarterGranularity) {
      return subQuarters(date, 1);
    } else if (granularity === yearGranularity) {
      // todo manage this._YearMonthStart
      return subYears(date, 1);
    } else {
      throw new Error(`Granularity ${granularity} not implemented`);
    }
  }

  getNextGroupingDate(date: Date, granularity: GranularityProperty): Date {
    switch (granularity) {
      case dayGranularity:
        return addDays(date, 1);
      case weekGranularity:
        return addWeeks(date, 1);
      case monthGranularity:
        return addMonths(date, 1);
      case quarterGranularity:
        return addQuarters(date, 1);
      case yearGranularity:
        return addYears(date, 1);
      default:
        throw new Error(`Granularity ${granularity} not implemented`);
    }
  }

  getGranularityGroupingKeys(
    start: Date,
    end: Date,
    granularity: GranularityProperty
  ): Record<string, Record<string, Date>> {
    const fullDateInterval = eachDayOfInterval({
      start: start,
      end: end,
    });

    const groupings: Record<string, Record<string, Date>> = {};
    for (const candidateDate of fullDateInterval) {
      const groupingKeys = Object.keys(groupings);
      const groupingKey = this.getDateGroupingKey(candidateDate, granularity);
      if (!groupingKeys.includes(groupingKey)) {
        let endDate = candidateDate;
        if (groupingKeys.length > 0) {
          endDate = subDays(this.getNextGroupingDate(candidateDate, granularity), 1);
        }
        groupings[groupingKey] = { startDate: candidateDate, endDate: endDate };
      } else {
        if (groupingKeys.length == 1) {
          groupings[groupingKey].endDate = candidateDate;
        }
      }
    }
    return groupings;
  }

  // </editor-fold desc="Date Functions">

  // </editor-fold desc="Public Functions">

  // <editor-fold desc="Private Functions">

  // <editor-fold desc="Balance Grouping Functions">
  /**
   * For a given transaction, process it into the correct groupingNode
   * @param transaction
   * @param transactionDate
   * @param granularity
   * @private
   */
  private async processTransactionGrouping(
    transaction: Transaction,
    transactionDate: Date,
    granularity: GranularityProperty,
    groupingKey: string,
    createChildren = true,
    limitChildren?: Array<GroupingNodeType>
  ) {
    // create a new grouping node for this granularity and groupingKey if one doesn't exist
    if (!this._granularity[granularity][groupingKey]) {
      this._granularity[granularity][groupingKey] = GroupingNodeFactory.createNode(
        "root",
        [],
        createChildren,
        limitChildren
      );
    }
    // add this transaction to the grouping node
    const transactionNode = this._granularity[granularity][groupingKey];
    await transactionNode.addTransaction(transaction);
  }

  /**
   * This function sums the values moving forward through the balance dates.
   * The completed summing and filling is stored in this._granularity.
   * @private
   */
  private async sumRunningBalances(granularityList: Array<GranularityProperty>) {
    for (const granularity of granularityList) {
      const granularityGroup = this._granularity[granularity];
      let previousGroupingNode = null;
      for (const dateGroupingKey in granularityGroup) {
        const currentGroupingNode = granularityGroup[dateGroupingKey];
        if (currentGroupingNode && previousGroupingNode) {
          await currentGroupingNode.addRunning(previousGroupingNode);
        }
        previousGroupingNode = currentGroupingNode;
      }
    }
  }

  /**
   * This function generates all the dates between the first and last transaction
   * and then fills in any dates in between that doesn't have transactions to be the same
   * as the previous grouping key.
   * @private
   */
  async fillTheGranularityGaps(granularityList: Array<GranularityProperty>, fillDaily: boolean) {
    if (this._dateStart == null && this._dateEnd == null) {
      return;
    }
    const fullDateInterval = eachDayOfInterval({
      start: this._dateStart,
      end: this._dateEnd,
    });

    // set the lastRunningDateEnd to mark where the grouping balance is summed until
    this._lastRunningDateEnd = this._dateEnd;

    for (const granularity of granularityList) {
      if (granularity === dayGranularity && !fillDaily) {
        continue;
      } else if (granularity === dayGranularity && fillDaily) {
        this._fillDaily = true;
      }
      const granularityGroup = this._granularity[granularity];
      let previousGroupingNode = null;
      for (const candidateDate of fullDateInterval) {
        const dateGroupingKey = this.getDateGroupingKey(candidateDate, granularity);
        if (previousGroupingNode && !granularityGroup[dateGroupingKey]) {
          granularityGroup[dateGroupingKey] = await previousGroupingNode.copyRunning();
        } else {
          previousGroupingNode = granularityGroup[dateGroupingKey];
        }
      }
    }
    return;
  }

  async copyGranularityBalanceFromLastDate(toDate: Date) {
    const dateGroupingKey = this.getDateGroupingKey(toDate, dayGranularity);
    const granularityGroup = this._granularity[dayGranularity];
    const previousGroupingKey = this.getDateGroupingKey(this._lastRunningDateEnd, dayGranularity);
    const previousGroupingNode = granularityGroup?.[previousGroupingKey];
    if (previousGroupingNode) {
      granularityGroup[dateGroupingKey] = await previousGroupingNode.copyRunning();
    }

    this._dateEnd = toDate;
    this._lastRunningDateEnd = toDate;

    return;
  }

  // </editor-fold desc="Balance Grouping Functions">

  // <editor-fold desc="Initialization Functions">

  private async initializeAll(granularityList?: Array<GranularityProperty>) {
    this._dateStart = null;
    this._dateEnd = null;

    const weekStartDay = await this.preferenceService.getWeekDayStartAsNumber();
    if (weekStartDay) {
      this._weekStartDay = weekStartDay;
    }
    const yearMonthStart = <number>await this.preferenceService.getYearMonthStartAsNumber();
    if (yearMonthStart) {
      this._YearMonthStart = yearMonthStart;
    }
    const monthStartDay = <number>await this.preferenceService.get(PreferenceType.monthDayStart);
    if (monthStartDay) {
      this._monthStartDay = monthStartDay;
    }

    this.initializeGranularity(granularityList);
  }

  /**
   * Initialize all granularity as empty object
   */
  private initializeGranularity(granularityList?: Array<GranularityProperty>) {
    this._granularity = {} as GranularityType;
    if (!granularityList) {
      granularityList = granularityProperties;
    }
    for (const granularity of granularityList) {
      this._granularity[granularity] = {};
    }
  }

  // </editor-fold desc="Initialization Functions">

  // <editor-fold desc="Date Functions">
  /**
   * Update the Min/Max dates of the period
   * @param date
   */
  private updatePeriodRange(date: Date) {
    if (!this._dateEnd || isAfter(date, this._dateEnd)) {
      this._dateEnd = date;
    }
    if (!this._dateStart || isBefore(date, this._dateStart)) {
      this._dateStart = date;
    }
  }

  getLastDayBalanceOfSymbol(symbol: string, lastDayGranularityKey: string): number {
    return this.granularity["day"][lastDayGranularityKey].balance.getActualAmountOfSymbol(symbol);
  }

  // </editor-fold desc="Date Functions">
  // </editor-fold desc="Private Functions">
}
