<mat-form-field>
  <mat-chip-list>
    Past:
    <mat-chip *ngFor="let item of customPresetsPast" (click)="selectRange(item)">
      {{ item }}
    </mat-chip>
  </mat-chip-list>
  <mat-chip-list>
    Current:
    <mat-chip *ngFor="let item of customPresetsCurrent" (click)="selectRange(item)">
      {{ item }}
    </mat-chip>
  </mat-chip-list>
  <mat-chip-list>
    Future:
    <mat-chip *ngFor="let item of customPresetsFuture" (click)="selectRange(item)">
      {{ item }}
    </mat-chip>
  </mat-chip-list>
</mat-form-field>
