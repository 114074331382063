<div class="content">
  <h1 class="mat-h1">Projections Dashboard</h1>
  <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="300px">
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.largeChart.cols"
      [rowspan]="(cardLayout | async)?.largeChart.rows"
    >
      <app-projected-income-expenses-card
        [projectedTransactions]="projectedTransactions"
        title="Projected Transactions"
      ></app-projected-income-expenses-card>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-projected-top-expenses-card
        [projectedTransactions]="projectedTransactions"
        title="Projected Top Expenses"
      ></app-projected-top-expenses-card>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-projected-income-vs-expenses-card
        [projectedTransactions]="projectedTransactions"
        title="Projected Income Vs Expenses"
      ></app-projected-income-vs-expenses-card>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-projected-top-income-card
        [projectedTransactions]="projectedTransactions"
        title="Projected Top Income"
      ></app-projected-top-income-card>
    </mat-grid-tile>
    <!--Transaction Table-->
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.table.cols"
      [rowspan]="(cardLayout | async)?.table.rows"
    >
      <app-projections-table-card
        [projectedTransactions]="projectedTransactions"
        [defaultStartDate]="defaultStartDate"
        [defaultEndDate]="defaultEndDate"
        [defaultGranularity]="defaultGranularity"
        title="Projected Transactions"
      ></app-projections-table-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
