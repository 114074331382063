import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { NormalizedPair } from "@bitwarden/web-vault/app/models/data/shared/normalized-pair";
import { Valuation } from "@bitwarden/web-vault/app/models/data/valuation.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

export enum BalanceAlignmentDescription {
  Opening = "Opening Balance Alignment Transaction",
  Closing = "Closing Balance Alignment Transaction",
  Generated = "Generated Balance Alignment Transaction",
}

export abstract class GlossBalanceUtils {
  /**
   * addNormalizedPairRecords
   * Returns a new Record containing the summation of the
   * firstRecord to the secondRecord matching on symbols
   * @param firstRecord
   * @param secondRecord
   */
  static addNormalizedPairRecords(firstRecord: Record<string, NormalizedPair>, secondRecord: Record<string, NormalizedPair>): Record<string, NormalizedPair> {
    const summationRecord: Record<string, NormalizedPair> = {};
    for (const newSymbol in firstRecord) {
      summationRecord[newSymbol] = firstRecord[newSymbol];
    }
    for (const newSymbol in secondRecord) {
      if (summationRecord[newSymbol]) {
        summationRecord[newSymbol].add(secondRecord[newSymbol]);
      } else {
        summationRecord[newSymbol] = secondRecord[newSymbol];
      }
    }
    return summationRecord;
  }

  /**
   * addToNormalizedPairRecords
   * Adds the second parameter normalizedPair Record to the first one.
   * If the symbol exists in the first Record it will add the values.
   * If the symbol doesn't exist then it will add it to the first Record and
   * copy the values across.
   * @param currentRecord
   * @param additionalRecord
   */
  static addToNormalizedPairRecords(currentRecord: Record<string, NormalizedPair>, additionalRecord: Record<string, NormalizedPair>) {
    for (const newSymbol in additionalRecord) {
      if (currentRecord[newSymbol]) {
        currentRecord[newSymbol].add(additionalRecord[newSymbol]);
      } else {
        currentRecord[newSymbol] = additionalRecord[newSymbol].copy();
      }
    }
  }

  /**
   * addNormalizedFromPair
   * Adds just the normalized components from a set of NormalizedPairs to the
   * GlossNumber in the first parameter.
   * @param currentNormalizedAmount
   * @param additionalRecord
   */
  static addNormalizedFromPair(currentNormalizedAmount: GlossNumber, additionalRecords: Record<string, NormalizedPair>) {
    for (const additionalSymbol in additionalRecords) {
      const additionalSymbolPair = additionalRecords[additionalSymbol];
      if (!currentNormalizedAmount.symbol) {
        currentNormalizedAmount.symbol = additionalSymbolPair.normalizedValue.symbol;
      }
      currentNormalizedAmount.add(additionalSymbolPair.normalizedValue);
    }
  }

  /**
   * Given a GlossQuantity and a Valuation, assuming they have been set
   * correctly, extract the pieces to build a full GlossBalance for a
   * transaction
   * @param quantity
   * @param valuation
   */
  static createGlossBalanceFromQuantityValuation(direction: TransactionDirection, quantity: GlossQuantity, valuation: Valuation, isTransfer = false): GlossBalance {
    const transactionBalance = new GlossBalance();
    const symbolGlossNumber = quantity.actualQuantity.copy();
    const normalizedGlossNumber = valuation.normalizedValue.copy();

    const transactionNormalizedPair = new NormalizedPair();
    if (direction === TransactionDirection.Out) {
      symbolGlossNumber.amount = -1 * symbolGlossNumber.amount;
    }
    transactionNormalizedPair.symbolAmount = symbolGlossNumber;
    transactionNormalizedPair.normalizedValue = normalizedGlossNumber;

    const symbol = symbolGlossNumber.symbol;

    if (!isTransfer) {
      if (direction === TransactionDirection.In) {
        transactionBalance.in[symbol] = transactionNormalizedPair.copy();
        transactionBalance.runningTotalIn[symbol] = transactionNormalizedPair.copy();
        transactionBalance.runningTotalNormalizedIn.copyFrom(normalizedGlossNumber);
      } else {
        transactionBalance.out[symbol] = transactionNormalizedPair.copy();
        transactionBalance.runningTotalOut[symbol] = transactionNormalizedPair.copy();
        transactionBalance.runningTotalNormalizedOut.copyFrom(normalizedGlossNumber);
      }
    }
    transactionBalance.value[symbol] = transactionNormalizedPair.copy();
    transactionBalance.runningTotalValue[symbol] = transactionNormalizedPair.copy();
    transactionBalance.runningTotalNormalizedValue.copyFrom(normalizedGlossNumber);

    return transactionBalance;
  }

  static createGlossBalanceNormalizedPair(normalizedPair: NormalizedPair, isTransfer = false) {
    const { normalizedValue, symbolAmount } = normalizedPair;
    let direction = TransactionDirection.In;
    const transactionBalance = new GlossBalance();

    if (normalizedValue.amount < 0) {
      direction = TransactionDirection.Out;
    }

    const symbol = symbolAmount.symbol;

    if (!isTransfer) {
      if (direction === TransactionDirection.In) {
        transactionBalance.in[symbol] = normalizedPair.copy();
        transactionBalance.runningTotalIn[symbol] = normalizedPair.copy();
        transactionBalance.runningTotalNormalizedIn.copyFrom(normalizedValue);
      } else {
        transactionBalance.out[symbol] = normalizedPair.copy();
        transactionBalance.runningTotalOut[symbol] = normalizedPair.copy();
        transactionBalance.runningTotalNormalizedOut.copyFrom(normalizedValue);
      }
    }
    transactionBalance.value[symbol] = normalizedPair.copy();
    transactionBalance.runningTotalValue[symbol] = normalizedPair.copy();
    transactionBalance.runningTotalNormalizedValue.copyFrom(normalizedValue);

    return transactionBalance;
  }
}
