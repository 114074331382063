export class ApmConfig {
  readonly SERVICE_NAME: string = "gloss-client";
  readonly SERVER_URL: string = "https://f398c720f9f14ff297259c23c80aeed8.apm.ap-southeast-2.aws.cloud.es.io:443";

  private _environment: string;

  private _serviceVersion: string;

  constructor() {
    this.environment = process.env.IS_LOCAL ? "localhost" : process.env.NODE_ENV;
    this.serviceVersion = process.env.APPLICATION_VERSION;
  }

  get environment(): string {
    return this._environment;
  }

  set environment(value: string) {
    this._environment = value;
  }

  get serviceVersion(): string {
    return this._serviceVersion;
  }

  set serviceVersion(value: string) {
    this._serviceVersion = value;
  }
}
