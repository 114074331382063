import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Chart, registerables } from "chart.js";

// import {BlobbyService} from "@bitwarden/web-vault/app/services/blobby/blobby.service";

@Component({
  selector: "app-top-expenses-graph",
  templateUrl: "./top-expenses-graph.component.html",
})
export class TopExpensesGraphComponent implements OnInit {
  @Input() dataLabels: Array<any>;
  @Input() dataSetOut: Array<any>;
  title = "Top Expenses";
  chart: Chart;
  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.createGraph();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSetOut && this.chart) {
      this.chart.destroy();
      this.createGraph();
    }
  }

  createGraph(): void {
    this.chart = new Chart("topExpensesChart", {
      type: "bar",
      data: {
        labels: this.dataLabels,
        datasets: [
          {
            label: "Expenses",
            data: this.dataSetOut,
            backgroundColor: ["rgb(255,165,0)"],
            borderColor: ["rgb(255,165,0)"],
          },
        ],
      },
      options: {
        scales: {
          x: {
            beginAtZero: true,
          },
        },
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    return null;
  }

  ngOnDestroy() {
    // remove the chart
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
