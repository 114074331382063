<app-spinner [showSpinner]="loading"></app-spinner>
<div
  (mouseenter)="showTooltipBox(true)"
  *ngIf="showTooltipBoolean && hoveredTransaction.id === selectedTransactionId"
>
  <app-info-tooltip
    (onMouseEnterTooltip)="onMouseEnterTooltip($event)"
    (onMouseLeaveTooltip)="onMouseLeaveTooltip($event)"
    [transaction]="hoveredTransaction"
    [selectedTransactionId]="selectedTransactionId"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
  ></app-info-tooltip>
</div>
<div class="transaction-table-container">
  <nav class="navbar navbar-expand-lg navbar-light nav-transactions">
    <div class="container-fluid">
      <div class="dropdown">
        <button
          [disabled]="!isActionButtonEnabled()"
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Actions
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <button
            class="dropdown-item"
            href="#"
            (click)="linkConversion()"
            [disabled]="!isLinkButtonEnabled()"
          >
            {{ "linkAsConversion" | i18n }}
          </button>
          <button
            class="dropdown-item"
            href="#"
            (click)="linkTransfer()"
            [disabled]="!isLinkButtonEnabled()"
          >
            {{ "linkAsTransfer" | i18n }}
          </button>
          <button
            class="dropdown-item"
            href="#"
            (click)="unlinkTransactions()"
            [disabled]="!isLinkButtonEnabled()"
          >
            {{ "removeLinkage" | i18n }}
          </button>
          <button class="dropdown-item" href="#" (click)="deleteTransaction()">
            {{ "deleteTransactions" | i18n }}
          </button>
        </div>
      </div>
    </div>
    <div>
      <mat-slide-toggle
        color="primary"
        [(ngModel)]="showRevaluations"
        (ngModelChange)="onToggleChange()"
      >
        <span *ngIf="showRevaluations">Hide</span>
        <span *ngIf="!showRevaluations">Show</span>
        Revaluations
      </mat-slide-toggle>
    </div>
    <div *ngIf="isHideEstimateExperimental">
      <mat-slide-toggle
        color="primary"
        [(ngModel)]="showEstimates"
        (ngModelChange)="onToggleChange()"
      >
        <span *ngIf="showEstimates">Hide</span>
        <span *ngIf="!showEstimates">Show</span>
        Estimates
      </mat-slide-toggle>
    </div>
  </nav>
  <app-transaction-table-tabs></app-transaction-table-tabs>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="transactionDate"
    matSortDirection="desc"
    matSortDisableClear
  >
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let item; let i = index"
        class="transaction-table__column-text link-checkbox"
      >
        <mat-checkbox
          [color]="'primary'"
          (change)="linkToggle($event, item)"
          [(ngModel)]="check[item.id]"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
        {{ element.accountId | bookPipe: "getBookName":[element.mockAccountName] | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
        {{ element.transactionDate.toString() | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
        {{ element.description }}
        <img
          *ngIf="element.linkedTo.length > 0"
          class="conversion-icon"
          src="images/icons/table/conversion.png"
          title="Linked to other transaction"
          (mouseover)="onHover(element)"
          (mouseout)="onOut()"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="categories">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Categories</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
        {{ element.categories | categoryPipe: "getCategoriesNamesList" | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="classifications">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Classifications</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
        {{ element.classifications | classificationPipe: "getClassificationsNamesList" | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Currency</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
        {{ element.quantity._currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="in">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">In</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-number">
        <span *ngIf="isDirectIn(element.direction)" class="dataIn">{{
          element.quantity.actualQuantity.amount | currency: element.quantity.actualQuantity.symbol
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="out">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Out</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-number">
        <span *ngIf="isDirectOut(element.direction)" class="dataOut">{{
          element.quantity.actualQuantity.amount | currency: element.quantity.actualQuantity.symbol
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="in_normalized">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
        In ( Normalized )
      </th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-number">
        <span *ngIf="isDirectIn(element.direction)" class="dataNormalizedIn">{{
          element.valuation.normalizedValue.amount | currency: this.baseCurrency
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="out_normalized">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
        Out ( Normalized )
      </th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-number">
        <span *ngIf="isDirectOut(element.direction)" class="dataNormalizedOut">{{
          element.valuation.normalizedValue.amount * -1 | currency: this.baseCurrency
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="aggregate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Aggregate</th>
      <td mat-cell *matCellDef="let element" class="transaction-table__column-number">
        <div *ngIf="isDirectIn(element.direction)">
          <span *ngIf="isDirectIn(element.direction)">{{
            element.valuation.normalizedValue.amount | currency: this.baseCurrency
          }}</span>
        </div>
        <div *ngIf="isDirectOut(element.direction)">
          <span *ngIf="isDirectOut(element.direction)">{{
            element.valuation.normalizedValue.amount | currency: this.baseCurrency
          }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Balance</th>
      <td
        mat-cell
        *matCellDef="let element"
        class="transaction-table__column-number"
        [matTooltip]=""
        (mouseenter)="onMouseEnter($event, element)"
        (mouseleave)="onMouseLeave()"
      >
        <span (mouseenter)="callOnMouseEnterForTd($event)" class="dataBalance">{{
          element.dailyBalance | currency: this.baseCurrency
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" *ngIf="isActionExperimental">
      <th mat-header-cell *matHeaderCellDef class="actions"></th>
      <td mat-cell *matCellDef="let row; let i = index" class="actions">
        <button mat-icon-button color="primary" matTooltip="Edit" (click)="handleEdit(row)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="highlightRow(row)"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of Transaction"
  >
  </mat-paginator>
</div>
