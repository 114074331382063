import { Injector } from "@angular/core";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BalanceForm } from "@bitwarden/web-vault/app/models/types/account.types";
import { SystemCurrenciesString } from "@bitwarden/web-vault/app/models/types/system-currencies.type";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";

export class BookBalance {
  readonly accountView: AccountView;
  readonly balanceForm: BalanceForm;
  private alignmentOfDate: Transaction;
  private newBalance: GlossNumber;
  private newDate: GlossDate;

  private transactionService: TransactionService;
  private transactionNormalizeService: TransactionNormalizeService;
  private globalService: GlobalService;

  constructor(accountView: AccountView, balanceForm: BalanceForm, private injector: Injector) {
    this.accountView = accountView;
    this.balanceForm = balanceForm;
    this.newBalance = new GlossNumber().setToGlossNumberObj({ amount: this.balanceForm.balance });
    this.newDate = new GlossDate().setToDateObj(this.balanceForm.date);

    this.transactionService = this.injector.get(TransactionService);
    this.transactionNormalizeService = this.injector.get(TransactionNormalizeService);
    this.globalService = this.injector.get(GlobalService);
  }

  private validateBalanceForm() {
    return this.isValidBalance() && this.isValidCurrency() && this.isValidDate();
  }

  private isValidBalance() {
    if (!this.balanceForm.balance) {
      return false;
    }

    return !isNaN(this.newBalance.amount);
  }

  private isValidCurrency() {
    return (
      this.balanceForm.currency != "" && SystemCurrenciesString.includes(this.balanceForm.currency)
    );
  }

  private isValidDate() {
    const isEmpty = this.balanceForm.date == "";
    const isInvalid = isNaN(new Date(this.balanceForm.date).getTime());
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const balanceDate = new Date(this.balanceForm.date);
    balanceDate.setHours(0, 0, 0, 0);
    const isFuture = balanceDate > today;
    return !isEmpty && !isInvalid && !isFuture;
  }

  private async hasBalanceForDate(): Promise<boolean> {
    try {
      const accountTransactions = await this.transactionService.getAccountTransactions(
        this.accountView.originalBook
      );
      this.alignmentOfDate = accountTransactions.find((transaction) => {
        return (
          transaction.definition === TransactionStatusEnum.closing &&
          transaction.transactionDate.date.getTime() === this.newDate.date.getTime()
        );
      });
      return !!this.alignmentOfDate;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
    }
  }

  private isBalanceSameForDate(): boolean {
    try {
      if (!this.alignmentOfDate) {
        return false;
      }
      return this.alignmentOfDate.bankImportedBalance === this.newBalance.amount;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
    }
  }

  private async updateBalance() {
    try {
      const balanceMessageDetails = `Your existing balance is ${this.alignmentOfDate.bankImportedBalance} for  ${this.newDate.dateString} and you are about to change it to  ${this.newBalance.amount}.`;
      const confirmation = await this.globalService.showDialogue(
        "wouldYouLikeToUpdateYouBalance",
        "warning",
        balanceMessageDetails
      );

      if (!confirmation) {
        return;
      }

      this.alignmentOfDate.bankImportedBalance = this.newBalance.amount;
      await this.transactionService.update(this.alignmentOfDate);
      this.globalService.showSuccessMessage("success", "balanceUpdatedSuccessfully");
      return true;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
      return false;
    }
  }

  private async addBalance() {
    try {
      const transaction = await this.transactionService.createAccountAlignmentTransaction(
        this.accountView.originalBook,
        this.balanceForm.balance,
        this.balanceForm.date
      );
      if (transaction instanceof Transaction) {
        await this.transactionNormalizeService.refreshMarketData([transaction]);
      }
      this.globalService.showSuccessMessage("success", "balanceAddedSuccessfully");
      return true;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
      return false;
    }
  }

  private showErrorMessage() {
    this.globalService.showInfoMessage("oops", "balanceExistsForDate");
  }

  async process() {
    if (!this.isValidCurrency()) {
      return;
    }

    if (!this.isValidBalance()) {
      return;
    }

    if (!this.validateBalanceForm()) {
      this.showErrorMessage();
      return;
    }

    if (!(await this.hasBalanceForDate())) {
      /** add balance for the date as there is no balance for the date */
      return await this.addBalance();
    } else if (!this.isBalanceSameForDate()) {
      /** update balance for the date as the balance is different*/
      // TODO add new and old balance to the message
      return await this.updateBalance();
    } else {
      /** show error message as the balance is same for the date */
      if (this.isBalanceSameForDate() || this.newBalance.amount === 0) {
        const balanceMessageDetails = `<span class="tw-block xs:tw-text-xs">You already have the balance of ${this.alignmentOfDate.bankImportedBalance} for  ${this.newDate.dateString}.</span> <span class="xs:tw-text-xs">Would you like to keep the existing balance?</span>`;
        const confirm = await this.globalService.showDialogue(
          "noChangesWereMade",
          "warning",
          balanceMessageDetails,
          true
        );

        if (confirm) {
          return true;
        } else {
          return;
        }
        return;
      }
      this.showErrorMessage();
      return false;
    }
  }
}
