export enum DateRangePresetType {
  "yesterday" = "yesterday",
  "+7days" = "7 days",
  "-7days" = "7 days",
  "lastweek" = "last week",
  "monthtodate" = "month to date",
  "30days" = "30 days",
  "3months" = "3 months",
  "6months" = "6 months",
  "lastmonth" = "last month",
  "90days" = "90 days",
  "yeartodate" = "year to date",
  "lastyear" = "last year",
  "last12Months" = "last 12 months",
  "last3years" = "last 3 years",
  "today" = "today",
  "thisweek" = "this week",
  "thismonth" = "this month",
  "thisyear" = "this year",
  "nextweek" = "next week",
  "nextmonth" = "next month",
  "nextquarter" = "next quarter",
  "nextyear" = "next year",
  "next5years" = "next 5 years",
}
