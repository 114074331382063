<ng-container>
  <h3 class="mt-4">{{ "customFields" | i18n }}</h3>
  <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="cipher.hasFields">
    <div
      role="group"
      class="row"
      cdkDrag
      *ngFor="let f of cipher.fields; let i = index; trackBy: trackByFunction"
      attr.aria-label="{{ f.name }}"
    >
      <div class="col-5 form-group">
        <div class="d-flex">
          <label for="fieldName{{ i }}">{{ "name" | i18n }}</label>
          <a
            class="ml-auto"
            href="https://bitwarden.com/help/custom-fields/"
            target="_blank"
            rel="noopener"
            appA11yTitle="{{ 'learnMore' | i18n }}"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
        </div>
        <input
          id="fieldName{{ i }}"
          type="text"
          name="Field.Name{{ i }}"
          [(ngModel)]="f.name"
          class="form-control"
          appInputVerbatim
          [disabled]="cipher.isDeleted || viewOnly"
          [readonly]="!cipher.edit && editMode"
        />
      </div>
      <div class="col-7 form-group">
        <label for="fieldValue{{ i }}">{{ "value" | i18n }}</label>
        <div class="d-flex align-items-center">
          <!-- Text -->
          <div class="input-group" *ngIf="f.type === fieldType.Text">
            <input
              id="fieldValue{{ i }}"
              class="form-control"
              type="text"
              name="Field.Value{{ i }}"
              [(ngModel)]="f.value"
              appInputVerbatim
              [disabled]="cipher.isDeleted || viewOnly"
              [readonly]="!cipher.edit && editMode"
              attr.aria-describedby="fieldName{{ i }}"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-outline-secondary"
                appA11yTitle="{{ 'copyValue' | i18n }}"
                (click)="copy(f.value, 'value', 'Field')"
              >
                <i class="bwi bwi-lg bwi-clone" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <!-- Hidden -->
          <div class="input-group" *ngIf="f.type === fieldType.Hidden">
            <input
              id="fieldValue{{ i }}"
              type="{{ f.showValue ? 'text' : 'password' }}"
              name="Field.Value{{ i }}"
              [(ngModel)]="f.value"
              class="form-control text-monospace"
              appInputVerbatim
              autocomplete="new-password"
              [disabled]="cipher.isDeleted || viewOnly || (!cipher.viewPassword && !f.newField)"
              [readonly]="!cipher.edit && editMode"
              attr.aria-describedby="fieldName{{ i }}"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-outline-secondary"
                appA11yTitle="{{ 'toggleVisibility' | i18n }}"
                (click)="toggleFieldValue(f)"
                [disabled]="!cipher.viewPassword && !f.newField"
              >
                <i
                  class="bwi bwi-lg"
                  aria-hidden="true"
                  [ngClass]="{ 'bwi-eye': !f.showValue, 'bwi-eye-slash': f.showValue }"
                >
                </i>
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                appA11yTitle="{{ 'copyValue' | i18n }}"
                (click)="copy(f.value, 'value', f.type === fieldType.Hidden ? 'H_Field' : 'Field')"
                [disabled]="!cipher.viewPassword && !f.newField"
              >
                <i class="bwi bwi-lg bwi-clone" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <!-- Linked -->
          <div class="input-group" *ngIf="f.type === fieldType.Linked">
            <select
              id="fieldValue{{ i }}"
              name="Field.Value{{ i }}"
              class="form-control"
              [(ngModel)]="f.linkedId"
              *ngIf="f.type === fieldType.Linked && cipher.linkedFieldOptions != null"
              [disabled]="cipher.isDeleted || viewOnly"
              attr.aria-describedby="fieldName{{ i }}"
            >
              <option *ngFor="let o of linkedFieldOptions" [ngValue]="o.value">{{ o.name }}</option>
            </select>
          </div>
          <div class="flex-fill">
            <!-- Boolean -->
            <input
              id="fieldValue{{ i }}"
              name="Field.Value{{ i }}"
              type="checkbox"
              [(ngModel)]="f.value"
              *ngIf="f.type === fieldType.Boolean"
              appTrueFalseValue
              [trueValue]="true"
              [falseValue]="false"
              [disabled]="cipher.isDeleted || viewOnly"
              attr.aria-describedby="fieldName{{ i }}"
            />
          </div>
          <button
            type="button"
            class="btn btn-link text-danger ml-2"
            (click)="removeField(f)"
            appA11yTitle="{{ 'remove' | i18n }}"
            *ngIf="!cipher.isDeleted && !viewOnly && !(!cipher.edit && editMode)"
          >
            <i class="bwi bwi-minus-circle bwi-lg" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-link text-muted cursor-move"
            appA11yTitle="{{ 'dragToSort' | i18n }}"
            *ngIf="!cipher.isDeleted && !viewOnly && !(!cipher.edit && editMode)"
          >
            <i class="bwi bwi-hamburger bwi-lg" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Add new custom field -->
  <a
    href="#"
    appStopClick
    (click)="addField()"
    class="d-inline-block mb-2"
    *ngIf="!cipher.isDeleted && !viewOnly && !(!cipher.edit && editMode)"
  >
    <i class="bwi bwi-plus-circle bwi-fw" aria-hidden="true"></i> {{ "newCustomField" | i18n }}
  </a>
  <div class="row" *ngIf="!cipher.isDeleted && !viewOnly && !(!cipher.edit && editMode)">
    <div class="col-5">
      <label for="addFieldType" class="sr-only">{{ "type" | i18n }}</label>
      <select id="addFieldType" class="form-control" name="AddFieldType" [(ngModel)]="addFieldType">
        <option *ngFor="let o of addFieldTypeOptions" [ngValue]="o.value">{{ o.name }}</option>
        <option
          *ngIf="cipher.linkedFieldOptions != null"
          [ngValue]="addFieldLinkedTypeOption.value"
        >
          {{ addFieldLinkedTypeOption.name }}
        </option>
      </select>
    </div>
  </div>
</ng-container>
