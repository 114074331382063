import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { InstitutionResponse } from "@bitwarden/web-vault/app/models/data/response/institution.response";
import { InstitutionConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { AppStateService } from "@bitwarden/web-vault/app/services/app-state.service";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";
import { AppState } from "@bitwarden/web-vault/app/state/app.state";

import { Institution } from "../../../models/data/blobby/institution.data";

@Injectable({
  providedIn: "root",
})
export class InstitutionService {
  private _institutionsMasterList: Institution[] = null;
  private _countriesMasterList: Country[] = null;
  private _isMasterListLoaded = false;
  private _isMasterListLoading = false;
  private pending: Array<any> = [];
  private appState: AppState;

  protected institutionListSubject = new BehaviorSubject<Institution[]>([]);
  institutionList$ = this.institutionListSubject.asObservable();
  institutions: Institution[] = [];

  protected countriesListSubject = new BehaviorSubject<Country[]>([]);
  countriesList$ = this.countriesListSubject.asObservable();
  countries: Country[] = [];

  constructor(
    private logService: LogService,
    private performanceService: PerformanceService,
    private dataRepositoryService: DataRepositoryService,
    private appStateService: AppStateService
  ) {
    this.appState = this.appStateService.getAppStateInstance();
  }

  async create(newInsts: Institution): Promise<Institution> {
    try {
      return await this.dataRepositoryService.createInstitution(newInsts);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async update(institution: Institution, triggerObservable: boolean): Promise<Institution> {
    try {
      const updatedInsts = await this.dataRepositoryService.updateInstitution(institution);
      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return updatedInsts;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async delete(institution: Institution, triggerObservable: boolean): Promise<boolean> {
    try {
      const success = await this.dataRepositoryService.deleteInstitution(institution);

      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return success;
    } catch (e) {
      this.logService.error(e.message);
      throw e;
    }
  }

  async getAll(): Promise<Institution[]> {
    return await this.dataRepositoryService.getAllInstitutions();
  }

  async getAccountInstitution(account: Book): Promise<Institution> {
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    return institutions.find(
      (institution) => institution.id === account.institutionLink.institutionId
    );
  }

  async filterInstitutionAccountById(
    institutionAccountId: string,
    institution: Institution
  ): Promise<InstitutionAccount> {
    if (institution.availableAccounts) {
      const institutionAccounts = institution.availableAccounts;
      return institutionAccounts.find((a) => a.id === institutionAccountId);
    }
  }

  async getInstitutionById(institutionId: string): Promise<Institution> {
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    return institutions.find((i) => i.id === institutionId);
  }

  async getAccountType(account: Book): Promise<InstitutionAccount> {
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    const institution = institutions.find(
      (institution) => institution.id === account.institutionLink.institutionId
    );
    return institution?.availableAccounts?.find(
      (accountType) => accountType.id === account.institutionLink.institutionAccountId
    );
  }

  async getNotInUserAccountInstitutions(): Promise<Institution[]> {
    // todo is master list loaded
    const institutionsInUserAccount = await this.dataRepositoryService.getAllInstitutions();
    const institutionsMasterlist = await this.getInstitutionsMasterList();
    return institutionsMasterlist.filter((institutionFromApi) => {
      return !institutionsInUserAccount.find(
        (institutionInUserAccount) => institutionInUserAccount.id === institutionFromApi.id
      );
    });
  }

  /*todo following http request and url are mock data. once the api is ready they should be replaced */
  /** return the institution of the selected country */
  async getCountryInstitutions(selectedCountry: Country) {
    const institutions = await this.getInstitutionsMasterList();
    return institutions.filter(
      (institution) => institution.bic.countryCode === selectedCountry.code
    );
  }

  async initialise() {
    if (!this.appState.getIsInitialized() && !this._isMasterListLoading) {
      this._isMasterListLoading = true;
      this.logService.info("Loading Institutions from API");
      this.LoadInstitutionsFromApi()
        .then()
        .finally(() => {
          this._isMasterListLoaded = true;
          this._isMasterListLoading = false;
          this.pending.forEach((resolve) => {
            resolve();
          });
        });
    }
  }

  isMasterListInitialised(): Promise<void> {
    return new Promise((resolve) => {
      if (this._isMasterListLoaded) {
        resolve();
      } else {
        this.logService.info("Waiting for isMasterListInitialised to resolve");
        this.pending.push(() => {
          resolve();
        });
      }
    });
  }

  async getInstitutionsMasterList(): Promise<Institution[]> {
    return await this.isMasterListInitialised().then(async () => {
      return this._institutionsMasterList;
    });
  }

  async getCountriesMasterListObservable(): Promise<Country[]> {
    let countries: Country[] = [];
    this.countriesList$.subscribe((countriesList) => {
      countries = countriesList;
    });

    return countries;
  }

  async getInstitutionsMasterListObservable(): Promise<Institution[]> {
    let institutions: Institution[] = [];
    this.institutionList$.subscribe((institutionLists) => {
      institutions = institutionLists;
    });

    return institutions;
  }

  async getCountryMasterList(): Promise<Country[]> {
    return await this.isMasterListInitialised().then(async () => {
      return this._countriesMasterList;
    });
  }

  async LoadInstitutionsFromApi(): Promise<void> {
    this.performanceService.mark("LoadInstitutionsFromApi");
    try {
      const institutionConfig = process.env.INSTITUTIONS as unknown as InstitutionConfigType;
      const institutionsResult = await this.dataRepositoryService.send(
        "GET",
        `${institutionConfig.endpoint.institutions}`,
        null,
        true,
        true,
        `${institutionConfig.url}/${institutionConfig.apiStage}`
      );
      this._institutionsMasterList = institutionsResult.institutions.map(
        (insto: any) => new Institution(new InstitutionResponse(insto))
      );
      this._countriesMasterList = institutionsResult.countries as Country[];
      this.logService.info("Institutions Loaded");

      /** Update the Observable Institutions and Countries */
      this.institutionListSubject.next(this._institutionsMasterList);
      this.countriesListSubject.next(this._countriesMasterList);
    } catch (error) {
      this._institutionsMasterList = [];
      this._countriesMasterList = [];
      this.logService.error("Institution Token Error");
      this.logService.error(error);
    } finally {
      this.performanceService.markEnd();
    }
  }

  async updateInstitution(institution: Institution) {
    return await this.dataRepositoryService.updateInstitution(institution);
  }

  async isInstitutionExist(institution: Institution): Promise<boolean> {
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    return institutions.some((inst) => inst.name === institution.name);
  }
}
