// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { scenarioMessages } from "@bitwarden/web-vault/app/components/cell-renderers/no-rows-overlay-component-of-dash-table/scenario-messages";
import { transactionOnly } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

@Component({
  selector: "app-no-rows-overlay",
  templateUrl: "./no-rows-overlay-component-of-dash-table.component.html",
})

// TODO - this version depends on AUD only. need to be updated for the multiple currencies
// TODO - clean up the code after tests passed & all confirmed
export class NoRowsOverlayComponentOfDashTable implements ICellRendererAngularComp {
  scenarioIndex: number;
  params: any;
  numberOfAccounts = 0;
  numberOfPositiveAccounts = 0;
  balanceGrouping: BalanceGrouping;
  dashboardService: DashboardService;
  dashboardType: string;
  scenarioMessages = {
    scenario1: "",
    scenario2: "",
    scenario3: "",
  };
  transactionOnlyMessage = "No transactions found for the selected period.";
  isInterestRateAvailable = true;
  showActionButton = false;
  actionLinkText = "Join";
  actionLinkMessage =
    " our early access program and GLOSS Vault will retrieve your account data, and keep it up to date automatically.";
  actionPreMessage = "Don't want to add your data manually?";
  userPrefilledMessage = "I would like to join the early access program…";

  constructor(
    private bookService: BookService,
    private transactionService: TransactionService,
    private institutionService: InstitutionService
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.scenarioIndex = params.context.componentParent.scenarioIndex;
    this.balanceGrouping = params.context.componentParent.groupBalances;
    this.dashboardType = params.context.componentParent.dashboardParameters?.type;
    this.dashboardService = this.params.context.componentParent.dashboardService;
    this.setIsInterestRateAvailable().then(() => {
      this.setScenarioMessages();
    });
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }

  openHelp(event: Event) {
    this.params.context.componentParent.openHelp(event, this.userPrefilledMessage);
  }

  async setAccountInfo() {
    const scenarioEndDateKey = Object.keys(this.balanceGrouping.granularity["day"]).pop();
    const accountNodes =
      this.balanceGrouping.granularity["day"][scenarioEndDateKey].children["account"];
    for (const accountNodesKey in accountNodes) {
      if (this.dashboardService.startingBalance) {
        if (this.dashboardService.startingBalance[accountNodesKey]) {
          const accountStartingBalance: GlossBalance =
            this.dashboardService.startingBalance[accountNodesKey].balance;
          // since we try to move money around for the best option, we can not get the balance from BalanceGrouping as it gets the updated version.
          const accountCurrentBalance: number =
            accountStartingBalance.getActualAmountOfSymbol("AUD");
          this.numberOfAccounts++;
          if (accountCurrentBalance > 0) {
            this.numberOfPositiveAccounts++;
          }
        }
      } else {
        const account = await this.bookService.get(accountNodesKey);
        if (account) {
          // since we try to move money around for the best option, we can not get the balance from BalanceGrouping as it gets the updated version.
          const accountCurrentBalance = await this.transactionService.getAccountBalance(account);
          this.numberOfAccounts++;
          if (accountCurrentBalance["AUD"] > 0) {
            this.numberOfPositiveAccounts++;
          }
        }
      }
    }
  }

  async setScenarioMessages() {
    if (!this.balanceGrouping) {
      return;
    }
    await this.setAccountInfo();
    this.setMessages();
  }

  async setIsInterestRateAvailable() {
    const accounts = await this.bookService.getAll();
    let userPredefinedMessage = "";
    for (const account of accounts) {
      const institutionId = account.institutionLink?.institutionId;
      const institutionAccountId = account.institutionLink?.institutionAccountId;
      const institution = await this.institutionService.getInstitutionById(institutionId);

      const institutionAccount = await this.institutionService.filterInstitutionAccountById(
        institutionAccountId,
        institution
      );

      userPredefinedMessage += `Account : ${institutionAccount?.name} & Institution : ${institution?.name},\n`;
      const d =
        account.institutionLink &&
        account.institutionLink?.institutionAccountId &&
        account.institutionLink?.institutionId &&
        institutionAccount?.interestRates.length > 0;
      if (d) {
        this.isInterestRateAvailable = true;
        return;
      }
    }

    this.isInterestRateAvailable = false;
    this.userPrefilledMessage = `I have an issue of interest rates for the following accounts & institutions:\n${userPredefinedMessage} Please help me to add the interest rates.`;
  }

  setMessages() {
    this.showActionButton = false;
    if (!this.isInterestRateAvailable) {
      this.setNoInterestRateMessage();
      return;
    }

    if (this.numberOfAccounts === 1) {
      this.setSingleAccountMessages();
    }

    if (this.numberOfAccounts > 1) {
      this.setMultipleAccountMessages();
    }
  }

  setSingleAccountMessages() {
    if (this.numberOfPositiveAccounts === 1) {
      this.scenarioMessages.scenario2 = scenarioMessages.scenario2.oneAccount;
      this.scenarioMessages.scenario3 = scenarioMessages.scenario3.wellDone;
      this.showActionButton = this.scenarioIndex === 1;
    } else {
      this.scenarioMessages.scenario2 = scenarioMessages.scenario2.noPositiveBalanceAccounts;
      this.scenarioMessages.scenario3 = scenarioMessages.scenario3.notHaveNecessaryInfo;
      this.showActionButton = true;
    }
  }

  setNoInterestRateMessage() {
    this.scenarioMessages.scenario2 = scenarioMessages.scenario2.noInterestRate;
    this.actionLinkText = "Notify our team";
    this.actionLinkMessage = " and we will add the data for you";
    this.actionPreMessage = "";
    this.showActionButton = true;
  }

  setMultipleAccountMessages() {
    if (this.numberOfPositiveAccounts === 0) {
      this.scenarioMessages.scenario2 = scenarioMessages.scenario2.noPositiveBalanceAccounts;
      this.scenarioMessages.scenario3 = scenarioMessages.scenario3.notHaveNecessaryInfo;
      this.showActionButton = true;
    }

    if (this.numberOfPositiveAccounts === 1) {
      this.scenarioMessages.scenario2 = scenarioMessages.scenario2.onlyOnePositiveBalanceAccount;
      this.scenarioMessages.scenario3 = scenarioMessages.scenario3.wellDone;
      this.showActionButton = this.scenarioIndex === 1;
    }

    if (this.numberOfPositiveAccounts > 1) {
      const isZeroInterestRate = this.isAllZeroRate();
      if (isZeroInterestRate) {
        this.scenarioMessages.scenario2 = scenarioMessages.scenario2.notGettingInterest;
        this.showActionButton = true;
      } else {
        this.scenarioMessages.scenario2 = scenarioMessages.scenario2.congratulations;
      }

      this.scenarioMessages.scenario3 = scenarioMessages.scenario3.wellDone;
      this.showActionButton = this.scenarioIndex === 1;
    }
  }

  isAllZeroRate() {
    const lastMonthKey = Object.keys(this.balanceGrouping.granularity["month"]).pop();
    const firstMonthKey = Object.keys(this.balanceGrouping.granularity["month"])[0];
    const monthGranularity = this.balanceGrouping.granularity["month"];
    const lastMonthBalance = monthGranularity[lastMonthKey].balance.getActualAmountOfSymbol("AUD");
    const firstMonthBalance =
      monthGranularity[firstMonthKey].balance.getActualAmountOfSymbol("AUD");

    return lastMonthBalance === firstMonthBalance;
  }

  /*  addBalance(): void {
    this.params.context.componentParent.addBalance(this.params.node.data);
  }

  edit(): void {
    this.params.context.componentParent.edit(this.params.node.data);
  }

  delete(): void {
    this.params.context.componentParent.delete(this.params.node.data);
  }*/
  protected readonly transactionOnly = transactionOnly;
}
