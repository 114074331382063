<div class="user-account-settings">
  <div class="page-header">
    <h1>{{ "myAccount" | i18n }}</h1>
  </div>
  <app-profile></app-profile>
  <div class="secondary-header text-danger border-0 mb-0">
    <h1>{{ "dangerZone" | i18n }}</h1>
  </div>
  <div class="card border-danger">
    <div class="card-body">
      <p>{{ "dangerZoneDesc" | i18n }}</p>
      <button
        *appRoleScope="RoleScope.BETA_ACCESS"
        bitButton
        buttonType="danger"
        (click)="deauthorizeSessions()"
      >
        {{ "deauthorizeSessions" | i18n }}
      </button>
      <button
        *appRoleScope="RoleScope.BETA_ACCESS"
        bitButton
        buttonType="danger"
        (click)="purgeVault()"
      >
        {{ "purgeVault" | i18n }}
      </button>
      <button
        *appRoleScope="RoleScope.GLOSS"
        bitButton
        buttonType="danger"
        (click)="deleteAccount()"
      >
        {{ "deleteAccount" | i18n }}
      </button>
      <!--      <button bitButton buttonType="danger" (click)="DumpVault()">-->
      <!--        {{ "bumpTransaction" | i18n }}-->
      <!--      </button>-->
    </div>
  </div>
  <div class="mt-5">
    <button type="submit" class="btn btn-primary btn-submit" (click)="lock()">
      <span>{{ "lockNow" | i18n }}</span>
    </button>
    <button type="submit" class="btn btn-primary btn-submit" (click)="logOut()">
      <span>{{ "logOut" | i18n }}</span>
    </button>
  </div>
</div>
<ng-template #deauthorizeSessionsTemplate></ng-template>
<ng-template #purgeVaultTemplate></ng-template>
<ng-template #deleteAccountTemplate></ng-template>
<ng-template #viewUserApiKeyTemplate></ng-template>
<ng-template #rotateUserApiKeyTemplate></ng-template>
