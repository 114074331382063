import { Component, OnInit, Input, SimpleChanges, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { Holding } from "@bitwarden/common/models/data/holding.data";

@Component({
  selector: "app-holdings-table",
  templateUrl: "holdings-table.component.html",
  styles: [
    "table { width: 100%;}",
    ".holdings-table-container { width: 100% ;}",
    ".holdings-table__column-number { text-align: right; padding: 5px;}",
    ".holdings-table__column-text { text-align: left; padding: 5px;}",
  ],
})
export class HoldingsTableComponent implements OnInit, AfterViewInit {
  @Input() holdingsData: Array<any>;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ["account", "classification", "owe", "own"];

  initialised = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.holdingsData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortColumns;
  }

  sortColumns(h: Holding, property: string) {
    switch (property) {
      case "quantity": {
        return h.quantity;
      }
      case "owe": {
        return h.quantity < 0 ? h.quantity : null;
      }
      case "own": {
        return h.quantity >= 0 ? h.quantity : null;
      }
      case "account": {
        return h.account;
      }
      case "classification": {
        return h.classification;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.holdingsData) {
      this.dataSource = new MatTableDataSource<Holding>(this.holdingsData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = this.sortColumns;
    }
  }

  ngOnInit(): void {
    this.initialised = true;
  }
}
