//TODO make this on ea proper class that can be extended and used in other components
import { GlossButtonOptions, GlossInputOptions } from "../../../../models/types/general-types";

export const previousButtonOptions: GlossButtonOptions = {
  iconName: "arrow_back",
  buttonText: "Previous",
};

export const checkButtonOptions: GlossButtonOptions = {
  iconName: "check",
  buttonText: "Save",
  isEndIcon: true,
};

export const accountInputOptions: GlossInputOptions = {
  placeholder: "Enter account name...",
  label: "Account Name",
  inputBlurred: null,
  inputCleared: null,
  isRequired: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInput: (value: string) => {},
};

export const currencyInputOptions: GlossInputOptions = {
  placeholder: "Enter currency...",
  label: "Currency",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputBlurred: (value: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputCleared: () => {},
  isRequired: true,
  isDisabled: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInput: (value: string) => {},
  value: "AUD",
};

export const institutionInputOptions: GlossInputOptions = {
  placeholder: "Enter institution name...",
  label: "Institution / Bank",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputBlurred: (value: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputCleared: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInput: (value: string) => {},
  isRequired: true,
  isDisabled: true,
};

export const balanceInputOptions: GlossInputOptions = {
  suffix: "AUD",
  placeholder: "0",
  label: "Balance",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputBlurred: (value: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInput: (value: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputCleared: () => {},
  isRequired: true,
};
