import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import {
  AccountCreationParameters,
  InterestParameters,
  TransferParameters,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import {
  InstitutionAccount,
  InstitutionAccountLink,
  InstitutionInterest,
} from "@bitwarden/web-vault/app/models/types/institution.type";
import {
  ScenarioHelpInfo,
  ScenarioOptionWinner,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { ScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/scenario-option";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

export class InterestRateScenarioOptionUtils extends ScenarioOption {
  /**
   * getInterestRates - given an account ID, see if there exists any interest rates for that
   * account type in that institution
   * @param accountID
   */
  async getInterestRates(account: Book, symbol: string) {
    const institutionService = this.injector.get(InstitutionService);
    if (account && account?.institutionLink) {
      const { institutionId, institutionAccountId }: InstitutionAccountLink =
        account.institutionLink;
      const institution = await institutionService.getInstitutionById(institutionId);
      const institutionAccount = await institutionService.filterInstitutionAccountById(
        institutionAccountId,
        institution
      );
      if (institutionAccount) {
        const interestRates = institutionAccount.interestRates;

        // filter the interestRates by the symbol that we are looking for
        interestRates.filter((rate) => rate.symbol === symbol);

        return interestRates;
      }
    }
    return;
  }

  async getAccountUrl(account: Book) {
    const institutionService = this.injector.get(InstitutionService);
    if (account && account?.institutionLink) {
      const { institutionId, institutionAccountId }: InstitutionAccountLink =
        account.institutionLink;
      const institution = await institutionService.getInstitutionById(institutionId);
      const institutionAccount = await institutionService.filterInstitutionAccountById(
        institutionAccountId,
        institution
      );
      if (institutionAccount) {
        return institutionAccount.link;
      }
    }
    return;
  }

  async getInstitutionName(account: Book) {
    const institutionService = this.injector.get(InstitutionService);
    if (account && account?.institutionLink) {
      const { institutionId }: InstitutionAccountLink = account.institutionLink;
      const institution = await institutionService.getInstitutionById(institutionId);
      if (institution) {
        return institution.name;
      }
    }
    return;
  }

  /**
   * createParameters - helper function to create the parameters for an interest creation estimate action
   * @param account
   * @param interestRates
   */
  createInterestParameters(
    account: Book,
    interestRates: InstitutionInterest[],
    groupedBalance?: BalanceGrouping
  ): InterestParameters {
    return {
      account: account,
      accountBalances: this.startingAccountBalances,
      currency: this.scenarioGroup.symbol,
      startDate: this.startDate,
      endDate: this.endDate,
      interestRates: interestRates,
      userGeneratedEstimateTransactions: this.userEstimateTransactions,
      startingTransactions: this.startingTransactions,
    };
  }

  createTransferParameters(
    accountFrom: Book,
    accountTo: Book,
    amount: number,
    symbol: string
  ): TransferParameters {
    return {
      accountFrom: accountFrom, // account we are creating the transfer from
      accountTo: accountTo, // account we are creating the transfer to
      transactionDate: new Date(this.scenarioGroup.anchorPoint.date), // transaction date
      amount: amount,
      symbol: symbol,
    };
  }

  async addToHelpFromTransferParameters(
    helpInfo: ScenarioHelpInfo,
    transferParameters: TransferParameters,
    toRate?: Array<InstitutionInterest>,
    fromRate?: Array<InstitutionInterest>,
    toAccountUrl?: string,
    fromAccountUrl?: string,
    ToInstitutionName?: string,
    FromInstitutionName?: string,
    mockAccountName?: string
  ) {
    if (transferParameters?.amount) {
      if (helpInfo?.transferAmount) {
        helpInfo.transferAmount += transferParameters.amount;
      } else {
        helpInfo.transferAmount = transferParameters.amount;
      }
    }
    if (!helpInfo?.transferToAccount && transferParameters?.accountTo) {
      const accountToName = await this.getAccountType(transferParameters.accountTo);
      helpInfo.transferToAccount = {
        accountBank: ToInstitutionName,
        accountUrl: toAccountUrl,
        accountName: accountToName,
        rate: toRate,
      };
    }
    if (!helpInfo?.transferToAccount && mockAccountName) {
      helpInfo.transferToAccount = {
        accountBank: ToInstitutionName,
        accountUrl: toAccountUrl,
        accountName: mockAccountName,
        rate: toRate,
      };
    }
    if (transferParameters?.accountFrom) {
      const accountFromName = await this.getAccountType(transferParameters.accountFrom);
      if (helpInfo?.transferFromAccount) {
        helpInfo.transferFromAccount.push({
          accountBank: FromInstitutionName,
          accountUrl: fromAccountUrl,
          accountName: accountFromName,
          rate: fromRate,
        });
      } else {
        helpInfo.transferFromAccount = [
          {
            accountBank: FromInstitutionName,
            accountUrl: fromAccountUrl,
            accountName: accountFromName,
            rate: fromRate,
          },
        ];
      }
    }
  }

  async getAccountType(account: Book) {
    let accountType = account.name;
    const institutionService = this.injector.get(InstitutionService);

    if (account?.institutionLink?.institutionId) {
      const institutionalAccount = await institutionService.getAccountType(account);
      if (institutionalAccount && institutionalAccount?.name) {
        accountType = institutionalAccount.name;
      }
    }
    return accountType;
  }

  createAccountCreationParameters(
    institution: Institution,
    institutionalAccount: InstitutionAccount,
    currency: string
  ): AccountCreationParameters {
    return {
      accountName: institutionalAccount.name, // account name we are creating
      currency: currency, // default account currency
      newInstitutionalAccount: institutionalAccount,
      institution: institution,
    };
  }

  /**
   * calculateWinner - choose the winning permutation from the set
   */
  async calculateWinner(): Promise<ScenarioOptionWinner> {
    const winner = await super.calculateWinner();

    if (winner) {
      return winner;
    }

    if (this.permutations.length > 0) {
      // choose the permutation with the highest balance on the end date
      const winningPermutation = this.permutations.reduce((max, current) => {
        const maxBalance = this.getBalanceForPermutation(max);
        const currentBalance = this.getBalanceForPermutation(current);

        /** TODO @Sinan@Michelle fix for following:
         * TODO - have an account with int. rates  and import basiq => scenario 2 works
         * TODO - import basiq  then create an account with interest rates => scenario 2  does not work
         * TODO Keep in mind that accounts from Basiq does not have interest rates
         * */
        if (maxBalance && currentBalance) {
          return currentBalance > maxBalance ? current : max;
        }
        return maxBalance ? max : current;
      });
      const createdRecords = this.combineCreatedRecords(winningPermutation);
      const groupedBalance = await this.calculateFullGroupedBalance(createdRecords.transactions);
      const scenarioHelp = winningPermutation.scenarioHelpInfo;

      return {
        createdRecords: createdRecords,
        groupedBalance: groupedBalance,
        helpInfo: scenarioHelp,
      };
    } else {
      return {
        createdRecords: { accounts: [], transactions: [] },
        groupedBalance: await this.calculateFullGroupedBalance([]),
      };
    }
  }

  getSymbolValue(accountID: string, symbol: string): number {
    if (
      this.startingAccountBalances[accountID].balance.runningTotalValue?.[symbol]?.symbolAmount
        ?.amount
    ) {
      return this.startingAccountBalances[accountID].balance.runningTotalValue[symbol].symbolAmount
        .amount;
    }
    return 0;
  }

  isCredit(account: Book): boolean {
    return ["credit", "credit-card"].includes(account.type);
  }
}
