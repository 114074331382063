import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction.view";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";
import { ExperimentalMode } from "@bitwarden/web-vault/app/shared/utils/feature-flag/experimental-mode";
import { getDailyBalance } from "@bitwarden/web-vault/app/shared/utils/helper-balance";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";
import { LinkTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/link-transaction";
import { ValidatorResponseTypes } from "@bitwarden/web-vault/app/validators/link.transactions/link-validator";

export type ToasterType = "success" | "error" | "warning";

import "./transaction-table.scss";
@Component({
  selector: "app-transaction-table",
  templateUrl: "transaction-table.component.html",
  styles: ["transaction-table.scss"],
})
export class TransactionTableComponent extends LinkTransaction implements OnInit, OnChanges {
  @Input() transactionsView: Array<TransactionView>;
  @Input() transactions: Array<Transaction>;
  @Input() groupBalances: BalanceGrouping;
  @Input() mockAccounts: Array<Book>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;
  tdElement: HTMLTableCellElement;
  protected readonly top = top;
  mouseOnTd = false;
  mouseOnTooltip = false;
  hoveredTransaction: TransactionView;
  showTooltipBoolean = false;
  xPosition = 0;
  yPosition = 0;
  selectedTransactionId: string;
  dataSource: MatTableDataSource<TransactionView>;
  selection: SelectionModel<any>;
  initialised = false;
  isFirstChange = true;
  linkedId = "";
  baseCurrency = "";
  displayedColumns: string[] = [
    "link",
    "account",
    "transactionDate",
    "categories",
    "classifications",
    "description",
    "out",
    "in",
    "out_normalized",
    "in_normalized",
    "aggregate",
    "balance",
  ];
  showRevaluations = true;
  showEstimates = true;
  isActionExperimental: boolean;
  isHideEstimateExperimental: boolean;
  indexViewMap: Map<string, number> = new Map();
  constructor(
    private logService: LogService,
    private i18nService: I18nService,
    private transactionService: TransactionService,
    private dataRepositoryService: DataRepositoryService,
    private platformUtilsService: PlatformUtilsService,
    private router: Router,
    private helperPreference: HelperPreference,
    protected experimentalMode: ExperimentalMode,
    private cdRef: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private globalService: GlobalService
  ) {
    super();
  }

  async ngOnInit() {
    this.initialised = true;
    this.baseCurrency = await this.helperPreference.getBaseCurrency();
    this.isActionExperimental = await this.isActionsExperimental();
    this.isHideEstimateExperimental = await this.isEstimateExperimental();

    if (this.isActionExperimental) {
      this.displayedColumns.push("actions");
    }

    this.hoveredTransaction = this.transactionsView[0];
    this.selectedTransactionId = this.transactionsView[0]?.id;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transactionsView) {
      this.setupDataSource();
    }
  }

  callOnMouseEnterForTd(event: MouseEvent) {
    const spanElement = event.currentTarget as HTMLSpanElement;
    const tdElement = spanElement.parentElement;

    const mouseoverEvent = new MouseEvent("mouseEnter");
    tdElement.dispatchEvent(mouseoverEvent);
  }

  private getOffset(element: HTMLElement): { top: number; left: number } {
    let top = 0;
    let left = 0;

    while (element) {
      top += element.offsetTop;
      left += element.offsetLeft;
      element = element.offsetParent as HTMLElement;
    }

    return { top, left };
  }

  onMouseEnterTooltip(showTooltip: boolean) {
    this.mouseOnTooltip = true;
    this.showTooltipBox(true);
  }

  onMouseLeaveTooltip(showTooltip: boolean) {
    this.mouseOnTooltip = false;
    setTimeout(() => {
      if (!this.mouseOnTooltip && !this.mouseOnTd) {
        this.showTooltipBox(false);
      }
    });
  }
  onMouseEnter(event: MouseEvent, hoveredTransaction: TransactionView) {
    this.mouseOnTd = true;
    this.hoveredTransaction = hoveredTransaction;
    if (this.hoveredTransaction?.detailedBalance?.length) {
      this.tdElement = event.currentTarget as HTMLTableCellElement;
      this.selectedTransactionId = hoveredTransaction.id;
      const accountCount = this.hoveredTransaction.detailedBalance.length;
      let symbolCount = 0;
      for (const account of this.hoveredTransaction.detailedBalance) {
        symbolCount = symbolCount + account.amounts.length;
      }
      const rect = this.getOffset(this.tdElement);
      this.xPosition = rect.left;
      this.yPosition = rect.top - accountCount * 12 - symbolCount * 2;

      this.showTooltipBox(true);
    } else {
      this.showTooltipBox(false);
    }
  }

  onMouseLeave() {
    this.mouseOnTd = false;
    setTimeout(() => {
      if (!this.mouseOnTd && !this.mouseOnTooltip) {
        this.showTooltipBox(false);
      }
    }, 500);
  }

  showTooltipBox(showTooltip: boolean) {
    this.showTooltipBoolean = showTooltip;
    if (!showTooltip) {
      this.yPosition = 0;
      this.xPosition = 0;
    }
  }

  sortColumns(transactionView: TransactionView, property: string) {
    const quantity = transactionView.quantity.actualQuantity.amount;
    switch (property) {
      case "transactionDate": {
        return new Date(transactionView.transactionDate).getTime();
      }
      case "symbol": {
        return transactionView.quantity.actualQuantity.symbol;
      }
      case "description": {
        return transactionView.description.toLowerCase();
      }
      case "quantity": {
        return quantity;
      }
      case "out": {
        return transactionView.direction === TransactionDirection.Out ? quantity : null;
      }
      case "in": {
        return transactionView.direction === TransactionDirection.In ? quantity : null;
      }
      case "out_normalized": {
        if (transactionView.direction === TransactionDirection.Out) {
          return -1 * transactionView.valuation.normalizedValue.amount;
        } else {
          return null;
        }
      }
      case "in_normalized": {
        if (transactionView.direction === TransactionDirection.In) {
          return transactionView.valuation.normalizedValue.amount;
        } else {
          return null;
        }
      }
      case "aggregate": {
        return transactionView.valuation.normalizedValue.amount;
      }
      case "direction": {
        return transactionView.direction;
      }
      case "account": {
        return transactionView.accountId;
      }
      case "currency": {
        return transactionView.quantity.currency;
      }
      case "balance": {
        return transactionView.dailyBalance;
      }
      case "classifications": {
        const classifications = transactionView.classifications
          .map((splitCategory) => splitCategory.name)
          .join(", ");
        return Array.isArray(transactionView.classifications)
          ? classifications.toLowerCase()
          : null;
      }
      case "categories": {
        const categories = transactionView.categories
          .map((splitCategory) => splitCategory.name)
          .join(", ");
        return Array.isArray(transactionView.categories) ? categories.toLowerCase() : null;
      }
    }
  }

  isDirectOut(d: TransactionDirection) {
    return d === TransactionDirection.Out;
  }

  isDirectIn(d: TransactionDirection) {
    return d === TransactionDirection.In;
  }

  highlightRow(transaction: TransactionView, id = "") {
    return this.linkedTo.includes(transaction.id) ? "linked" : "";
  }

  onHover(item: TransactionView) {
    this.linkedTo = item.linkedTo;
  }

  onOut() {
    this.linkedTo = [];
  }

  async linkToggle(event: MatCheckboxChange, transactionView: TransactionView) {
    const isReval = this.isRevaluation(transactionView);
    if (!this.isValidToLink(isReval)) {
      this.check[transactionView.id] = false;
      await this.showToaster(isReval, this.platformUtilsService, this.i18nService);
      event.source.toggle();
      return;
    }

    const isSelected = event.source.checked;
    const linkedViews = this.transactionsView.filter((view, index) => {
      return transactionView.linkedTo.includes(view.id) || view.id === transactionView.id;
    });
    const uniqueIds: Set<string> = new Set();
    const allLinkViews = linkedViews.filter((linkView) => {
      if (!uniqueIds.has(linkView.id)) {
        uniqueIds.add(linkView.id);
        return true;
      }

      return false;
    });
    const linkTransactions = DataTransformer.castToTransactionArray(allLinkViews);
    for (const trans of linkTransactions) {
      if (isSelected) {
        if (trans.id !== transactionView.id) {
          this.check[trans.id] = true;
        }
        this.selectToLink(trans);
      } else {
        if (trans.id !== transactionView.id) {
          this.check[trans.id] = false;
        }
        this.unselectToLink(trans);
      }
    }
  }

  handleEdit(transactionView: TransactionView) {
    this.router.navigate(["/add-edit-transaction"], {
      queryParams: { transactionId: transactionView.id },
    });
  }

  async refreshDataSource() {
    const transactions = await this.dataRepositoryService.getAllTransactions();
    this.dataSource.data = this.transactionsView.map((transactionView) => {
      if (this.linkIds.includes(transactionView.id)) {
        const linkedTransaction = transactions.find(
          (transaction) => transaction.id === transactionView.id
        );
        if (linkedTransaction) {
          return transactionView.update(linkedTransaction);
        } else {
          return transactionView;
        }
      } else {
        return transactionView;
      }
    });

    this.transactionsView = this.dataSource.data;
  }

  async linkConversion() {
    try {
      this.loading = true;
      const isConversion = this.isConversion(this.linkedTransactions);
      if (!this.isValidToLink(isConversion)) {
        await this.showToaster(isConversion, this.platformUtilsService, this.i18nService);
        return;
      }
      await this.saveLink(isConversion);
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async linkTransfer() {
    try {
      this.loading = true;
      const isTransfer = this.isTransfer(this.linkedTransactions);
      if (!this.isValidToLink(isTransfer)) {
        await this.showToaster(isTransfer, this.platformUtilsService, this.i18nService);
        return;
      }
      await this.saveLink(isTransfer);
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async unlinkTransactions() {
    try {
      const { unlinkTransactions, newTransactionsView } = await this.removeLinkage(
        this.transactionsView
      );
      this.transactionsView = newTransactionsView;
      await this.save(this.UNLINK_RESPONSE, unlinkTransactions);
      this.loading = false;
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async deleteTransaction() {
    try {
      const isValidToDelete = this.validateDelete(this.linkedTransactions);
      if (!this.isRealTransactions(this.linkedTransactions)) {
        await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);
        return;
      }

      const confirm = await this.globalService.showDialogue(
        "areYouSureToDeleteTransactions",
        "warning"
      );

      if (confirm) {
        this.loading = true;
        const areDeleted = await this.transactionService.bulkDelete(this.linkedTransactions);
        if (areDeleted) {
          await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);
        } else {
          isValidToDelete.status = "warning";
          isValidToDelete.messageId = "missingTransactionsToDelete";
          await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);
        }
        await this.dashboardService.loadDashboardData();
        this.resetProps();
        this.loading = false;
      }
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  private async saveLink(response: ValidatorResponseTypes) {
    await this.save(response, this.linkedTransactions);
  }

  private async save(response: ValidatorResponseTypes, transactions: Transaction[]) {
    for (const transaction of transactions) {
      await this.transactionService.update(transaction);
    }

    await this.refreshDataSource();
    await this.showToaster(response, this.platformUtilsService, this.i18nService);
    this.resetProps();
  }

  setCheckBoxIndex() {
    if (this.isFirstChange && this.transactionsView.length) {
      this.transactionsView.forEach((view, index) => {
        this.indexViewMap.set(view.id, index);
        this.check[view.id] = false;
      });

      this.isFirstChange = false;
    }
  }

  private setupDataSource() {
    this.setCheckBoxIndex();
    const transactionsView = getDailyBalance(this.transactionsView, this.groupBalances);

    if (this.mockAccounts && this.mockAccounts.length > 0) {
      for (const transaction of transactionsView) {
        transaction.setMockAccount(this.mockAccounts);
      }
    }

    // Filter the data based on showRevaluations
    const filteredData = this.filterDataSource(
      transactionsView,
      this.showRevaluations,
      this.showEstimates
    );

    this.dataSource = new MatTableDataSource<TransactionView>(filteredData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.sortColumns;

    /** Set the default sorting direction for the 'transactionDate' column */
    if (this.sort) {
      // this.sort.active = "transactionDate";
      // this.sort.direction = "desc";
      this.dataSource.sort = this.sort;
    }
  }

  onToggleChange() {
    this.setupDataSource();
  }

  private filterDataSource(
    data: TransactionView[],
    showRevaluations: boolean,
    showEstimates: boolean
  ): TransactionView[] {
    if (showRevaluations) {
      // When showRevaluations is true, no filtering is applied based on revalTransaction
      return showEstimates ? data : data.filter((item) => !item.estimateTransaction);
    } else {
      // When showRevaluations is false, show items with revalTransaction as false and showEstimates based on the specified value
      return data.filter(
        (item) => !item.revalTransaction && (showEstimates ? true : !item.estimateTransaction)
      );
    }
  }

  async isActionsExperimental() {
    return await this.experimentalMode.isExperimental("transaction", "action");
  }

  async isEstimateExperimental() {
    return await this.experimentalMode.isExperimental("transaction", "toggleEstimate");
  }

  protected readonly GlossDate = GlossDate;
}
