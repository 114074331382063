import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/components/customer-support-box/customer-support-box.component";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { scopeEnum } from "@bitwarden/web-vault/app/models/scope/typeDef";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

@Component({
  selector: "app-settings-navigation",
  templateUrl: "settings-navigation.component.html",
})
export class SettingsNavigationComponent implements OnInit, OnDestroy {
  protected readonly RoleScope = RoleScope;
  private destroy$ = new Subject<void>();
  accountLink = "account";
  securityLink = "security";
  subscriptionLink = "subscription";
  hasScope = false;
  earlyRequestDialogRef: MatDialogRef<CustomerSupportBoxComponent>;

  constructor(
    private router: Router,
    private helperCommon: HelperCommon,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    if (this.isPreferencesRoute()) {
      this.setSettingsRoute();
    }

    this.hasScope = await this.helperCommon.hasScope(scopeEnum.EARLY_ACCESS);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  requestEarlyAccess() {
    this.earlyRequestDialogRef = this.dialog.open(CustomerSupportBoxComponent, {
      panelClass: "no-background-dialog",
      data: {
        supportBoxHeader: "Request An Early Access",
        dialogOpen: true,
        closeDialogue: this.closeEarlyRequestDialog.bind(this),
      },
      disableClose: true,
    });

    this.earlyRequestDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }

  private isPreferencesRoute(): boolean {
    return this.router.url.includes("preferences");
  }

  private setSettingsRoute() {
    this.accountLink = "/settings/account";
    this.securityLink = "/settings/security";
    this.subscriptionLink = "/settings/subscription";
  }
}
