import { Component, EventEmitter, HostListener, Injector, Output } from "@angular/core";
import { Route, Routes } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { devFlagEnabled } from "@bitwarden/common/misc/flags";
import { RouterService } from "@bitwarden/web-vault/app/core";
import { SideMenu } from "@bitwarden/web-vault/app/layouts/menu/side-menu";
import { SideMenuActions } from "@bitwarden/web-vault/app/layouts/menu/state/side-menu.action";
import { getSideMenu } from "@bitwarden/web-vault/app/layouts/menu/state/side-menu.reducer";
import { UserLayoutComponent } from "@bitwarden/web-vault/app/layouts/user-layout.component";
import { RoutesType } from "@bitwarden/web-vault/app/models/enum/routesType";
import {
  StateManagement,
  StoreState,
} from "@bitwarden/web-vault/app/services/DataService/state-management/state.management";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";
import "./app-menu.component.scss";

@Component({
  selector: "app-menu",
  templateUrl: "./app-menu.component.html",
  styles: ["app-menu.component.scss"],
})
export class AppMenuComponent extends SideMenu {
  @Output() changeHelpOpen = new EventEmitter<boolean>();

  protected readonly HelperCommon = HelperCommon;
  protected readonly process = process;
  protected readonly EventEmitter = EventEmitter;

  menuRoutes: Route = {};
  glossSettingsRoute: Route = {};
  glossSettings: Routes = [];
  routeNames: any = RoutesType;
  sideMenu$: Observable<SideMenu>;
  sideMenu: SideMenu;
  stateManagement: StateManagement;
  selectedKey = "dashboard";
  isExpanded: Record<string, any> = {
    navigation: true,
    settingPanel: false,
    transactionPanel: false,
    referenceDataPanel: false,
  };

  constructor(
    private store: Store<StoreState>,
    private glossMenuService: SideMenuService,
    private routerService: RouterService,
    private injector: Injector
  ) {
    super();
    this.stateManagement = injector.get(StateManagement);
  }

  ngOnInit() {
    this.sideMenu$ = this.store.select(getSideMenu);
    this.sideMenu = this.getSideMenuState(this.stateManagement, this.sideMenu$);
    this.menuRoutes = this.routerService
      .getRoutes()
      .find((route) => route.component === UserLayoutComponent);
    this.glossSettingsRoute = this.menuRoutes.children.find(
      (child) => child.path === "gloss-settings"
    );
    this.glossSettings = this.glossSettingsRoute.children.slice(1);
  }

  @HostListener("click", ["$event"])
  onClickEvt(event: any) {
    event.preventDefault();
    const elementId = this.getSubMenuId(event);

    if (elementId) {
      this.loadSideMenuState();
      const sideMenu = this.resetSideMenu(this.sideMenu) as SideMenu;

      SideMenuActions.toggle({ sideMenu });
      this.isExpanded = sideMenu;
    }
  }

  handleToggleNavigation() {
    //TODO this is commented out as per request for having a static sidebar for now
    /*    this.loadSideMenuState();
    const sideMenu = this.toggleNavigation(this.sideMenu) as SideMenu;
    this.store.dispatch(SideMenuActions.toggle({ sideMenu }));

    this.isExpanded = {
      ...this.isExpanded,
      navigation: sideMenu.navigation,
    };*/
  }

  expandPanel(panelName: string, event: Event) {
    event.preventDefault();
    this.loadSideMenuState();
    Object.keys(this.isExpanded).forEach((key) => {
      if (key !== "navigation" && key !== panelName) {
        this.isExpanded = { ...this.isExpanded, [key]: false };
      }
    });

    const sideMenu = this.toggleSideMenu(this.sideMenu, panelName) as SideMenu;
    this.store.dispatch(SideMenuActions.toggle({ sideMenu }));
    this.isExpanded = { ...this.isExpanded, [panelName]: true, navigation: true };
  }

  isDevEnv() {
    return devFlagEnabled("development");
  }

  private loadSideMenuState() {
    this.sideMenu$ = this.store.select(getSideMenu);
    this.sideMenu = this.getSideMenuState(this.stateManagement, this.sideMenu$);
  }

  selectLink(key: string) {
    if (key === "help") {
      this.changeHelpOpen.emit(true);
    } else {
      this.selectedKey = key;
    }
  }
}
