<div class="card">
  <div class="card-header">{{ "accountSettings" | i18n }}</div>
  <div class="list-group list-group-flush">
    <a
      *appRoleScope="RoleScope.GLOSS"
      routerLink="{{ accountLink }}"
      class="list-group-item"
      routerLinkActive="active"
    >
      {{ "myAccount" | i18n }}
    </a>
    <a
      *appRoleScope="RoleScope.GLOSS"
      routerLink="{{ securityLink }}"
      class="list-group-item"
      routerLinkActive="active"
    >
      {{ "security" | i18n }}
    </a>
    <!--  todo reintroduce after apple store in-app purschase  <a-->
    <!--      *ngIf="hasScope"-->
    <!--      routerLink="{{ subscriptionLink }}"-->
    <!--      class="list-group-item"-->
    <!--      routerLinkActive="active"-->
    <!--    >-->
    <!--      {{ "subscription" | i18n }}-->
    <!--    </a>-->
    <!--    <a *ngIf="!hasScope" class="list-group-item tw-cursor-pointer" (click)="requestEarlyAccess()">-->
    <!--      {{ "requestEarlyAccess" | i18n }}-->
    <!--    </a>-->
    <a
      *appRoleScope="RoleScope.BETA_ACCESS"
      routerLink="/gloss-settings/preferences"
      class="list-group-item"
      routerLinkActive="active"
    >
      {{ "preferences" | i18n }}
    </a>
  </div>
</div>
