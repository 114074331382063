<app-spinner [showSpinner]="loading"></app-spinner>
<div class="content">
  <div class="page-header d-flex">
    <div class="ml-auto d-flex">
      <button class="btn btn-sm btn-outline-primary ml-3" type="button" (click)="invite()">
        <i aria-hidden="true" class="bwi bwi-plus bwi-fw"></i>
        Add New Class
      </button>
    </div>
  </div>
  <div height="200px">
    <p *ngIf="existingClassifications && !existingClassifications.length">
      There are currently no classes created. Please create new classes in the system or connect
      your finance institutions.
    </p>
  </div>
  <table class="table table-hover table-list mb-0" min-height="200px">
    <tbody>
      <tr *ngFor="let classification of existingClassifications; let i = index">
        <td width="30">
          <bit-avatar
            [text]="classification.name"
            [id]="classification.id"
            size="small"
          ></bit-avatar>
        </td>
        <td>
          <a href="#" appStopClick (click)="edit(classification)">{{ classification.name }}</a>
        </td>
        <td class="table-list-options">
          <button
            [bitMenuTriggerFor]="accountOptions"
            class="tw-border-none tw-bg-transparent tw-text-main"
            type="button"
            appA11yTitle="{{ 'options' | i18n }}"
          >
            <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
          </button>
          <bit-menu #accountOptions>
            <button bitMenuItem (click)="delete(classification)">
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "remove" | i18n }}
            </button>
            <button bitMenuItem (click)="edit(classification)">
              <i class="bwi bwi-fw bwi-pencil" aria-hidden="true"></i>
              {{ "edit" | i18n }}
            </button>
          </bit-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>
