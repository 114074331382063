import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";

import { Transaction } from "../../models/data/blobby/transaction.data";
import { TransactionView } from "../../models/view/transaction.view";
import { DashboardService } from "../../services/dashboard/dashboard-service";

@Component({
  selector: "app-filtered-transactions-table-card",
  templateUrl: "./filtered-transactions-table-card.component.html",
})
export class FilteredTransactionsTableCardComponent implements OnInit {
  @Input() title: string;
  private unsubscribe$ = new Subject<void>();
  protected readonly RoleScope = RoleScope;

  filteredTransactions: Array<Transaction> = [];
  filteredTransactionsView: Array<TransactionView> = [];
  groupBalances: BalanceGrouping;
  mockAccounts: Array<Book>;

  constructor(private dashboardService: DashboardService, private logger: LogService) {}

  async ngOnInit(): Promise<void> {
    setTimeout(() => {
      this.subscribeToDashboardService();
    }, 0);
  }

  subscribeToDashboardService() {
    this.logger.debug("FilteredTransactionsTableCardComponent: subscribeToDashObservables");
    /** Subscribe to the filtered transaction list on the Dashboard Service */
    this.dashboardService.filteredTransactions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filteredTransactions) => {
        if (filteredTransactions) {
          this.filteredTransactions = filteredTransactions;
          this.filteredTransactionsView = this.filteredTransactions.map(
            (transaction: Transaction) => new TransactionView(transaction)
          );
        }
      });

    /** Subscribe to groupBalances to get the granularity balance */
    this.dashboardService.groupBalances$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((groupBalances) => {
        this.groupBalances = groupBalances;
      });

    /** Subscribe to mockAccounts to get fake account names for table */
    this.dashboardService.mockAccounts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mockAccounts) => {
        this.mockAccounts = mockAccounts;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
