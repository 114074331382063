<ng-container>
  <app-spinner [showSpinner]="loading"></app-spinner>
  <div class="content tw-flex tw-gap-4 xs:tw-flex-col">
    <app-settings-navigation></app-settings-navigation>
    <div class="user-account-settings">
      <div class="page-header">
        <h1>{{ "preferences" | i18n }}</h1>
      </div>
      <form class="modal-content" id="preference" (ngSubmit)="submit()">
        <ng-container>
          <div class="form-group mb-4">
            <label>Base currency *</label>
            <div class="input-search-container">
              <input
                disabled
                class="form-control"
                type="text"
                id="baseCurrency"
                [(ngModel)]="preferredCurrency"
                placeholder="Pick a currency"
                aria-label="Number"
                matInput
                (keyup)="searchForCurrency()"
                (blur)="onBaseCurrencyBlur()"
                name="currency"
                [matAutocomplete]="autoCurrency"
              />

              <svg class="tw-absolute tw-right-2 tw-h-7 tw-w-7">
                <use xlink:href="images/sprite.svg#down-arrow" />
              </svg>

              <mat-autocomplete autoActiveFirstOption #autoCurrency="matAutocomplete">
                <mat-option *ngFor="let option of filteredCurrencies" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!hideField('timezone')">
          <br />
          <div class="form-group mb-4">
            <label>Timezone *</label>
            <div class="input-search-container">
              <input
                class="form-control"
                type="text"
                id="baseTimeZone"
                [(ngModel)]="preferredTimeZone"
                placeholder="Pick a timezone"
                aria-label="Number"
                (keyup)="searchForTimeZones()"
                (blur)="onTimeZonesBlur()"
                matInput
                name="zone"
                [matAutocomplete]="autoTimezones"
              />

              <!--            <i aria-hidden="true" class="bwi bwi-caret-down bwi-sm"></i>-->

              <mat-autocomplete autoActiveFirstOption #autoTimezones="matAutocomplete">
                <mat-option *ngFor="let tz of filteredZones" [value]="tz">
                  {{ tz }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </ng-container>

        <br />

        <ng-container>
          <div class="form-group mb-4">
            <label>Date format *</label>
            <div class="input-search-container">
              <input
                disabled
                class="form-control"
                type="text"
                id="baseDateFormat"
                [(ngModel)]="preferredDateFormat"
                placeholder="Pick a date format"
                aria-label="Date"
                (keyup)="searchForDateFormat()"
                (blur)="onDateFormatBlur()"
                matInput
                name="date"
                [matAutocomplete]="autoDateFormat"
              />

              <!--            <i aria-hidden="true" class="bwi bwi-caret-down bwi-sm"></i>-->
              <svg class="tw-absolute tw-right-2 tw-h-7 tw-w-7">
                <use xlink:href="images/sprite.svg#down-arrow" />
              </svg>

              <mat-autocomplete autoActiveFirstOption #autoDateFormat="matAutocomplete">
                <!--              this one has display none in it, its in the 7/18/2024 commit so I just comment it out here in case its intended-->
                <!--              class="mat-select-arrow"-->
                <mat-option *ngFor="let df of filteredDateFormats" [value]="df">
                  {{ df }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </ng-container>

        <br />

        <ng-container>
          <div class="form-group mb-4">
            <label>Mode</label>
            <div class="input-search-container">
              <!--            <i aria-hidden="true" class="bwi bwi-caret-down bwi-sm"></i>-->
              <div class="form-control">
                <mat-select [(value)]="preferredMode" id="baseMode">
                  <mat-option *ngFor="let m of modes" [value]="m">
                    {{ m }}
                  </mat-option>
                </mat-select>
              </div>
              <svg class="tw-absolute tw-right-2 tw-h-7 tw-w-7">
                <use xlink:href="images/sprite.svg#down-arrow" />
              </svg>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!hideField('dateStart')">
          <div class="form-group mb-4">
            <label>Year Start Month</label>
            <div class="form-control">
              <mat-select [(value)]="preferredVisualYearMonthStartName" id="yearMonths">
                <mat-option *ngFor="let ym of yearMonthsArr" [value]="ym.month">
                  {{ ym.month }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <br />
          <div class="form-group mb-4">
            <label>Week Start Day</label>
            <div class="form-control">
              <mat-select [(value)]="preferredVisualWeekDayStartName" id="weekDays">
                <mat-option *ngFor="let wd of weekDaysArr" [value]="wd.day">
                  {{ wd.day }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <br />
          <div class="form-group mb-4">
            <label>Month Start Day</label>
            <div class="input-search-container">
              <div class="form-control">
                <mat-select [(value)]="preferredMonthDayStart" id="monthDays">
                  <mat-option *ngFor="let md of monthDays" [value]="md">
                    {{ md }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </ng-container>

        <div>
          <!--  <button mat-raised-button mat-dialog-close (click)="cancelChanges()">
          {{ "cancel" | i18n }}
        </button>-->
          <button mat-raised-button color="primary" type="submit">
            {{ "save" | i18n }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
