import { Injector } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { dayGranularity } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import {
  ScenarioOptionWinner,
  CreatedRecords,
  ScenarioPermutation,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";
import { EstimateActionsFactory } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/estimate-actions.factory";
import { TransactionCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.calculation.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";

export class ScenarioOption {
  injector: Injector;
  permutations: Array<ScenarioPermutation>;

  winner: ScenarioOptionWinner;

  userEstimateTransactions: Array<Transaction>;
  startingTransactions: Array<Transaction>;
  startingAccountBalances: Record<string, GroupingNode>;
  groupedBalance: BalanceGrouping;

  scenarioGroup: ScenarioGroup;
  startDate: Date;
  endDate: Date;

  constructor(
    scenarioGroup: ScenarioGroup,
    injector: Injector,
    startingTransactions?: Array<Transaction>
  ) {
    this.scenarioGroup = scenarioGroup;
    this.startDate = new Date(scenarioGroup.anchorPoint.date);
    this.endDate = new Date(scenarioGroup.endDate.date);
    this.injector = injector;

    if (startingTransactions) {
      this.startingTransactions = startingTransactions;
    }
    if (this.scenarioGroup?.startingBalances) {
      this.startingAccountBalances = this.scenarioGroup.startingBalances;
    }
  }

  async beforeRunningPermutationsHook() {
    if (!this.startingTransactions) {
      const transactionCalculationService = this.injector.get(TransactionCalculationService);
      this.startingTransactions =
        await transactionCalculationService.createStartingTransactionsFromBalance(
          this.startDate,
          this.startingAccountBalances,
          "Starting Transactions at Anchor Point",
          this.injector
        );
    }
    this.userEstimateTransactions = await this.getUserGeneratedEstimateTransactions();
    await this.createPermutations();
    return;
  }

  async afterRunningPermutationsHook() {
    this.winner = await this.calculateWinner();
    return;
  }

  /**
   * createPermutations - Create the different possible sets of permutations to run
   *                      By default, this should only run the ones from blobby so that it will
   *                      work for user generated scenarios eventually
   */
  async createPermutations() {
    // TODO: Update this to create a single permutation that runs the estimate actions that are saved to Blobby
    this.permutations = [];
  }

  /**
   * calculateWinner - choose the winning permutation from the set
   */
  async calculateWinner(): Promise<ScenarioOptionWinner> {
    // if only 1 permuatation that that is always the winner
    if (this.permutations.length === 1) {
      const winningPermutation = this.permutations[0];

      const createdRecords = this.combineCreatedRecords(winningPermutation);
      const groupedBalance = await this.calculateFullGroupedBalance(createdRecords.transactions);
      const scenarioHelp = winningPermutation.scenarioHelpInfo;

      return {
        createdRecords: createdRecords,
        groupedBalance: groupedBalance,
        helpInfo: scenarioHelp,
      };
    }
  }

  /**
   * combineCreatedRecords - Combines all the created records from each estimate action that was run for a
   *                        permutation
   * @param permutation
   */
  combineCreatedRecords(permutation: ScenarioPermutation): CreatedRecords {
    const createdRecords: CreatedRecords = {
      transactions: [],
      accounts: [],
    };
    for (const estimateAction of permutation.estimateActions) {
      if (estimateAction?.createdRecords?.transactions) {
        createdRecords.transactions = createdRecords.transactions.concat(
          estimateAction.createdRecords.transactions
        );
      }
      if (estimateAction?.createdRecords?.accounts) {
        createdRecords.accounts = createdRecords.accounts.concat(
          estimateAction.createdRecords.accounts
        );
      }
    }
    return createdRecords;
  }

  /**
   * calculateFullGroupedBalance - Combines the starting transactions with the user generated
   *                               estimate transactions and the estimate action transactions to
   *                               produce a complete Balance Grouping for graphing purposes
   * @param transactions
   */
  async calculateFullGroupedBalance(transactions: Array<Transaction>): Promise<BalanceGrouping> {
    const fullTransactionList = [
      ...this.startingTransactions,
      ...this.userEstimateTransactions,
      ...transactions,
    ];

    const transactionCalculationService = this.injector.get(TransactionCalculationService);
    const groupedBalance = await transactionCalculationService.recalculateBalance(
      fullTransactionList,
      true,
      null,
      null,
      true
    );
    return groupedBalance;
  }

  /**
   *
   */
  getBalanceForPermutation(permutation: ScenarioPermutation) {
    if (permutation.estimateActions.length > 0) {
      const finalBalance =
        permutation.estimateActions[permutation.estimateActions.length - 1].groupedBalance;

      const transactionCalculationService = this.injector.get(TransactionCalculationService);
      const finalBalanceAmount = transactionCalculationService.getNormalizedBalanceByDate(
        finalBalance,
        this.endDate
      );
      return finalBalanceAmount;
    }
  }

  async run(): Promise<ScenarioOptionWinner> {
    await this.beforeRunningPermutationsHook();

    // run through each possible permutation of the scenario
    for (const permutation of this.permutations) {
      const groupedBalance = await this.createGroupingFromStartingTransactions();
      const createdRecords: CreatedRecords = { groupedBalance: groupedBalance };
      // run all the estimate actions for each permutation
      for (const estimateActionParams of permutation.estimateActions) {
        const estimateAction = await EstimateActionsFactory.createEstimateActions(
          estimateActionParams.estimateActionType
        );
        await estimateAction.run(estimateActionParams.parameters, this.injector, createdRecords);
        if (estimateAction?.createdRecords) {
          estimateActionParams.createdRecords = estimateAction.createdRecords;
          if (estimateAction?.createdRecords?.groupedBalance) {
            // save and pass the groupedBalance to the next estimateAction run
            createdRecords.groupedBalance = estimateAction.createdRecords.groupedBalance;
            estimateActionParams.groupedBalance = estimateAction.createdRecords.groupedBalance;
          }
          // save and pass any created accounts to the next estimateAction run
          if (estimateAction?.createdRecords?.accounts) {
            createdRecords.accounts = estimateAction?.createdRecords?.accounts;
          }
        }
      }
    }

    await this.afterRunningPermutationsHook();

    return this.winner;
  }

  async getUserGeneratedEstimateTransactions(): Promise<Array<Transaction>> {
    const transactions: Array<Transaction> = [];

    // TODO:
    // get list of only user generated estimate actions
    // run each estimate action with the required parameters
    // add the created transactions to the transactions array

    return transactions;
  }

  /**
   *
   */
  async runEstimateActions(): Promise<Array<Transaction>> {
    const transactions: Array<Transaction> = [];

    return transactions;
  }

  async createGroupingFromStartingTransactions(): Promise<BalanceGrouping> {
    const preferenceService = this.injector.get(PreferenceService);
    const groupedBalance = new BalanceGrouping(this.startingTransactions, preferenceService);
    await groupedBalance.buildBalanceGrouping(
      false,
      null,
      true,
      true,
      ["account"],
      [dayGranularity]
    );
    return groupedBalance;
  }
}
