import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component } from "@angular/core";
import { format } from "date-fns";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { RecurringPeriod } from "@bitwarden/common/enums/recurringPeriod";
import { TimeGranularity } from "@bitwarden/common/enums/timeGranularity";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";

import { EstimateActionsService } from "../../services/DataService/estimates/estimate-actions-service";

@Component({
  selector: "app-dashboard-projection",
  templateUrl: "./dashboard-projection.component.html",
})
export class DashboardProjectionComponent {
  projectedTransactions: Array<Transaction>;
  defaultStartDate: string;
  defaultEndDate: string;
  defaultGranularity: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private blobbyService: BlobbyService,
    private estimatesService: EstimateActionsService
  ) {}

  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 3,
          largeChart: { cols: 3, rows: 1 },
          smallChart: { cols: 1, rows: 1 },
          table: { cols: 3, rows: 5 },
        };
      }

      return {
        columns: 3,
        largeChart: { cols: 3, rows: 1 },
        smallChart: { cols: 1, rows: 1 },
        table: { cols: 3, rows: 5 },
      };
    })
  );

  async ngOnInit(): Promise<void> {
    this.defaultGranularity = TimeGranularity.Monthly;
    // create dates for two years into the future starting with the first of the current month
    const formattedStartDate = format(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      "yyyy-MM-dd"
    );
    this.defaultStartDate = formattedStartDate;

    const formattedEndDate = format(
      new Date(new Date().getFullYear() + 2, new Date().getMonth(), -1),
      "yyyy-MM-dd"
    );
    this.defaultEndDate = formattedEndDate;

    this.subscribeToEstimates();

    // generate the projected transactions for these dates
    await this.estimatesService.updateDates(
      formattedStartDate,
      formattedEndDate,
      1,
      RecurringPeriod.Months
    );

    // this.projectedTransactions = projectedTransactions;

    return null;
  }

  // subscribe to the observable on the EstimateActionData Service
  subscribeToEstimates() {
    this.estimatesService.projectedEstimates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((projectedTransactions) => {
        this.projectedTransactions = projectedTransactions;
      });
  }

  ngOnDestroy() {
    // use this to remove any subscriptions to any destroyed observables
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
