import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";

@Component({
  selector: "app-projected-top-expenses-card",
  templateUrl: "./projected-top-expenses-card.component.html",
})
export class ProjectedTopExpensesCardComponent implements OnInit {
  @Input() title: string;
  @Input() projectedTransactions: Array<Transaction>;

  dataSetOut: Array<any>;
  dataLabels: Array<string>;

  constructor(private categoryService: CategoryService) {}
  async ngOnInit(): Promise<void> {
    return null;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.projectedTransactions) {
      await this.parseGraphData();
    }
  }

  private async parseGraphData() {
    // only get transactions with direction out
    // sum them up based on the category-renderer split
    const newDataSetOut: Array<any> = [];
    const newDataLabels: Array<string> = [];

    if (this.projectedTransactions && Array.isArray(this.projectedTransactions)) {
      for (const transaction of this.projectedTransactions) {
        const actualQuantity = transaction.quantity.actualQuantity.amount;
        if (transaction.direction == TransactionDirection.Out) {
          const categoryList = transaction.categories;
          let category = "No Category";
          if (categoryList) {
            // TODO: come back and fix this when we have allocations
            if (Array.isArray(categoryList) && categoryList.length > 0) {
              category = categoryList[0].name;
            }
          }
          if (!newDataLabels.includes(category)) {
            newDataLabels.push(category);
            newDataSetOut.push({ quantity: actualQuantity, category: category });
          } else {
            const index = newDataLabels.indexOf(category);
            newDataSetOut[index].quantity = newDataSetOut[index].quantity + actualQuantity;
          }
        }
      }
    }

    newDataSetOut.sort((a, b): number => {
      if (a.quantity > b.quantity) {
        return -1;
      } else if (a.quantity < b.quantity) {
        return 1;
      } else {
        return a.category.localeCompare(b.category);
      }
    });

    const finalDataSetOut: Array<any> = [];
    const finalDataLabels: Array<string> = [];

    newDataSetOut.forEach((element, index) => {
      finalDataSetOut[index] = -element.quantity;
      finalDataLabels[index] = element.category;
    });

    this.dataSetOut = finalDataSetOut.slice(0, 10);
    this.dataLabels = finalDataLabels.slice(0, 10);
  }
}
