/**
 * Gloss number pair will be used throughout the system to represent
 * the original symbol and quantity that is tied to a normalized
 * amount in the base currency. Previously these two numbers were
 * often not aligned together, so it was more difficult to piece
 * together the origin of the normalized number.
 *
 * This also allows us to be able to extract the raw quantity of a symbol
 * or the normalized value depending on the need of the circumstance such as
 * for graphing purposes.
 *
 */
import { GlossNumber } from "./gloss-number";

export class NormalizedPair {
  private _symbolAmount: GlossNumber = new GlossNumber();
  private _normalizedValue: GlossNumber = new GlossNumber();

  get symbolAmount(): GlossNumber {
    return this._symbolAmount;
  }

  set symbolAmount(symbolAmount: GlossNumber) {
    this._symbolAmount = symbolAmount;
  }

  get normalizedValue(): GlossNumber {
    return this._normalizedValue;
  }

  set normalizedValue(normalizedValue: GlossNumber) {
    this._normalizedValue = normalizedValue;
  }

  setToNormalizedPairObj(response: Record<string, any>) {
    return response instanceof NormalizedPair
      ? this.setFromObject(response)
      : this.setFromQueryString(response);
  }

  private setFromObject(normalisedPair: NormalizedPair) {
    return normalisedPair;
  }

  private setFromQueryString(response: Record<string, any>) {
    if (response.symbolAmount) {
      this.symbolAmount = new GlossNumber().setToGlossNumberObj(response.symbolAmount);
    } else if (response._symbolAmount) {
      this.symbolAmount = new GlossNumber().setToGlossNumberObj(response._symbolAmount);
    }

    if (response.normalizedValue) {
      this.normalizedValue = new GlossNumber().setToGlossNumberObj(response.normalizedValue);
    } else if (response._normalizedValue) {
      this.normalizedValue = new GlossNumber().setToGlossNumberObj(response._normalizedValue);
    }
    return this;
  }

  add(additionalPair: NormalizedPair) {
    this._normalizedValue.amount = this.addPairNormalizedAmount(additionalPair);
    this._symbolAmount.amount = this.addPairSymbolAmount(additionalPair);
  }

  addPairSymbolAmount(additionalPair: NormalizedPair): number {
    if (this._symbolAmount.symbol === additionalPair.symbolAmount.symbol) {
      const amount = this._symbolAmount.amount + additionalPair.symbolAmount.amount;
      return amount;
    } else {
      throw new Error(
        "Cannot add the symbol amount of two glossNumberPair records because the symbolAmount" +
          " symbols are different"
      );
    }
  }

  addPairNormalizedAmount(additionalPair: NormalizedPair): number {
    if (this._normalizedValue.symbol === additionalPair.normalizedValue.symbol) {
      const amount = this._normalizedValue.amount + additionalPair.normalizedValue.amount;
      return amount;
    } else {
      // debugger
      throw new Error(
        "Cannot add the normalized amount of two glossNumberPair records because the normalizedValue" +
          " symbols are different"
      );
    }
  }

  copy(): NormalizedPair {
    const copyNormalizedPair = new NormalizedPair();
    copyNormalizedPair.symbolAmount = this.symbolAmount.copy();
    copyNormalizedPair.normalizedValue = this.normalizedValue.copy();
    return copyNormalizedPair;
  }
}
