import { Injectable } from "@angular/core";

import { Category } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/category.data";
import { Classification } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/classification.data";
import { Institution } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/institution.data";
import {
  DataServiceAbstractionTypeArray,
  GlossEncryptedDataType,
} from "../../../../../apps/ironfly-web/src/app/models/enum/glossEncryptedDataType";
import { I18nService } from "../../abstractions/i18n.service";
import { LogService } from "../../abstractions/log.service";
import { MessagingService } from "../../abstractions/messaging.service";
import { PlatformUtilsService } from "../../abstractions/platformUtils.service";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(
    private messagingService: MessagingService,
    private logService: LogService,
    private platformUtilsService: PlatformUtilsService,
    private i18nService: I18nService
  ) {}

  showMessageAsIs(
    type: "error" | "success" | "warning" | "info",
    titleKey: string,
    textKey: string
  ) {
    this.platformUtilsService.showToast(type, titleKey, textKey);
  }
  showMessage(type: "error" | "success" | "warning" | "info", titleKey: string, textKey: string) {
    this.platformUtilsService.showToast(
      type,
      this.i18nService.t(titleKey),
      this.i18nService.t(textKey)
    );
  }
  showErrorMessage(titleKey: string, textKey: string) {
    this.showMessage("error", titleKey, textKey);
  }
  showInfoMessage(titleKey: string, textKey: string) {
    this.showMessage("info", titleKey, textKey);
  }
  showSuccessMessage(titleKey: string, textKey: string) {
    this.showMessage("success", titleKey, textKey);
  }

  showWarningMessage(titleKey: string, textKey: string) {
    this.showMessage("warning", titleKey, textKey);
  }

  async showRemoveDialogue() {
    return await this.showDialogue("areYouSureYouWannaRemove", "warning");
  }
  async showRemoveAccountDialogue() {
    return await this.showDialogue("deleteAccountConfirmMessageNoTransactions", "warning");
  }
  async showRemoveSomeTransactionsAccountDialogue(deletable: number, total: number) {
    const message = this.i18nService.t(
      "deleteAccountConfirmMessageSomeTransactions",
      deletable.toString(),
      total.toString()
    );
    return await this.platformUtilsService.showDialog(
      null,
      message,
      this.i18nService.t("yes"),
      this.i18nService.t("no"),
      "warning"
    );
  }
  async showRemoveAllTransactionsAccountDialogue(total: number) {
    const message = this.i18nService.t(
      "deleteAccountConfirmMessageAllTransactions",
      total.toString()
    );
    return await this.platformUtilsService.showDialog(
      null,
      message,
      this.i18nService.t("yes"),
      this.i18nService.t("no"),
      "warning"
    );
  }
  async showBasiqConnectToAnotherInstitution() {
    return await this.showDialogue("wouldYouLikeToConnectToAnotherInstitution", "warning");
  }
  async showDialogue(title: string, type: string, body: string = null, isBodyHtml = false) {
    return await this.platformUtilsService.showDialog(
      body,
      this.i18nService.t(title),
      this.i18nService.t("yes"),
      this.i18nService.t("no"),
      type,
      isBodyHtml
    );
  }

  getLocaleValue(key: string) {
    return this.i18nService.t(key);
  }
  isEmpty(value: string) {
    return value?.replace(/\s/g, "") === "" || value === undefined;
  }

  throwEmptyErrorMessage(emptyErrorMessage?: string) {
    throw emptyErrorMessage ? emptyErrorMessage : `emptyField`;
  }

  throwInstitutionExistsError() {
    this.showErrorMessage("errorOccurred", "institutionExists");
  }

  throwBookExistsError() {
    this.showErrorMessage("errorOccurred", "bookExists");
  }

  throwConnectorExistsError() {
    this.showErrorMessage("errorOccurred", "connectorExists");
  }

  checkEmpty(value: string) {
    if (this.isEmpty(value)) {
      this.throwEmptyErrorMessage();
    }
  }
  checkEmptyArr(arr: any[]) {
    if (arr.length === 0) {
      this.throwEmptyErrorMessage();
    }
  }

  alreadyExists(
    value: GlossEncryptedDataType,
    stack: DataServiceAbstractionTypeArray,
    what: string // to set the key categoryExists or classificationExists or InstitutionExists
  ) {
    if (
      value instanceof Category ||
      value instanceof Classification ||
      value instanceof Institution
    ) {
      if (stack && Array.isArray(stack)) {
        stack.forEach((element) => {
          if (
            element instanceof Category ||
            element instanceof Classification ||
            element instanceof Institution
          ) {
            if (element.name === value.name) {
              throw this.i18nService.t(what.toLowerCase()).toLowerCase() + "Exists";
            }
          }
        });
      }
    }
  }
}
