import { inject } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
  BaseProgressBar,
  ProgressBarInterface,
} from "@bitwarden/web-vault/app/components/progress-bar/base-progress-bar";

import { BlobbyService } from "../../services/blobby/blobby.service";

export class CsvProgressBar extends BaseProgressBar implements ProgressBarInterface {
  protected blobbyService: BlobbyService;
  protected unsubscribe$ = new Subject<void>();

  constructor(loadingPercent: number, importingLabel: string, totalItems: number) {
    super(loadingPercent, importingLabel, totalItems);
    this.blobbyService = inject(BlobbyService);
    this.loadingPercent = loadingPercent;
    this.importingLabel = importingLabel;
    this.totalItems = totalItems;
  }

  show() {
    this.blobbyService.inProcessItem$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (inProcessItem) => {
        this.processItems(inProcessItem.type);
      },
    });
  }

  processItems(type: string) {
    this.setLabel(type);
    this.loadingPercent = 0;
    this.blobbyService.startReferenceImport();
    this.blobbyService.loadingItemReference$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value) => {
        this.totalItems = this.blobbyService.totalItemsToProcess;
        this.loadingPercent = this.getLoadingPercentage(value);
        if (this.totalItems === 0) {
          this.blobbyService.completeReferenceProgress();
        }
      },
    });
  }
}
