import { AfterViewInit, Component, DoCheck, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { DashboardParameters } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { RoleAccessData } from "@bitwarden/web-vault/app/models/data/role-access.data";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ItemCountService } from "@bitwarden/web-vault/app/services/DataService/state/item-count.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

import { StepsService } from "../../components/account-wizard-stepper/wizard-stepper-service";
import { Transaction } from "../../models/data/blobby/transaction.data";
import { DashboardService } from "../../services/dashboard/dashboard-service";

@Component({
  selector: "app-dash-primary",
  templateUrl: "./dash-primary.component.html",
})
export class DashPrimaryComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private roleAccess: RoleAccessData;

  showGraph = false;
  transactions: Array<Transaction>;
  graphData: Array<GraphDataSet>;
  scenarioData: ScenarioData;
  defaultDates: Array<Date> = [];
  dashboardParameters: DashboardParameters;
  defaultGranularity: GranularityProperty;
  granularityOptions: Array<GranularityProperty>;
  showSpinner = false;
  hasNoAccounts = false;
  hasNoTransaction = false;
  isDev = false;
  hasEarlyAccess = true;
  isWizard = false;
  private protectIsWizard = false;

  constructor(
    private dashboardService: DashboardService,
    private logger: LogService,
    private globalService: GlobalService,
    private roleAccessService: RoleAccessService,
    private itemCountService: ItemCountService,
    private blobbyService: BlobbyService,
    private stepsService: StepsService
  ) {}

  handleSpinner(spinnerValue: boolean) {
    this.showSpinner = spinnerValue;
  }

  async ngOnInit(): Promise<void> {
    try {
      // todo removing this await breaks the loading of the dashboard.
      await this.dashboardService.resetDashboardData();
      this.handleSpinner(true);
      this.initialiseComponent();
      this.isDev = HelperCommon.isDevEnv();
      this.stepsService
        .getSteps()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async (steps) => {
            return steps;
          },
          complete: async () => {
            await this.dashboardService.resetDashboardData();
            this.showGraph = true;
            this.isWizard = false;

            await Promise.all([
              this.subscribeToDashObservables(),
              this.dashboardService.loadDashboardData(),
            ]);
          },
        });
    } catch (error) {
      this.handleSpinner(false);
    }
  }

  ngDoCheck() {
    this.initialiseComponent();

    if (!this.roleAccess) {
      this.roleAccess = this.roleAccessService.getRoleAccess();

      if (this.dashboardParameters) {
        this.hasEarlyAccess = this.showComingSoon();
      }
    }
  }

  initialiseComponent() {
    if (this.blobbyService.getInitialised()) {
      const itemCountInstance = this.blobbyService.getItemCountService().getItemCountInstance();
      this.hasNoAccounts = itemCountInstance.getTotalTransaction() > 0;
      this.hasNoTransaction = itemCountInstance.getTotalAccount() > 0;

      if ((!this.hasNoAccounts || !this.hasNoTransaction) && !this.protectIsWizard) {
        this.isWizard = true;
        this.protectIsWizard = true;
      }

      this.showGraph = this.hasNoAccounts && this.hasNoTransaction && !this.isWizard;
      this.handleSpinner(false);
    }
  }

  async ngAfterViewInit() {
    try {
      await this.dashboardService.triggerTransactionObservables();
      await this.subscribeToDashObservables();
      this.dashboardService.isVaultPurge = false;
      await this.dashboardService.loadDashboardData();
      this.handleSpinner(false);
    } catch (e) {
      this.handleSpinner(false);
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
    }
  }

  ngOnDestroy() {
    this.dashboardService.resetDashboardData();
    this.destroy$.next();
    this.destroy$.complete();
  }

  showComingSoon() {
    const scope = this.roleAccess.getScope();
    return scope.includes(RoleScope.BETA_ACCESS);
  }

  /**
   * subscribeToDashServices - Subscribe to the observables in the dashboard service
   */
  async subscribeToDashObservables() {
    this.dashboardService.graphData$.pipe(takeUntil(this.destroy$)).subscribe((graphData) => {
      this.graphData = this.dashboardService.isVaultPurge ? [] : graphData;
    });

    this.dashboardService.scenarioData$.pipe(takeUntil(this.destroy$)).subscribe((scenarioData) => {
      this.scenarioData = this.dashboardService.isVaultPurge
        ? {
            scenario: [],
            balance: [],
          }
        : scenarioData;
    });

    this.dashboardService.defaultStartDate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((startDate) => {
        if (startDate) {
          // note that JS only sees reassignment of array as an update, so we need to reassign the variable
          const newDefaultDates = [];
          newDefaultDates.push(new Date(startDate));
          if (this.defaultDates[1]) {
            newDefaultDates.push(this.defaultDates[1]);
          }
          this.defaultDates = newDefaultDates;
        }
      });

    this.dashboardService.defaultEndDate$.pipe(takeUntil(this.destroy$)).subscribe((endDate) => {
      if (endDate) {
        // note that JS only sees reassignment of array as an update, so we need to reassign the variable
        const newDefaultDates = [];
        if (this.defaultDates[0]) {
          newDefaultDates.push(this.defaultDates[0]);
        } else {
          newDefaultDates.push(null);
        }
        newDefaultDates.push(new Date(endDate));
        this.defaultDates = newDefaultDates;
      }
    });

    this.dashboardService.dashboardConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dashboardParameters) => {
        if (dashboardParameters) {
          this.dashboardParameters = dashboardParameters;
          this.hasEarlyAccess = ["summary", "combined"].includes(dashboardParameters.name)
            ? this.showComingSoon()
            : true;
        }
      });

    this.dashboardService.defaultGranularity$
      .pipe(takeUntil(this.destroy$))
      .subscribe((defaultGranularity) => {
        // Handle the new defaultGranularity value here
        this.defaultGranularity = defaultGranularity;
      });

    this.dashboardService.granularityOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((granularityOptions) => {
        // Handle the new granularityOptions value here
        this.granularityOptions = granularityOptions;
      });
  }

  callFilter(data: any) {
    const { selectedAccounts, selectedDirections, selectedCategories, selectedClassifications } =
      data;
    this.dashboardService
      .filterDashboardTransactions(
        selectedAccounts,
        selectedDirections,
        selectedCategories,
        selectedClassifications
      )
      .then(() => {
        this.handleSpinner(false);
      });
  }

  callScenario(data: any) {
    this.dashboardService.graphScenario().then(() => {
      this.handleSpinner(false);
    });
  }
}
