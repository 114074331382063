<app-spinner [showSpinner]="loading"></app-spinner>
<div class="modal-container" (keydown.enter)="$event.preventDefault(); $event.stopPropagation()">
  <div class="m-header tw-mb-[30px] tw-px-10 xs:tw-px-5">
    <button
      *ngIf="!editMode"
      class="mat-icon-button prev-icon"
      (click)="previousButtonOptions.onClick()"
    >
      <mat-icon>{{ previousButtonOptions.iconName }}</mat-icon>
    </button>
    <div class="tw-flex tw-text-2xl xs:tw-text-lg">
      <mat-icon class="header-icon">account_balance_wallet</mat-icon>
      {{ title }}
    </div>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <form name="accountForm">
      <div class="tw-flex tw-flex-col">
        <app-gloss-input [options]="accountInputOptions"></app-gloss-input>
        <br />
        <app-gloss-input [options]="currencyInputOptions"></app-gloss-input>
        <br />
        <app-gloss-input [options]="institutionInputOptions"></app-gloss-input>
        <br />

        <app-institutional-accounts
          [institution]="selectedInstitution"
          (selectedInstitutionAccount)="onSelectAccountType($event)"
          [preselectedInstitutionalAccount]="selectedAccountType"
        ></app-institutional-accounts>

        <div class="tw-mt-10 tw-flex tw-justify-center tw-gap-8 md:tw-flex-col-reverse">
          <app-gloss-button
            *ngIf="!editMode"
            [options]="previousButtonOptions"
            class="tw-w-full tw-text-primary sm:tw-hidden"
          ></app-gloss-button>
          <app-gloss-button [options]="checkButtonOptions" class="tw-w-full"></app-gloss-button>
        </div>
      </div>
    </form>
  </div>
</div>

<!--


<h1 mat-dialog-title class="title">
  <button
    *ngIf="!this.editMode"
    mat-icon-button
    color="primary"
    mat-dialog-close
    (click)="goBack()"
  >
    <mat-icon>arrow_left</mat-icon>
  </button>
  {{ title | i18n }}
  <button mat-icon-button color="primary" mat-dialog-close aria-label="Close dialog">
    <mat-icon>close</mat-icon>
  </button>
</h1>
<mat-dialog-content>
  <app-spinner [showSpinner]="loading"></app-spinner>
  <form class="modal-content" [formGroup]="form" (ngSubmit)="submit()" name="accountForm">
    <div class="modal-body">


      <div *ngIf="HelperCommon.isDevEnv()">
        <app-split-options
          [formGroup]="form"
          [isPanelOpen]="isCategoryOpen"
          (toggleOptionsPanel)="openCategoriesPanel()"
          [mainFormControlName]="'categories'"
          [optionControlNamePrefix]="'category'"
          [options]="categories"
          [title]="'category'"
          [selectedOptions]="selectedCategories"
          addNewButtonText="addNewCategory"
        >
        </app-split-options>

        <app-split-options
          [formGroup]="form"
          [isPanelOpen]="isClassificationOpen"
          (toggleOptionsPanel)="openClassificationsPanel()"
          [mainFormControlName]="'classifications'"
          [optionControlNamePrefix]="'classification'"
          [options]="classifications"
          [title]="'classification'"
          [selectedOptions]="selectedClassifications"
          addNewButtonText="addNewClassification"
        >
        </app-split-options>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="right-buttons-container">
    <button mat-raised-button mat-dialog-close>{{ "cancel" | i18n }}</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!form.valid" cdkAu>
      {{ "save" | i18n }}
    </button>
  </div>
</mat-dialog-actions>
-->
