import { Injectable } from "@angular/core";
import { defer, Observable } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { PreferenceResponse } from "@bitwarden/web-vault/app/models/data/response/preference.response";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import {
  StringToNumberHelperOfMonth,
  StringToNumberHelperOfWeek,
  StringToNumberPreference,
  UserPreference,
} from "@bitwarden/web-vault/app/models/types/PrefereneceTypes";
import { BasiqUserType } from "@bitwarden/web-vault/app/models/types/basiq.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

import {
  Preference,
  PreferenceTypeKey,
  SyncStatusCollectionType,
} from "../../../models/data/blobby/preference.data";

@Injectable({
  providedIn: "root",
})
export class PreferenceService {
  constructor(
    private globalService: GlobalService,
    private dataRepositoryService: DataRepositoryService
  ) {}

  async create(preference: Preference): Promise<Preference> {
    return await this.dataRepositoryService.createPreference(preference);
  }

  async update(preference: Preference): Promise<Preference> {
    return this.dataRepositoryService.updatePreference(preference);
  }

  async getAll(): Promise<Preference | false> {
    const preference = await this.dataRepositoryService.getAllPreferences();
    if (preference instanceof Preference) {
      return preference;
    } else {
      return false;
    }
  }

  async getUserPreferenceObject(): Promise<UserPreference | false> {
    const preference = await this.getAll();
    if (preference) {
      return {
        baseCurrency: preference.baseCurrency,
        timeZone: preference.timeZone,
        dateFormat: preference.dateFormat,
        mode: preference.mode,
        weekDayStart: preference.weekDayStart
          ? this.getWeekDaysArray(preference.weekDayStart)[0].value
          : 1,
        weekDayStartName: preference.weekDayStart
          ? this.getWeekDaysArray(preference.weekDayStart)[0].day
          : "Monday",
        YearMonthStart: preference.YearMonthStart
          ? this.getYearMonthsArray(preference.YearMonthStart)[0].value
          : 1,
        YearMonthStartName: preference.YearMonthStart
          ? this.getYearMonthsArray(preference.YearMonthStart)[0].month
          : "January",
        monthDayStart: preference.monthDayStart ? preference.monthDayStart : 1,
      };
    } else {
      return false;
    }
  }

  async get(
    key: PreferenceTypeKey
  ): Promise<
    string | StringToNumberPreference | number | BasiqUserType | SyncStatusCollectionType
  > {
    try {
      const preference = await this.getAll();

      if (preference instanceof Preference) {
        return preference[key];
      }
    } catch (e) {
      this.globalService.showErrorMessage(`Could not get preference ${key}`, e);
    }
  }

  async getWeekDayStart(): Promise<StringToNumberPreference> {
    return <StringToNumberPreference>await this.get(PreferenceType.weekDayStart);
  }

  async getYearMonthStart(): Promise<StringToNumberPreference> {
    return <StringToNumberPreference>await this.get(PreferenceType.YearMonthStart);
  }

  async getWeekDayStartAsNumber() {
    const weekDayStart = await this.getWeekDayStart();
    return Object.values(weekDayStart)[0];
  }

  async getYearMonthStartAsNumber() {
    const yearMonthStart = await this.getYearMonthStart();
    return Object.values(yearMonthStart)[0];
  }

  async updateKey(
    key: PreferenceTypeKey,
    value: string | StringToNumberPreference | number | BasiqUserType | SyncStatusCollectionType
  ): Promise<Preference | false> {
    const preference = await this.getAll();
    if (preference instanceof Preference) {
      const updatedPreference = new Preference(
        new PreferenceResponse({ ...preference, ...{ [key]: value } })
      );
      return await this.update(updatedPreference);
    }
    return false;
  }

  getWeekDaysArray(weekDaysTypes: StringToNumberPreference): StringToNumberHelperOfWeek[] {
    try {
      return Object.entries(weekDaysTypes).map(([day, value]) => ({ day, value }));
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  getYearMonthsArray(weekDaysTypes: StringToNumberPreference): StringToNumberHelperOfMonth[] {
    try {
      return Object.entries(weekDaysTypes).map(([month, value]) => ({ month, value }));
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  loadPreference$(): Observable<Preference> {
    return defer(() => {
      return this.getAll().then((item) => {
        if (item instanceof Preference) {
          return item;
        }
      });
    });
  }

  updatePreference$(preference: Preference): Observable<Preference> {
    return defer(() => {
      return this.update(preference).then((item) => {
        if (item instanceof Preference) {
          return item;
        }
      });
    });
  }

  async getBasiqUser() {
    const preference = await this.getAll();
    if (preference) {
      return preference.basiqUser;
    }

    return;
  }
}
