import { Component, DoCheck, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/components/customer-support-box/customer-support-box.component";
import { RoleAccessData } from "@bitwarden/web-vault/app/models/data/role-access.data";
import {
  DATA_AGGREGATOR_OPTIONS,
  RoleScope,
} from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

import "./creation-options.component.scss";
@Component({
  selector: "app-creation-options",
  templateUrl: "./creation-options.component.html",
  styles: ["creation-options.component.scss"],
})
export class CreationOptionsComponent implements DoCheck {
  private destroy$ = new Subject<void>();
  private roleAccess: RoleAccessData;
  protected readonly RoleScope = RoleScope;
  protected readonly DATA_AGGREGATOR_OPTIONS = DATA_AGGREGATOR_OPTIONS;

  earlyRequestDialogRef: MatDialogRef<CustomerSupportBoxComponent>;
  hasAccess: boolean;
  tooltipInfo =
    "<h2>Synchronized Account</h2>Link your Account and have the GLOSS Vault automatically keep your transactions and balances up to date</br></br><h2>Unlinked Account</h2>Use Unlinked option where you have an account provider that has no linking feature, or where you are not yet ready to become a Premium subscriber";
  showHelpTooltip = false;
  xPosition: number;
  yPosition: number;
  pointer: string;
  trianglePointerOffset = 50;
  trianglePointerHeight = 16;
  isInfoOpen = false;
  isClicked = false;
  hasScope: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      createManually: CallableFunction;
      createByLinking: CallableFunction;
      closeDialogue: CallableFunction;
      isPremiumAccount: boolean;
    },
    private helperCommon: HelperCommon,
    private globalService: GlobalService,
    private roleAccessService: RoleAccessService,
    public dialog: MatDialog
  ) {
    this.hasAccess = data.isPremiumAccount;
  }

  ngDoCheck() {
    if (!this.roleAccess) {
      this.roleAccess = this.roleAccessService.getRoleAccess();
      this.hasScope = this.hasBasiqAccess();
    }
  }

  hasBasiqAccess() {
    if (this.roleAccess) {
      const scope = this.roleAccess.getScope();
      const claim = this.roleAccess.getClaim();
      const dataAggregator = claim.data_aggregator_connector_name;

      return (
        (scope.includes(RoleScope.BETA_ACCESS) || scope.includes(RoleScope.DATA_AGGREGATOR)) &&
        dataAggregator.includes(DATA_AGGREGATOR_OPTIONS.BASIQ)
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createManually() {
    this.data.createManually();
  }
  async createByLinking() {
    if (!this.hasScope) {
      // todo translate body
      const confirm = await this.globalService.showDialogue(
        "wouldYouLikeToGetEarlyAccess",
        "info",
        "This feature is still in development, would you like to join our early access program to access it?"
      );
      if (confirm) {
        this.openHelpMenu();
      }

      return;
    }

    if (this.data.isPremiumAccount) {
      this.data.createByLinking();
    }
  }

  isPremiumAccount() {
    return this.hasAccess;
  }

  openHelpMenu() {
    this.requestEarlyAccess();
  }

  requestEarlyAccess() {
    this.earlyRequestDialogRef = this.dialog.open(CustomerSupportBoxComponent, {
      panelClass: "no-background-dialog",
      data: {
        supportBoxHeader: "Request An Early Access",
        dialogOpen: true,
        closeDialogue: this.closeEarlyRequestDialog.bind(this),
      },
      disableClose: true,
    });

    this.earlyRequestDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }

  toggleClick(e: MouseEvent) {
    this.isClicked = !this.isClicked;
  }

  onTooltipClose() {
    this.showHelpTooltip = false;
    this.isInfoOpen = false;
    this.isClicked = !this.isClicked;
  }

  async openDashboardHelp(e: MouseEvent) {
    e.stopPropagation();
    this.showHelpTooltip = true;
    this.isInfoOpen = true;
    const target = e.currentTarget as HTMLElement;
    const targetRect = target.getBoundingClientRect();

    // dialog open slightly left
    // this.pointer = "top-right"; if no error delete by end of september
    this.pointer = "creation-options";
    this.xPosition = targetRect.right + this.trianglePointerOffset;
    this.yPosition = targetRect.bottom + this.trianglePointerHeight;
  }
}
