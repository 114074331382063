import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as d3 from "d3";
import { interpolate } from "d3";

@Component({
  selector: "app-pie-chart",
  templateUrl: "./pie-chart.component.html",
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() dataSet: number[] = [];
  private svg: any;
  private chart: any;
  private arc: any;
  private tooltip: any;

  private readonly margin = 5;
  private readonly width = 180;
  private readonly height = 180;

  // The radius of the pie chart is half the smallest side
  private readonly radius = Math.min(this.width, this.height) / 2 - this.margin;

  private colors: any;

  private readonly offset = 4; // Adjust the translation offset as needed
  ngOnInit(): void {
    this.createSVG();
    this.createColors();
    this.drawChart(); // Create the graph initially
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSet && this.svg) {
      this.drawChart();
    }
  }

  private createSVG(): void {
    this.svg = d3
      .select("#pieChart")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`);
  }

  private createColors(): void {
    this.colors = d3
      .scaleOrdinal()
      .domain(["Income", "Expenses"])
      .range(["rgb(34,139,34)", "rgb(255,165,0)"]);
  }

  private drawChart() {
    // Compute the position of each group on the pie //layout
    const pieLayout = d3
      .pie<number>()
      .sort(null)
      .value((d) => d);

    this.arc = d3
      .arc()
      .startAngle(function (d) {
        return d.startAngle;
      })
      .endAngle(function (d) {
        return d.endAngle;
      })
      .innerRadius(0)
      .outerRadius(this.radius);
    const arcOver = d3
      .arc()
      .innerRadius(0)
      .outerRadius(this.radius + this.offset);

    const data = pieLayout(this.dataSet);

    // creating slices
    this.chart = this.svg.selectAll("path").data(data);

    this.chart.exit().remove(); // Remove old data

    const slice = this.chart
      .enter()
      .append("path")
      .merge(this.chart)
      .attr("d", this.arc)
      .attr("fill", (d: number, i: number) => this.colors(i === 0 ? "Income" : "Expenses"))
      .attr("stroke", "white")
      .style("stroke-width", "2px");

    // Append the tooltip elements to the SVG
    this.tooltip = d3
      .select("#pieChart")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0.8)
      .style("visibility", "hidden")
      .style("position", "absolute")
      .style("text-align", "center")
      .style("width", "fit-content")
      .style("background-color", "black")
      .style("border-radius", "8px")
      .style("padding", "5px")
      .style("pointer-events", "none")
      .style("color", "white");

    let isAnimating = false;
    slice
      .transition()
      .duration(1000) // Duration of the animation
      .ease(d3.easeCircleOut)
      .attrTween("d", (d: any) => {
        isAnimating = true;
        return this.arcTween(d);
      })
      .on("end", () => {
        // Clear the flag when animation ends
        isAnimating = false;
      });

    // Add mouseover animation
    slice
      .on("mouseover", (event: MouseEvent, d: any) => {
        // Check if the animation is not in progress
        if (isAnimating) {
          return;
        }

        d3.select(event.target as d3.BaseType)
          .transition()
          .duration(200)
          .attr("d", arcOver);

        // Show tooltip
        this.showTooltip(d);
      })
      .on("mousemove", (event: MouseEvent, d: any) => {
        // Check if the animation is not in progress
        if (isAnimating) {
          return;
        }

        // Get the mouse coordinates relative to the SVG element
        const [x, y] = d3.pointer(event);

        // Update tooltip position
        // [x, y] results in the tooltip position away from pointer, so the +this.radius
        this.updateTooltipPosition(x + this.radius, y + this.radius);

        // Update tooltip content
        this.updateTooltipContent(["Income", "Expenses"][d.index], d.data);
      })
      .on("mouseout", (event: MouseEvent) => {
        // Check if the animation is not in progress
        if (isAnimating) {
          return;
        }

        d3.select(event.target as d3.BaseType)
          .transition()
          .duration(200)
          .attr("d", this.arc); // Revert to original path

        // Hide tooltip
        this.hideTooltip();
      });

    this.addLabels(data);

    this.addLegend(["Income", "Expenses"]);
  }

  private arcTween(d: any) {
    // Interpolate between the current 'd' and the final 'd'
    const i = interpolate(d.startAngle + 0.1, d.endAngle);
    // Return a function that computes the intermediate 'd'
    return (t: number) => {
      d.endAngle = i(t);
      return this.arc(d);
    };
  }

  private showTooltip(data: any) {
    this.tooltip.style("visibility", "visible");
  }

  private hideTooltip() {
    this.tooltip.style("visibility", "hidden");
  }

  private updateTooltipPosition(x: number, y: number) {
    this.tooltip.style("left", x + "px").style("top", y + "px");
  }

  private updateTooltipContent(label: string, value: number) {
    this.tooltip.html(`${label}<br>${value}`);
  }

  private addLabels(data: any) {
    this.svg
      .selectAll("text")
      .data(data)
      .enter()
      .append("text")
      .attr("transform", (d: any) => `translate(${this.arc.centroid(d)})`)
      .style("text-anchor", "middle")
      .text((d: any, i: number) => ["Income", "Expenses"][i])
      .style("font-size", "12px");
  }

  private addLegend(legendData: Array<string>) {
    const legend = d3
      .select("#legend")
      .attr("class", "legend")
      .style("display", "flex")
      .style("justify-content", "center")
      .style("gap", "20px");

    const legendItems = legend
      .selectAll(".legend-item")
      .data(legendData)
      .enter()
      .append("div")
      .attr("class", "legend-item")
      .style("display", "flex")
      .style("gap", "5px");

    // Create colored rectangles as indicators
    legendItems
      .append("div")
      .style("background-color", (d: any, i: number) =>
        this.colors(i === 0 ? "Income" : "Expenses")
      )
      .attr("class", "legend-rect")
      .style("width", "12px") // Adjust the size as needed
      .style("height", "12px") // Adjust the size as needed
      .style("margin", "auto"); // Adjust spacing between rectangle and text

    // Create text labels
    legendItems.append("text").text((d: any) => d); // Use the legend data for text content
  }
  ngOnDestroy() {
    if (this.svg) {
      this.svg.selectAll("*").remove();
    }
  }
}
