import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { Scenario } from "@bitwarden/web-vault/app/models/data/blobby/scenario.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { GlossEncryptedDataType } from "@bitwarden/web-vault/app/models/enum/glossEncryptedDataType";

import { Book } from "../../models/data/blobby/book.data";
import { Category } from "../../models/data/blobby/category.data";
import { Classification } from "../../models/data/blobby/classification.data";
import { Institution } from "../../models/data/blobby/institution.data";
import { Preference } from "../../models/data/blobby/preference.data";
import { SourceTransaction } from "../../models/data/blobby/source-transaction.data";
import { Transaction } from "../../models/data/blobby/transaction.data";
import { SymbolInfoData } from "../../models/data/symbol-info.data";
import { BlobbyDataTypeEnum } from "../../models/enum/blobbyDataTypeEnum";

export class BlobbyMemoryStorage {
  private accountStore = new Map<string, Book>();
  private classificationStore = new Map<string, Classification>();
  private institutionStore = new Map<string, Institution>();
  private categoryStore = new Map<string, Category>();
  private estimateStore = new Map<string, Estimate>();
  private symbolInfoStore = new Map<string, SymbolInfoData>();
  private transactionStore = new Map<string, Transaction>();
  private referenceStore = new Map<string, ReferenceData>();
  private preferenceStore = new Map<string, Preference>();
  private sourceTransactionStore = new Map<string, SourceTransaction>();
  private sourceBookStore = new Map<string, SourceBook>();
  private scenarioStore = new Map<string, Scenario>();
  private scenarioGroupStore = new Map<string, ScenarioGroup>();
  private estimateGroupStore = new Map<string, Estimate>();
  private sourceCategoryStore = new Map<string, SourceCategory>();
  private vaultStore = new Map<string, any>();
  private connectorStore = new Map<string, Connector>();

  getContext() {
    return {
      categories: this.categoryStore.size,
      institutions: this.institutionStore.size,
      accounts: this.accountStore.size,
      transactions: this.transactionStore.size,
      references: this.referenceStore.size,
      classifications: this.classificationStore.size,
      connectors: this.connectorStore.size,
    };
  }

  indexAccount(accounts: Array<Book>) {
    for (const account of accounts) {
      if (account.id) {
        this.accountStore.set(account.id, account);
      }
    }
  }

  deleteAccount(account: Book) {
    this.accountStore.delete(account.id);
  }

  deleteSourceBook(sourceBook: SourceBook) {
    this.sourceBookStore.delete(sourceBook.id);
  }

  indexCategory(categories: Array<Category>) {
    for (const cat of categories) {
      if (cat.id) {
        this.categoryStore.set(cat.id, cat);
      }
    }
  }

  deleteCategory(category: Category) {
    this.categoryStore.delete(category.id);
  }

  indexClassification(classifications: Array<Classification>) {
    for (const cls of classifications) {
      if (cls.id) {
        this.classificationStore.set(cls.id, cls);
      }
    }
  }

  deleteClassification(classification: Classification) {
    this.classificationStore.delete(classification.id);
  }

  indexInstitution(institutions: Array<Institution>) {
    for (const insts of institutions) {
      if (insts.id) {
        this.institutionStore.set(insts.id, insts);
      }
    }
  }

  deleteInstitution(institution: Institution) {
    this.institutionStore.delete(institution.id);
  }

  indexEstimate(estimates: Array<Estimate>) {
    for (const est of estimates) {
      if (est.id) {
        this.estimateStore.set(est.id, est);
      }
    }
  }

  deleteEstimate(estimate: Estimate) {
    this.estimateStore.delete(estimate.id);
  }

  indexSymbol(symbols: Array<SymbolInfoData>) {
    for (const sym of symbols) {
      if (sym.id) {
        this.symbolInfoStore.set(sym.id, sym);
      }
    }
  }

  deleteSymbol(symbol: SymbolInfoData) {
    this.symbolInfoStore.delete(symbol.id);
  }

  indexTransaction(transactions: Array<Transaction>) {
    for (const transaction of transactions) {
      if (transaction.id) {
        this.transactionStore.set(transaction.id, transaction);
      }
    }
  }

  deleteTransaction(transaction: Transaction) {
    this.transactionStore.delete(transaction.id);
  }

  indexReference(references: Array<ReferenceData>) {
    for (const reference of references) {
      if (reference.id) {
        this.referenceStore.set(reference.id, reference);
      }
    }
  }

  deleteReference(reference: ReferenceData) {
    this.referenceStore.delete(reference.id);
  }

  indexPreference(preferences: Array<Preference>) {
    for (const preference of preferences) {
      if (preference.id) {
        this.preferenceStore.set(preference.id, preference);
      }
    }
  }

  indexSourceTransaction(sourceTransactions: Array<SourceTransaction>) {
    for (const sourceTransaction of sourceTransactions) {
      if (sourceTransaction.id) {
        this.sourceTransactionStore.set(sourceTransaction.id, sourceTransaction);
      }
    }
  }
  indexSourceBook(sourceBooks: Array<SourceBook>) {
    for (const sourceBook of sourceBooks) {
      if (sourceBook.id) {
        this.sourceBookStore.set(sourceBook.id, sourceBook);
      }
    }
  }

  indexScenario(scenarios: Array<Scenario>) {
    for (const scenario of scenarios) {
      if (scenario.id) {
        this.scenarioStore.set(scenario.id, scenario);
      }
    }
  }
  indexScenarioGroup(scenarioGroups: Array<ScenarioGroup>) {
    for (const scenarioGroup of scenarioGroups) {
      if (scenarioGroup.id) {
        this.scenarioGroupStore.set(scenarioGroup.id, scenarioGroup);
      }
    }
  }

  indexSourceCategory(sourceCategories: Array<SourceCategory>) {
    for (const sourceCategory of sourceCategories) {
      if (sourceCategory.id) {
        this.sourceCategoryStore.set(sourceCategory.id, sourceCategory);
      }
    }
  }

  indexConnector(sourceConnectors: Array<Connector>) {
    for (const sourceConnector of sourceConnectors) {
      if (sourceConnector.id) {
        this.connectorStore.set(sourceConnector.id, sourceConnector);
      }
    }
  }

  deleteSourceTransaction(sourceTransaction: SourceTransaction) {
    this.sourceTransactionStore.delete(sourceTransaction.id);
  }

  indexEstimateGroup(estimateGroups: Array<Estimate>) {
    for (const estimateGroup of estimateGroups) {
      this.estimateGroupStore.set(estimateGroup.id, estimateGroup);
    }
  }
  deleteEstimateGroup(estimateGroup: Estimate) {
    this.estimateGroupStore.delete(estimateGroup.id);
  }

  getAll(type: BlobbyDataTypeEnum) {
    if (type == BlobbyDataTypeEnum.Transaction) {
      return Array.from(this.transactionStore.values());
    } else if (type == BlobbyDataTypeEnum.ReferenceData) {
      return Array.from(this.referenceStore.values());
    } else if (type == BlobbyDataTypeEnum.Book) {
      return Array.from(this.accountStore.values());
    } else if (type == BlobbyDataTypeEnum.Category) {
      return Array.from(this.categoryStore.values());
    } else if (type == BlobbyDataTypeEnum.Classification) {
      return Array.from(this.classificationStore.values());
    } else if (type == BlobbyDataTypeEnum.Institution) {
      return Array.from(this.institutionStore.values());
    } else if (type == BlobbyDataTypeEnum.Estimate) {
      return Array.from(this.estimateStore.values());
    } else if (type == BlobbyDataTypeEnum.Symbol) {
      return Array.from(this.symbolInfoStore.values());
    } else if (type === BlobbyDataTypeEnum.Preference) {
      return Array.from(this.preferenceStore.values());
    } else if (type === BlobbyDataTypeEnum.SourceTransaction) {
      return Array.from(this.sourceTransactionStore.values());
    } else if (type === BlobbyDataTypeEnum.SourceBook) {
      return Array.from(this.sourceBookStore.values());
    } else if (type === BlobbyDataTypeEnum.EstimateGroup) {
      return Array.from(this.estimateGroupStore.values());
    } else if (type === BlobbyDataTypeEnum.ScenarioGroup) {
      return Array.from(this.scenarioGroupStore.values());
    } else if (type === BlobbyDataTypeEnum.Scenario) {
      return Array.from(this.scenarioStore.values());
    } else if (type === BlobbyDataTypeEnum.SourceCategory) {
      return Array.from(this.sourceCategoryStore.values());
    } else if (type === BlobbyDataTypeEnum.Connector) {
      return Array.from(this.connectorStore.values());
    } else {
      throw new Error(`Unsupported type ${type}`);
    }
  }

  indexBramByType(type: BlobbyDataTypeEnum, data: GlossEncryptedDataType[]) {
    if (type == BlobbyDataTypeEnum.Transaction) {
      this.indexTransaction(<Transaction[]>data);
    }

    if (type == BlobbyDataTypeEnum.ReferenceData) {
      this.indexReference(<ReferenceData[]>data);
    }

    if (type == BlobbyDataTypeEnum.Book) {
      this.indexAccount(<Book[]>data);
    }

    if (type == BlobbyDataTypeEnum.Category) {
      this.indexCategory(<Category[]>data);
    }

    if (type == BlobbyDataTypeEnum.SourceCategory) {
      this.indexSourceCategory(<SourceCategory[]>data);
    }

    if (type == BlobbyDataTypeEnum.Classification) {
      this.indexClassification(<Classification[]>data);
    }

    if (type == BlobbyDataTypeEnum.Institution) {
      this.indexInstitution(<Institution[]>data);
    }

    if (type == BlobbyDataTypeEnum.Connector) {
      this.indexConnector(<Connector[]>data);
    }

    if (type == BlobbyDataTypeEnum.Estimate) {
      this.indexEstimate(<Estimate[]>data);
    }

    if (type == BlobbyDataTypeEnum.Symbol) {
      this.indexSymbol(<SymbolInfoData[]>data);
    }

    if (type === BlobbyDataTypeEnum.Preference) {
      this.indexPreference(<Preference[]>data);
    }

    if (type === BlobbyDataTypeEnum.SourceTransaction) {
      this.indexSourceTransaction(<SourceTransaction[]>data);
    }

    if (type === BlobbyDataTypeEnum.SourceBook) {
      this.indexSourceBook(<SourceBook[]>data);
    }

    if (type === BlobbyDataTypeEnum.EstimateGroup) {
      this.indexEstimateGroup(<Estimate[]>data);
    }
  }

  deleteItem(type: BlobbyDataTypeEnum, item: GlossEncryptedDataType) {
    if (type == BlobbyDataTypeEnum.Transaction) {
      this.deleteTransaction(<Transaction>item);
    } else if (type == BlobbyDataTypeEnum.ReferenceData) {
      this.deleteReference(<ReferenceData>item);
    } else if (type == BlobbyDataTypeEnum.SourceBook) {
      this.deleteSourceBook(<SourceBook>item);
    } else if (type == BlobbyDataTypeEnum.Book) {
      this.deleteAccount(<Book>item);
    } else if (type == BlobbyDataTypeEnum.Category) {
      this.deleteCategory(<Category>item);
    } else if (type == BlobbyDataTypeEnum.Classification) {
      this.deleteClassification(<Classification>item);
    } else if (type == BlobbyDataTypeEnum.Institution) {
      this.deleteInstitution(<Institution>item);
    } else if (type == BlobbyDataTypeEnum.Estimate) {
      this.deleteEstimate(<Estimate>item);
    } else if (type == BlobbyDataTypeEnum.Symbol) {
      this.deleteSymbol(<SymbolInfoData>item);
    } else if (type === BlobbyDataTypeEnum.SourceTransaction) {
      this.deleteSourceTransaction(<SourceTransaction>item);
    } else if (type === BlobbyDataTypeEnum.EstimateGroup) {
      this.deleteEstimateGroup(<Estimate>item);
    } else {
      // not implemented todo
    }
  }
}
