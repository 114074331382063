import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Chart, registerables } from "chart.js";

@Component({
  selector: "app-holdings-own-card",
  templateUrl: "./holdings-own-card.component.html",
})
export class HoldingsOwnCardComponent implements OnInit {
  @Input() title: string;
  @Input() holdingsOwn: Array<any>;
  @Input() holdingsOwnLabels: Array<string>;
  chart: Chart;
  initialised: boolean;

  constructor() {
    Chart.register(...registerables);
    this.initialised = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.holdingsOwn || changes.holdingsOwnLabels) && this.chart) {
      this.chart.data = {
        labels: this.holdingsOwnLabels,
        datasets: [
          {
            data: this.holdingsOwn,
            hoverOffset: 2,
            backgroundColor: [
              "rgb(46, 88, 148)",
              "rgb(81, 117, 167)",
              "rgb(115, 146, 186)",
              "rgb(150, 175, 205)",
              "rgb(184, 204, 224)",
              "rgb(219, 233, 243)",
            ],
          },
        ],
      };
      this.chart.update();
      this.chart.resize();
    }
  }

  ngOnInit(): void {
    this.chart = new Chart("OwnHoldings", {
      type: "pie",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        parsing: {
          key: "nested.value",
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
            align: "start",
          },
        },
      },
    });
    this.initialised = true;
    return null;
  }

  ngOnDestroy() {
    // remove the chart
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
