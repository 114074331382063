<mat-form-field>
  <mat-label>{{ "enterDateRange" | i18n }}</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" (click)="picker.open()">
    <input
      matStartDate
      formControlName="start"
      placeholder="Start date"
      (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
      #dateRangeStart
    />
    <input
      matEndDate
      formControlName="end"
      placeholder="End date"
      (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
      #dateRangeEnd
    />
  </mat-date-range-input>

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

  <mat-date-range-picker #picker>
    <mat-date-range-picker-actions class="flex-column">
      <app-preset-date-range class="preset"></app-preset-date-range>

      <div class="modal-footer right-buttons-container">
        <button mat-raised-button mat-dialog-close matDatepickerCancel>
          {{ "cancel" | i18n }}
        </button>
        <button mat-raised-button color="primary" matDatepickerApply>
          {{ "apply" | i18n }}
        </button>
      </div>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>
