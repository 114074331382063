// async-cell-renderer.component.ts
import { Component, OnDestroy } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

@Component({
  selector: "app-institution-renderer-cell-renderer",
  template: `{{ value }}`,
})
export class InstitutionCellRenderer implements ICellRendererAngularComp, OnDestroy {
  value: string;
  private unsubscribe$ = new Subject<void>();
  mockAccounts: Book[] = [];

  constructor(
    private bookService: BookService,
    private dashboardService: DashboardService,
    private institutionService: InstitutionService
  ) {
    /** Subscribe to mockAccounts to get fake account names for table */
    this.dashboardService.mockAccounts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mockAccounts) => {
        this.mockAccounts = mockAccounts;
      });
  }

  async agInit(params: any): Promise<void> {
    const accountId = params.value;
    let bookViews: AccountView[] = [];
    if (this.mockAccounts) {
      bookViews = await this.bookService.getBooksViewWithMockAccounts(this.mockAccounts);
    } else {
      bookViews = await this.bookService.getBooksView();
    }

    const bookView = bookViews.find((view) => view.originalBook.id === accountId);

    this.value = bookView
      ? bookView.institution
        ? bookView.institution.name
        : "Account is not linked to an institution"
      : "No account found linked ";
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
