import { ScenarioMessages } from "../../../models/types/general-types";

export const scenarioMessages: ScenarioMessages = {
  scenario1: "",
  scenario2: {
    oneAccount:
      "You've only added one account to your GLOSS Vault. If you have more\n" +
      "accounts, add them to your Vault and get more insight into your \n" +
      "current interest.",
    noPositiveBalanceAccounts:
      "You don't have any accounts with a positive balance in your GLOSS Vault. If you have more accounts, add them to your Vault and get more insight into your current interest.",
    onlyOnePositiveBalanceAccount:
      "You only have one account with a positive balance in your GLOSS Vault. If you have more accounts, add them to your Vault and get more insight into your current interest.",
    congratulations:
      "Congratulations! You are already doing really good with your savings by having the highest interest rate within your options. Interest rates change frequently so keep an eye on this scenario to ensure that you continue to receive the best rate.",
    notGettingInterest:
      "Looks like you are not getting interest. You may want to consider moving your money to an account with a higher interest rate.",
    notHaveNecessaryInfo:
      "The GLOSS Vault does not yet have the necessary information to perform calculations on overdraft or zero balance accounts.",
    noInterestRate:
      "The GLOSS Vault does not yet have the necessary information about your Institution and Account to determine your current interest.",
  },
  scenario3: {
    wellDone:
      "Well done! You are already maximising your savings interest by \n" +
      "having the highest interest account. Interest rates change \n" +
      "frequently so keep an eye on this scenario to ensure that you \n" +
      "continue to receive the best rate.",
    notHaveNecessaryInfo:
      "The GLOSS Vault does not yet have the necessary information to perform calculations on overdraft or zero balance accounts.",
  },
};
