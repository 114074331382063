import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component } from "@angular/core";
import { format } from "date-fns";
import { map } from "rxjs/operators";

import { Holding } from "@bitwarden/common/models/data/holding.data";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { SymbolData } from "@bitwarden/web-vault/app/models/data/symbol.data";
import { AllocationService } from "@bitwarden/web-vault/app/services/DataCalculationService/allocation/allocation.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";

import { BookService } from "../../services/DataService/book/book.service";
import { SymbolService } from "../../services/DataService/symbol/symbol.service";

@Component({
  selector: "app-dashboard-holding",
  templateUrl: "./dashboard-holding.component.html",
})
export class DashboardHoldingComponent {
  accounts: Array<Book>;
  holdingsOwe: Array<any>;
  holdingsOweLabels: Array<any>;
  holdingsOwn: Array<any>;
  holdingsOwnLabels: Array<any>;
  nettHoldings: Array<any>;
  nettHoldingsLabels: string[] = ["Owe", "Own"];
  nettBalance: number;
  holdingsData: Array<Holding> = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private blobbyService: BlobbyService,
    private symbolService: SymbolService,
    private bookService: BookService,
    private allocationService: AllocationService,
    private classificationService: ClassificationService
  ) {}

  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 3,
          largeChart: { cols: 3, rows: 1 },
          smallChart: { cols: 1, rows: 1 },
          table: { cols: 3, rows: 5 },
        };
      }

      return {
        columns: 3,
        largeChart: { cols: 3, rows: 1 },
        smallChart: { cols: 1, rows: 1 },
        table: { cols: 3, rows: 5 },
      };
    })
  );

  async ngOnInit(): Promise<void> {
    this.accounts = await this.bookService.getAll();
    await this.parseAccountData();
    return null;
  }

  async parseAccountData() {
    const newHoldingsArray: Array<Holding> = [];
    const allAccountBals: Holding[][] = [];

    for await (const account of this.accounts) {
      const accountId = account.id;
      const symbols = await this.symbolService.query(
        (symbol: SymbolData) => symbol.accountId === accountId
      );

      for (const symbol of symbols) {
        const accountBals: Array<Holding> = [];

        /** Get the current balances for the account grouped by classification and category-renderer */
        const formattedDate = Number(format(new Date(), "yyyyMMdd"));
        const accountBalances = await this.allocationService.getBalanceClose(
          accountId,
          symbol.name,
          false,
          false,
          formattedDate
        );

        accountBalances.forEach((classCats) => {
          let classExists = false;
          /** Go through all the balances already processed and search for this classification */
          accountBals.forEach((holding) => {
            if (holding.classification === classCats.classification) {
              holding.quantity = holding.quantity + classCats.value.symbolAmount.amount;
              classExists = true;
            }
          });
          // the classification is new so create a new holding for it
          if (!classExists && classCats.value.symbolAmount.amount != 0) {
            const holding = new Holding();
            holding.classification = classCats.classification;
            holding.quantity = classCats.value.symbolAmount.amount;
            holding.account = accountId;
            accountBals.push(holding);
            newHoldingsArray.push(holding);
          }
        });
        allAccountBals.push(accountBals);
      }
    }

    /** Sum all the positive and negative balance for each classification
    to create graph data for holdings owned and holdings owed */
    const positiveBals: any[] = [];
    const negativeBals: any[] = [];
    let nettOwnBal = 0;
    let nettOweBal = 0;

    allAccountBals.forEach((holdings) => {
      holdings.forEach((holding) => {
        let tempBalances = null;
        if (holding.quantity > 0) {
          nettOwnBal += holding.quantity;
          tempBalances = positiveBals;
        } else if (holding.quantity < 0) {
          nettOweBal += holding.quantity;
          tempBalances = negativeBals;
        }

        /** if number is not 0 then add it to the relevant array */
        if (tempBalances) {
          let matchingClassification = false;
          tempBalances.forEach((dataset) => {
            if (dataset.classification === holding.classification) {
              dataset.quantity = dataset.quantity + holding.quantity;
              matchingClassification = true;
            }
          });
          if (!matchingClassification) {
            tempBalances.push({
              classification: holding.classification,
              quantity: holding.quantity,
            });
          }
        }
      });
    });

    const holdingsOwn: any[] = [];
    const holdingsOwnLabels: any[] = [];
    let index = 0;
    for (const element of positiveBals) {
      holdingsOwn[index] = element.quantity;
      const classification = await this.classificationService.get(element.classification);
      holdingsOwnLabels[index] = classification.name;
      index++;
    }

    /*   positiveBals.forEach((element, index) => {
      holdingsOwn[index] = element.quantity;
      const classification = await this.classificationService.get(element.classification)
      holdingsOwnLabels[index] = element.classification;
    });*/
    this.holdingsOwn = holdingsOwn.slice(0, 10);
    this.holdingsOwnLabels = holdingsOwnLabels.slice(0, 10);

    const holdingsOwe: any[] = [];
    const holdingsOweLabels: any[] = [];
    negativeBals.forEach((element, index) => {
      holdingsOwe[index] = element.quantity;
      holdingsOweLabels[index] = element.classification;
    });
    this.holdingsOwe = holdingsOwe.slice(0, 10);
    this.holdingsOweLabels = holdingsOweLabels.slice(0, 10);
    this.nettHoldings = [nettOweBal, nettOwnBal];
    this.nettBalance = nettOwnBal + nettOweBal;
    this.holdingsData = newHoldingsArray;
  }
}
