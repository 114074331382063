<div class="projections-table-container">
  <div class="projection-form-inputs">
    <mat-form-field>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="Start date"
          (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
          #dateRangeStart
          [formControl]="startDate"
        />
        <input
          matEndDate
          placeholder="End date"
          (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
          #dateRangeEnd
          [formControl]="endDate"
        />
        <mat-hint>YYYY-MM-DD – YYYY-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-date-range-input>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Granularity</mat-label>
      <mat-select
        [(ngModel)]="selectedGranularity"
        name="recurringPeriod"
        (selectionChange)="granularityChange()"
        disableOptionCentering
      >
        <mat-option *ngFor="let granularity of granularityOptions" [value]="granularity">
          {{ granularity }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-text">
        {{ element.accountId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-text">
        {{ element.transactionDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-text">
        {{ element.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="categories">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Categories</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-text">
        {{ element.categories | categoryPipe: "getCategoriesNamesList" | async }}
      </td>
    </ng-container>
    <ng-container matColumnDef="classifications">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Classifications</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-text">
        {{ element.classifications | classificationPipe: "getClassificationsNamesList" | async }}
      </td>
    </ng-container>
    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-text">
        {{ element.quantity.actualQuantity.symbol }}
      </td>
    </ng-container>

    <ng-container matColumnDef="in">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">In</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-number">
        <span *ngIf="isDirectIn(element.direction)">{{
          element.quantity.actualQuantity.amount | currency: element.currency
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="out">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Out</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-number">
        <span *ngIf="isDirectOut(element.direction)">{{
          element.quantity.actualQuantity.amount | currency: element.currency
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="in_normalized">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
        In ( Normalized )
      </th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-number">
        <span *ngIf="isDirectIn(element.direction) && element.valuation !== undefined">{{
          element.valuation.normalizedValue.amount | currency: this.baseCurrency
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="out_normalized">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
        Out ( Normalized )
      </th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-number">
        <span *ngIf="isDirectOut(element.direction) && element.valuation !== undefined">{{
          element.valuation.normalizedValue.amount | currency: this.baseCurrency
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="aggregate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Aggregate</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-number">
        <div *ngIf="isDirectIn(element.direction)">
          <span *ngIf="isDirectIn(element.direction)">{{
            element.valuation.normalizedValue.amount | currency: this.baseCurrency
          }}</span>
        </div>
        <div *ngIf="isDirectOut(element.direction)">
          <span *ngIf="isDirectOut(element.direction)">{{
            element.valuation.normalizedValue.amount * -1 | currency: this.baseCurrency
          }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="own">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Own</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-number">
        <span
          *ngIf="element.quantity.actualQuantity.amount > 0 || element.quantity.actualQuantity == 0"
          >{{ element.quantity }}</span
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="owe">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Owe</th>
      <td mat-cell *matCellDef="let element" class="projections-table__column-number">
        <span *ngIf="element.quantity.actualQuantity < 0">{{
          element.quantity.actualQuantity
        }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of projections"
  >
  </mat-paginator>
</div>
