<div class="content">
  <h1 class="mat-h1">Projections Dashboard</h1>
  <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="400px">
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-holdings-owe-card
        [holdingsOwe]="holdingsOwe"
        [holdingsOweLabels]="holdingsOweLabels"
        title="Owe"
      ></app-holdings-owe-card>
    </mat-grid-tile>

    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-holdings-net-card
        [nettHoldings]="nettHoldings"
        [nettBalance]="nettBalance"
        [nettHoldingsLabels]="nettHoldingsLabels"
        title="Net"
      ></app-holdings-net-card>
    </mat-grid-tile>

    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-holdings-own-card
        [holdingsOwn]="holdingsOwn"
        [holdingsOwnLabels]="holdingsOwnLabels"
        title="Own"
      ></app-holdings-own-card>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.table.cols"
      [rowspan]="(cardLayout | async)?.table.rows"
    >
      <app-holdings-table-card
        [holdingsData]="holdingsData"
        title="Current Holdings"
      ></app-holdings-table-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
