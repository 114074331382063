import { Injectable } from "@angular/core";

import { Scenario } from "@bitwarden/web-vault/app/models/data/blobby/scenario.data";
import { ScenarioResponse } from "@bitwarden/web-vault/app/models/data/response/scenario.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { GroupScenarioBalance } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { CalculationServiceAbstraction } from "@bitwarden/web-vault/app/services/DataCalculationService/calculation.service.abstraction";
import { EstimateService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";

@Injectable({
  providedIn: "root",
})
export class ScenarioService implements CalculationServiceAbstraction {
  constructor(private blobbyService: BlobbyService, private estimateService: EstimateService) {}

  async runScenarioGroup(scenarioGroup: ScenarioGroup) {
    const groupScenarioBalanceArray: GroupScenarioBalance[] = [];
    for (const s of scenarioGroup.scenarios) {
      const scenario = new Scenario(new ScenarioResponse(s));
      const scenarioBalance = await this.getScenarioBalance(scenario, scenarioGroup);
      groupScenarioBalanceArray.push(scenarioBalance);
    }

    return groupScenarioBalanceArray;
  }

  async getScenarioBalance(scenario: Scenario, scenarioGroup: ScenarioGroup) {
    const groupedBalanceByScenario = await this.estimateService.runScenarioOptions(
      scenario,
      scenarioGroup
    );
    const groupScenarioBalance: GroupScenarioBalance = {
      ScenarioGroupId: scenarioGroup.id,
      scenarioID: scenario.id,
      scenarioType: scenario.scenarioType,
      scenarioName: scenario.name,
      createdRecords: groupedBalanceByScenario.createdRecords,
      groupedBalance: groupedBalanceByScenario.groupedBalance,
      graphData: null,
      helpInfo: groupedBalanceByScenario.helpInfo,
    };

    return groupScenarioBalance;
  }
}
