<app-help-tooltip (onTooltipClose)="onTooltipClose()"></app-help-tooltip>
<div class="modal-container create-account">
  <div class="m-header tw-mb-[30px] tw-px-10 xs:tw-px-5">
    <div class="tw-flex tw-items-center tw-text-2xl xs:tw-text-lg">
      <mat-icon class="header-icon">account_balance_wallet</mat-icon>
      <app-help-tooltip
        [showHelpTooltip]="showHelpTooltip"
        [tooltipInfo]="tooltipInfo"
        [xPosition]="xPosition"
        [yPosition]="yPosition"
        [pointer]="pointer"
        (onTooltipClose)="onTooltipClose()"
      ></app-help-tooltip>
      <div class="tw-flex tw-items-center">
        <div>Create a New Account</div>
        <div *ngIf="tooltipInfo" title="help" (click)="openDashboardHelp($event)">
          <mat-icon
            class="mat-icon-secondary mat-icon-tooltip"
            id="help-info-button-creation"
            [ngClass]="{ clicked: isClicked }"
            (click)="toggleClick($event)"
          >
            info_outline
          </mat-icon>
        </div>
      </div>
    </div>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <div class="tw-flex tw-gap-12 md:tw-flex-col">
      <ng-container>
        <button
          class="tile-button tw-bg-neutral-50 tw-text-neutral-800 md:tw-flex-row-reverse md:tw-justify-between"
          [ngClass]="{ 'tw-opacity-40': !hasBasiqAccess() }"
          (click)="createByLinking()"
          tabindex="-1"
        >
          <mat-icon class="add-new-account-icon tw-bg-primary">add</mat-icon>
          <div>
            {{ "linkedAccounts" | i18n }}
            <span class="tw-block tw-text-left tw-text-neutral-700">
              {{ "linkedAccountsExplanation" | i18n }}
            </span>
          </div>
        </button>
      </ng-container>

      <button
        *appRoleScope="RoleScope.GLOSS"
        class="tile-button tw-bg-neutral-50 tw-text-neutral-800 md:tw-flex-row-reverse md:tw-justify-between"
        (click)="createManually()"
        tabindex="-1"
      >
        <mat-icon class="add-new-account-icon tw-bg-secondary">add</mat-icon>
        <div>
          {{ "unlinkedAccounts" | i18n }}
          <span class="tw-block tw-text-left tw-text-neutral-700">
            {{ "unlinkedAccountsExplanation" | i18n }}
          </span>
        </div>
      </button>
      <!--    </div>-->
      <!--        <span *ngIf="!isPremiumAccount()" class="premium-info tw-mt-5 tw-block tw-px-3">-->
      <!--          {{ "premiumSubscriptionInformation" | i18n }}-->
      <!--          <a>Know more.</a>-->
      <!--        </span>-->
      <!--    </div>-->
    </div>
  </div>
</div>
