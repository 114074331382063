import { Injectable } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SideMenuService {
  private isGlossMenuOpenSubject$ = new BehaviorSubject<boolean>(false);
  isGlossMenuOpen$: Observable<boolean> = this.isGlossMenuOpenSubject$.pipe(distinctUntilChanged());

  private _shouldRunWizard = new BehaviorSubject(false);
  shouldRunWizard$ = this._shouldRunWizard.asObservable();

  get isGlossMenuOpen(): boolean {
    return this.isGlossMenuOpenSubject$.value;
  }

  setShouldRunWizard(shouldRunWizard: boolean) {
    this._shouldRunWizard.next(shouldRunWizard);
  }

  setGlossMenuOpenState(isOpen: boolean) {
    this.isGlossMenuOpenSubject$.next(isOpen);
  }

  loadSideMenu$(): Observable<any> {
    return this.isGlossMenuOpen$;
  }
}
