<h1 class="!tw-text-alt2 tw-text-4xl">Start Your Premium Account Now</h1>
<div class="tw-pt-32">
  <h2 class="tw-text-2xl">
    Millions of individuals, teams, and organizations worldwide trust Bitwarden for secure password
    storage and sharing.
  </h2>
</div>
<ul class="tw-text-main tw-mt-12 tw-flex tw-flex-col tw-gap-10 tw-text-2xl">
  <li>Store logins, secure notes, and more</li>
  <li>Secure your account with advanced two-step login</li>
  <li>Access anywhere on any device</li>
  <li>Create your account to get started</li>
</ul>
