export enum GlossRouteEnum {
  dashboard = "/primary-dashboard",
  account = "/gloss-settings/account",
  categories = "/gloss-settings/categories",
  classifications = "/gloss-settings/classifications",
  institutions = "/gloss-settings/institutions",
  import = "/import-management",
  reference = "/reference-management",
  estimates = "/estimates",
  settings = "/gloss-settings/preferences",
  userAccount = "/settings/account",
  subscription = "/settings/subscription",
  security = "/settings/security",
  preferences = "/settings/preferences",
}
