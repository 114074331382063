<div class="modal-container" (keydown.enter)="$event.preventDefault(); $event.stopPropagation()">
  <div class="m-header tw-mb-[30px] tw-px-10 xs:tw-px-5">
    <button class="mat-icon-button prev-icon" (click)="previousButtonOptions.onClick()">
      <mat-icon>{{ previousButtonOptions.iconName }}</mat-icon>
    </button>
    <div class="tw-flex tw-text-2xl xs:tw-text-lg">
      <mat-icon class="header-icon">account_balance_wallet</mat-icon>
      Find Institution / Bank
    </div>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <div class="tw-flex tw-flex-col">
      <app-countries (selectedCountry)="countrySelected($event)"></app-countries>
      <br />
      <app-institutions
        [country]="selectedCountry"
        (selectedInstitution)="institutionSelected($event)"
      >
      </app-institutions>

      <div class="tw-mt-10 tw-flex tw-justify-center tw-gap-8 md:tw-flex-col-reverse">
        <app-gloss-button
          [options]="previousButtonOptions"
          class="tw-w-full sm:tw-hidden"
        ></app-gloss-button>
        <app-gloss-button [options]="nextButtonOptions" class="tw-w-full"></app-gloss-button>
      </div>
    </div>
  </div>
</div>
