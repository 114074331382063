import { BasiqConfigType } from "../../../models/types/environement-config.type";

const basiqConfig = process.env.BASIQ as unknown as BasiqConfigType;

export class GlossApiProxyRequest {
  authed = true;
  data: any;
  hasResponse = true;
  method: "GET" | "POST" | "PUT" | "DELETE" = "POST";
  path = basiqConfig.endpoint.proxy_request;
  url = `${basiqConfig.url}/${basiqConfig.apiStage}`;
  alterHeaders: any = null;

  constructor(data: any) {
    this.data = data;
  }
}
