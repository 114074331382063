import { SubscriptionEnum, subscriptionStoreEnum } from "../enum/subscription.enum";
import { GranularityType } from "../types/balanceGroupingTypes";

/* This is mapped with the backend scope and claims set in the JWT token.
 *  https://miro.com/app/board/uXjVKub44XU=/?share_link_id=50902592729 */
export enum scopeEnum {
  api = "api", // legacy bitwarden
  offline_mode = "offline_mode", // legacy bitwarden
  VAULT = "VAULT",
  VIEW = "VIEW",
  DATA_AGGREGATOR = "DATA_AGGREGATOR",
  EQUITY = "EQUITY",
  GLOSS = "GLOSS",
  FOREX = "FOREX",
  EARLY_ACCESS = "EARLY_ACCESS",
}

export type ClaimType = {
  // naming convention is ${scope.lowercase()}_${realms_properties}
  vault_size_limit: string; // 200m, 5g, etc
  data_aggregator_connector_name: dataAggregatorConnector[]; // plaid or basiq or both or empty
  equity_interval: GranularityType; // ric access?
  forex_interval: GranularityType;
  forex_currency: string[]; // todo map that with the system currency
  market_data_exchanges: string[];
  gloss_subscription_tier: SubscriptionEnum;
  gloss_subscription_store: subscriptionStoreEnum;
  gloss_views: string[];
};

export enum dataAggregatorConnector {
  plaid = "plaid",
  basiq = "basiq",
}
