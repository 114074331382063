import { Component, Input, OnInit } from "@angular/core";
import { format } from "date-fns";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";

import { TransactionService } from "../../services/DataService/transaction/transaction.service";

@Component({
  selector: "app-monthly-income-expenses-card",
  templateUrl: "./monthly-income-expenses-card.component.html",
})
export class MonthlyIncomeExpensesCardComponent implements OnInit {
  @Input() title: string;

  constructor(
    private blobbyService: BlobbyService,
    private transactionService: TransactionService
  ) {}

  dataSetIn: Array<any>;
  dataSetOut: Array<any>;
  dataSetNettIn: Array<any>;
  dataLabels: Array<Date>;

  async ngOnInit(): Promise<void> {
    const transactions = await this.transactionService.getAll();
    this.parseGraphData(DataTransformer.castToTransactionArray(transactions));
  }

  private parseGraphData(transactions: Array<Transaction>) {
    // only look at the last 12 momths
    // sort into month/year categories
    // sort into direction
    // nett income = (Total in) - (Total out)
    // TODO: sample dataset
    // dataIn: [{x: '2022-12-25', y: 20000}, {x: '2023-01-26', in: 10500}]
    // dataOut: [{x: '2022-12-25', y: 50000}, {x: '2023-01-26', out: 55000}]
    const newDataSetIn: Array<any> = [];
    const newDataSetOut: Array<any> = [];
    const newDataSetNettIn: Array<any> = [];
    const newDataLabels: Array<Date> = [];

    const parsedMonths: Array<string> = [];
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    minDate.setDate(1);

    transactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.transactionDate.date);
      const newDate = new Date(transactionDate.setDate(1));

      const actualQuantity = transaction.quantity.actualQuantity.amount;
      if (transactionDate < minDate) {
        return;
      }
      const monthYear = transactionDate.getMonth() + "/" + transactionDate.getFullYear();
      const formattedDate = format(
        new Date(transactionDate.getFullYear(), transactionDate.getMonth(), 1),
        "dd/MM/yyyy"
      );
      // const formattedDate = "01/" + monthYear
      const direction = transaction.direction;

      if (!parsedMonths.includes(monthYear)) {
        parsedMonths.push(monthYear);
        newDataLabels.push(newDate);
        newDataSetIn.push({ x: formattedDate, y: 0 });
        newDataSetOut.push({ x: formattedDate, y: 0 });
      }
      const index = parsedMonths.indexOf(monthYear);
      if (direction == TransactionDirection.In) {
        const existingElement = newDataSetIn[index];
        newDataSetIn[index] = { ...existingElement, y: existingElement.y + actualQuantity };
      } else if (direction == TransactionDirection.Out) {
        const existingElement = newDataSetOut[index];
        newDataSetOut[index] = { ...existingElement, y: existingElement.y + actualQuantity };
      }

      // newDataSet = { x: "12/2022", y: 20000 };
      // this.dataSetIn.push(newDataSet);
    });

    // Calculate the data set for Nett Income
    newDataSetIn.forEach((income, index) => {
      let nettIncome = 0;
      if (income.y > 0 && income.y > newDataSetOut[index].y) {
        nettIncome = income.y - newDataSetOut[index].y;
      }
      newDataSetNettIn.push({ ...income, y: nettIncome });
    });

    this.dataSetIn = newDataSetIn;
    this.dataSetNettIn = newDataSetNettIn;
    this.dataSetOut = newDataSetOut;
    this.dataLabels = newDataLabels;
  }
}
