<div class="content">
  <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="300px">
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.largeChart.cols"
      [rowspan]="(cardLayout | async)?.largeChart.rows"
    >
      <app-monthly-income-expenses-card
        title="Monthly Income and Expenses"
      ></app-monthly-income-expenses-card>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-top-expenses-card title="Top Expenses"></app-top-expenses-card>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-income-vs-expenses-card title="Income vs Expenses"></app-income-vs-expenses-card>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.smallChart.cols"
      [rowspan]="(cardLayout | async)?.smallChart.rows"
    >
      <app-top-income-card title="Top Income"></app-top-income-card>
    </mat-grid-tile>
    <!--Transaction Table-->
    <mat-grid-tile
      [colspan]="(cardLayout | async)?.table.cols"
      [rowspan]="(cardLayout | async)?.table.rows"
    >
      <app-transactions-table-card title="Transactions"></app-transactions-table-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
