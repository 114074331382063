import { Injectable } from "@angular/core";
import { format as fnsFormat, isBefore, isWithinInterval, subDays as fnsSubDays } from "date-fns";
import { BehaviorSubject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { SyncStatusCollectionType } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { MarketDataConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";

const marketDataConfig = process.env.MARKETDATA as MarketDataConfigType;
const API_DATE_FORMAT = "yyyy-MM-dd";
@Injectable({
  providedIn: "root",
})
export class MarketDataService {
  private config: MarketDataConfigType;
  private transactions: Transaction[] = [];
  /** this prop should be set to true whenever new transactions are added to blobby*/
  static refreshTransactions = true;
  private totalPageSubject = new BehaviorSubject<{ page: number; totalPage: number }>({
    page: 0,
    totalPage: 0,
  });
  totalPage$ = this.totalPageSubject.asObservable();

  constructor(
    private globalService: GlobalService,
    private transactionService: TransactionService,
    private bookService: BookService,
    private preferenceService: PreferenceService,
    private dataRepositoryService: DataRepositoryService,
    private logService: LogService
  ) {
    this.config = marketDataConfig;
  }

  startPullingData() {
    this.totalPageSubject = new BehaviorSubject<{ page: number; totalPage: number }>({
      page: 0,
      totalPage: 0,
    });
    this.totalPage$ = this.totalPageSubject.asObservable();
  }

  async getFirstTransactionDate(transactions: Transaction[]): Promise<Date> {
    const earliestDate = this.findEarliestDate(transactions);
    if (earliestDate instanceof Date && !isNaN(earliestDate.getTime())) {
      return earliestDate;
    } else {
      return fnsSubDays(new Date(), 1);
    }
  }

  private findEarliestDate(transactions: Transaction[]): Date {
    return transactions.reduce((earliestDate, transaction) => {
      const currentDate = new Date(transaction?.transactionDate?.date);
      return currentDate.getTime() < earliestDate.getTime() ? currentDate : earliestDate;
    }, new Date(transactions[0]?.transactionDate?.date));
  }

  private getCurrenciesFromList(list: Array<Transaction | Book>): string[] {
    if (list.length === 0) {
      return [];
    }
    const currencyMap = list.reduce(
      (currencyValues: Record<string, boolean>, obj: Transaction | Book) => {
        if (obj instanceof Transaction) {
          if (obj.quantity && obj.quantity.currency && !currencyValues[obj.quantity.currency]) {
            currencyValues[obj.quantity.currency] = true;
          }
        } else if (obj instanceof Book) {
          if (obj.currency && !currencyValues[obj.currency]) {
            currencyValues[obj.currency] = true;
          }
        }
        return currencyValues;
      },
      {}
    );

    return Object.keys(currencyMap);
  }

  private async getBaseCurrency(): Promise<string> {
    const base = await this.preferenceService.get("baseCurrency");
    if (typeof base === "string") {
      return base;
    } else {
      this.logService.error("MarketDataService::getBaseCurrency => Could not fetch base currency");
      return "USD";
    }
  }

  async getSymbolsInTheSystem(
    transactionsInImportProcess: Transaction[],
    newBooks: Book[]
  ): Promise<string[]> {
    const transactionsCurrencies = this.getCurrenciesFromList(transactionsInImportProcess);
    const booksCurrencies = this.getCurrenciesFromList(newBooks);
    const baseCurrency = await this.getBaseCurrency();

    /** Remove duplicates */
    return [...new Set([...transactionsCurrencies, ...booksCurrencies, baseCurrency])];
  }

  async getCurrencyRates(
    startDate: Date,
    endDate: Date,
    symbols: string[]
  ): Promise<Array<ReferenceData>> {
    const baseCurrency = await this.getBaseCurrency();

    if (!baseCurrency || !symbols.length || (symbols.length === 1 && symbols[0] === baseCurrency)) {
      this.totalPageSubject.next({ page: 100, totalPage: 100 });
      this.totalPageSubject.complete();
      return [];
    }

    let ccyReference: ReferenceData[] = [];
    let nextPage = true;
    let page = 1;
    const path = `?base=${baseCurrency}&startDate=${fnsFormat(
      startDate,
      API_DATE_FORMAT
    )}&endDate=${fnsFormat(endDate, API_DATE_FORMAT)}&symbols=${symbols.join(",")}`;

    // TODO once paging works remove timer
    const timer = setTimeout(() => {
      this.totalPageSubject.next({ page: page++, totalPage: 5 });
    }, 500);

    try {
      while (nextPage) {
        const instrumentMarketData = await this.dataRepositoryService.send(
          "GET",
          path,
          null,
          true,
          true,
          `${this.config.url}/${this.config.apiStage}${this.config.endpoint?.currency}`
        );

        if (!instrumentMarketData.error) {
          //this.totalPageSubject.next({ page, totalPage: instrumentMarketData.totalPage });
          if (instrumentMarketData.nextPage) {
            for (const ccyData of instrumentMarketData.referenceData) {
              const ccyReferenceData = new ReferenceData(new ReferenceDataResponse(ccyData));
              ccyReference.push(ccyReferenceData);
            }
            page++;
          } else {
            for (const ccyData of instrumentMarketData.referenceData) {
              const ccyReferenceData = new ReferenceData(new ReferenceDataResponse(ccyData));
              ccyReference.push(ccyReferenceData);
            }
            this.totalPageSubject.next({ page: 100, totalPage: 100 });
            this.totalPageSubject.complete();
            nextPage = false;
          }
          this.totalPageSubject.next({ page: 100, totalPage: 100 });
          clearTimeout(timer);
        } else {
          this.totalPageSubject.next({ page: 100, totalPage: 100 });
          this.totalPageSubject.complete();
          clearTimeout(timer);
          nextPage = false;
          this.logService.info(
            `MarketDataService: Instrument Error :${instrumentMarketData.error.message}`
          );
          ccyReference = [];
          this.globalService.showErrorMessage(
            "errorOccurred",
            instrumentMarketData.error.errorMessage
          );
        }
      }
    } catch (e) {
      this.logService.error(`getCurrencyReferenceDataFromInstruments`);
      this.logService.error(e);
    }

    return ccyReference;
  }

  async getTransactions() {
    if (this.transactions.length === 0 || MarketDataService.refreshTransactions) {
      this.transactions = await this.transactionService.getAll(false);
    }

    return this.transactions;
  }

  async getCurrenciesInSystem() {
    const transactions = await this.getTransactions();
    const book = await this.bookService.getAll();

    return await this.getSymbolsInTheSystem(transactions, book);
  }

  async refreshCurrencyRates() {
    const transactions = await this.getTransactions();

    const startDate = await this.getFirstTransactionDate(transactions);
    const endDate = new Date();
    const symbols = await this.getCurrenciesInSystem();

    /* fetch and save currency */
    const ccyReference = await this.getCurrencyRates(startDate, endDate, symbols);
    await this.dataRepositoryService.importReferenceData(ccyReference);

    const syncStatus = (await this.preferenceService.get("syncStatus")) as SyncStatusCollectionType;
    const newSync = {
      ...syncStatus,
      ...{
        fixer: {
          startDate: fnsFormat(startDate, API_DATE_FORMAT),
          symbols: symbols,
          endDate: fnsFormat(endDate, API_DATE_FORMAT),
        },
      },
    };

    await this.preferenceService.updateKey("syncStatus", newSync);
  }

  async importCurrencyRates(startDate: Date, endDate: Date, currencies: string[]) {
    const ccyReference = await this.getCurrencyRates(startDate, endDate, currencies);

    await this.dataRepositoryService.importReferenceData(ccyReference);
  }

  async isDataUpToDate(startDate: Date, symbols: string[], endDate?: Date) {
    /* Only one symbols in the system no need to sync */
    if (symbols.length <= 1) {
      return true;
    }

    /* Get the previous sync information */
    const syncStatus = (await this.preferenceService.get("syncStatus")) as SyncStatusCollectionType;

    const checkStartDate = startDate;
    const checkEndDate = endDate ? endDate : fnsSubDays(new Date(), 1);
    const lastSyncInterval: Interval = {
      start: new Date(syncStatus.fixer.startDate),
      end: new Date(syncStatus.fixer.endDate),
    };

    /* If we are missing any symbols sync */
    if (symbols.every((symbol) => syncStatus.fixer.symbols.includes(symbol))) {
      return false;
    } else {
      if (isBefore(lastSyncInterval.end, lastSyncInterval.start)) {
        return false;
      } else {
        /* Check if the last sync was in our last sync interval */
        return (
          isWithinInterval(checkStartDate, lastSyncInterval) &&
          isWithinInterval(checkEndDate, lastSyncInterval)
        );
      }
    }
  }
}
