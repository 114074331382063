import { Injector } from "@angular/core";

import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import {
  TransferParameters,
  TransferOutput,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { CreatedRecords } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { TransactionCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.calculation.service";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";

export class TransactionTransferAction extends EstimateActionData {
  parameters: TransferParameters;
  createdRecords: TransferOutput;
  groupedBalance: BalanceGrouping;

  constructor(response: EstimateActionResponse) {
    super(response);
  }

  async run(
    parameters: TransferParameters,
    injector: Injector,
    createdRecords?: CreatedRecords
  ): Promise<TransferOutput> {
    this.fillInParameters(createdRecords, parameters);
    await super.run(parameters, injector, createdRecords);
    const transactions: Array<Transaction> = [];

    // create the out transaction
    const outTransaction = await this.createTransferTransaction(
      this.parameters.accountFrom.id,
      "Mock transfer : Out",
      this.parameters.amount * -1,
      injector
    );

    // create the in transaction
    const inTransaction = await this.createTransferTransaction(
      this.parameters.accountTo.id,
      "Mock transfer : In",
      this.parameters.amount,
      injector
    );

    // link the two transactions as a transfer in the system so they are not counted as a directional value
    outTransaction.linkedTo = [inTransaction.id];
    inTransaction.linkedTo = [outTransaction.id];

    transactions.push(outTransaction);
    transactions.push(inTransaction);

    if (this.groupedBalance) {
      await this.addTransaction(outTransaction, this.groupedBalance);
      await this.addTransaction(inTransaction, this.groupedBalance);
    }

    // create the output to return
    this.createdRecords = {
      transactions: transactions,
      groupedBalance: this.groupedBalance,
    };

    return this.createdRecords;
  }

  /**
   * createInterestTransaction - given a date and an amount, create an interest transaction for it
   *
   * @param previousDate
   * @param monthlyInterestAmount
   */

  async createTransferTransaction(
    accountID: string,
    description: string,
    amount: number,
    injector: Injector
  ): Promise<Transaction> {
    const transactionCalculationService = injector.get(TransactionCalculationService);
    const transactionNormalizeService = injector.get(TransactionNormalizeService);
    const classificationService = injector.get(ClassificationService);
    const defaultSplitClassification =
      await classificationService.createDefaultSplitClassification();
    const transferTransaction = await transactionCalculationService.createFakeTransaction(
      accountID,
      amount,
      this.parameters.symbol,
      this.parameters.transactionDate,
      description,
      transactionNormalizeService,
      defaultSplitClassification,
      ["placeholder"]
    );
    return transferTransaction;
  }

  fillInParameters(createdRecords: CreatedRecords, parameter: TransferParameters) {
    if (parameter?.accountTo === null && createdRecords.accounts.length > 0) {
      parameter.accountTo = createdRecords.accounts[0];
    }
  }
}
