import { Component, OnInit, Input, SimpleChanges, ViewChild, AfterViewInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DATE_LOCALE, DateAdapter } from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { RecurringPeriod } from "@bitwarden/common/enums/recurringPeriod";
import { TimeGranularity } from "@bitwarden/common/enums/timeGranularity";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { EstimateActionsService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-actions-service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";

export interface Period {
  frequency: number;
  period: RecurringPeriod;
}

export interface GranularityToPeriod {
  id: TimeGranularity;
  period: Period;
}

@Component({
  selector: "app-projections-table",
  templateUrl: "projections-table.component.html",
  styles: [
    "table { width: 100%;}",
    ".projections-table-container { width: 100% ;}",
    ".projections-table__column-number { text-align: right; padding: 5px;}",
    ".projections-table__column-text { text-align: left; padding: 5px;}",
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-US" }],
})
export class ProjectionsTableComponent implements OnInit, AfterViewInit {
  @Input() projectedTransactions: Array<any>;
  @Input() defaultStartDate: string;
  @Input() defaultEndDate: string;
  @Input() defaultGranularity: string;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    "account",
    "transactionDate",
    "categories",
    "classifications",
    "description",
    "symbol",
    "out",
    "in",
    "out_normalized",
    "in_normalized",
    "aggregate",
  ];
  selectedGranularity: string;
  timeGranularity = TimeGranularity;
  granularityOptions: Array<string>;
  baseCurrency: string;

  granularity: Array<GranularityToPeriod> = [
    {
      id: TimeGranularity.Daily,
      period: { frequency: 1, period: RecurringPeriod.Days },
    },
    {
      id: TimeGranularity.Weekly,
      period: { frequency: 1, period: RecurringPeriod.Weeks },
    },
    {
      id: TimeGranularity.Monthly,
      period: { frequency: 1, period: RecurringPeriod.Months },
    },
    {
      id: TimeGranularity.Quarterly,
      period: { frequency: 3, period: RecurringPeriod.Months },
    },
    {
      id: TimeGranularity.Biannual,
      period: { frequency: 6, period: RecurringPeriod.Months },
    },
    {
      id: TimeGranularity.Annual,
      period: { frequency: 1, period: RecurringPeriod.Years },
    },
    {
      id: TimeGranularity.Biennial,
      period: { frequency: 2, period: RecurringPeriod.Years },
    },
  ];

  initialised = false;
  events: string[] = [];
  startDate: FormControl;
  endDate: FormControl;
  // granularityForm = FormControl;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dateAdapter: DateAdapter<any>,
    private estimatesService: EstimateActionsService,
    private helperPreference: HelperPreference
  ) {
    this.dateAdapter.setLocale("en-CA");
    this.granularityOptions = Object.keys(this.timeGranularity);
    // this.dateAdapter.setLocale('en-US');
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.projectedTransactions);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.sortColumns;
  }

  sortColumns(t: Transaction, property: string) {
    const quantity = t.quantity.actualQuantity.amount;
    switch (property) {
      case "transactionDate": {
        return new Date(t.transactionDate.date).getTime();
      }
      case "symbol": {
        return t.quantity.actualQuantity.symbol;
      }
      case "description": {
        return t.description;
      }
      case "quantity": {
        return quantity;
      }
      case "out": {
        return t.direction === TransactionDirection.Out ? quantity : null;
      }
      case "in": {
        return t.direction === TransactionDirection.In ? quantity : null;
      }
      case "out_normalized": {
        if (t.direction === TransactionDirection.Out) {
          return t.valuation.normalizedValue.amount;
        } else {
          return null;
        }
      }
      case "in_normalized": {
        if (t.direction === TransactionDirection.In) {
          return t.valuation.normalizedValue.amount;
        } else {
          return null;
        }
      }
      case "aggregate": {
        if (t.direction === TransactionDirection.In) {
          return t.valuation.normalizedValue.amount;
        } else {
          return t.valuation.normalizedValue.amount * -1;
        }
      }
      case "direction": {
        return t.direction;
      }
      case "account": {
        return t.accountId;
      }
      case "currency": {
        return t.quantity.currency;
      }
      case "classifications": {
        return Array.isArray(t.classifications)
          ? t.classifications.sort().join()
          : t.classifications;
      }
      case "categories": {
        return Array.isArray(t.categories) ? t.categories.sort().join() : t.categories;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectedTransactions) {
      this.dataSource = new MatTableDataSource<Transaction>(this.projectedTransactions);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = this.sortColumns;
      if (this.sort) {
        this.sort.sort({ id: "transactionDate", start: "asc", disableClear: false });
      }
      this.dataSource.sort = this.sort;
    }
    if (changes.defaultStartDate) {
      this.startDate = new FormControl(this.defaultStartDate);
    }
    if (changes.defaultEndDate) {
      this.endDate = new FormControl(this.defaultEndDate);
    }
    if (changes.defaultGranularity) {
      this.selectedGranularity = this.defaultGranularity;
    }
  }

  isDirectOut(d: TransactionDirection) {
    return d === TransactionDirection.Out;
  }

  isDirectIn(d: TransactionDirection) {
    return d === TransactionDirection.In;
  }

  async ngOnInit(): Promise<void> {
    // console.log("start date:" + this.defaultStartDate)
    // console.log("end date:" + this.defaultEndDate)
    // set the startdate and enddate at initialisation to the dates we first used to create future transactions with
    this.baseCurrency = await this.helperPreference.getBaseCurrency();
    this.startDate = new FormControl(this.defaultStartDate);
    this.endDate = new FormControl(this.defaultEndDate);
    this.selectedGranularity = this.defaultGranularity;
    this.initialised = true;
  }

  async dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    // check that both start and end date are set and submit to the service
    // console.log("adding event")
    // console.log(event)
    // console.log(dateRangeStart.value);
    // console.log(dateRangeEnd.value);
    let period = RecurringPeriod.Months;
    let frequency = 1;
    if (this.selectedGranularity) {
      const granularityPeriod = this.granularity.find(
        (granularitySet) => granularitySet.id === this.selectedGranularity
      );
      frequency = granularityPeriod.period.frequency;
      period = granularityPeriod.period.period;
    }

    // check that both start and end date are set and submit to the service
    if (dateRangeStart.value !== "" && dateRangeEnd.value !== "") {
      // set the start date and end date on the estimates service and then get new transactions
      await this.estimatesService.updateDates(
        dateRangeStart.value,
        dateRangeEnd.value,
        frequency,
        period
      );
      // TODO: also supply granularity to update the revals
    }
  }

  async granularityChange() {
    // console.log(this.selectedGranularity)
    if (this.selectedGranularity) {
      const granularityPeriod = this.granularity.find(
        (granularitySet) => granularitySet.id === this.selectedGranularity
      );
      // console.log(granularityPeriod)
      await this.estimatesService.updateGranularity(
        this.startDate.value,
        this.endDate.value,
        granularityPeriod.period.frequency,
        granularityPeriod.period.period
      );
    }
  }
}
