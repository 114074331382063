<div class="creation-modal-container" (keydown.enter)="$event.preventDefault()">
  <div class="modal-header import-flow-modal-header">
    <button mat-icon-button color="primary" mat-dialog-close (click)="goBack()">
      <mat-icon>arrow_left</mat-icon>
    </button>
    <h6 class="header">Account Region</h6>
    <button
      mat-icon-button
      color="primary"
      mat-dialog-close
      aria-label="Close dialog"
      tabindex="-1"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-body">
    <div class="creation-modal-container">
      <div class="account-region-option-buttons-container">
        <button class="region-option option-au" (click)="goWithBasiq()">
          {{ "australia" | i18n }}
          <span class="button-explanation">
            {{ "auOptionExplanation" | i18n }}
          </span>
        </button>
        <button class="region-option option-us" (click)="goWithPlaid()">
          {{ "unitedStates" | i18n }}
          <span class="button-explanation">
            {{ "usOptionExplanation" | i18n }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
