import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

@Component({
  selector: "app-projected-income-vs-expenses-card",
  templateUrl: "./projected-income-vs-expenses-card.component.html",
})
export class ProjectedIncomeVsExpensesCardComponent implements OnInit {
  @Input() title: string;
  @Input() projectedTransactions: Array<Transaction>;

  dataSet: Array<any>;

  async ngOnInit(): Promise<void> {
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectedTransactions) {
      this.parseGraphData();
    }
  }

  private parseGraphData() {
    // only get transactions with direction out
    // sum them up based on the category-renderer split
    const newDataSet: Array<any> = [0, 0];

    if (this.projectedTransactions && Array.isArray(this.projectedTransactions)) {
      this.projectedTransactions.forEach((transaction) => {
        const actualQuantity = transaction.quantity.actualQuantity.amount;
        if (!isNaN(actualQuantity)) {
          if (transaction.direction == TransactionDirection.Out) {
            newDataSet[1] = newDataSet[1] + actualQuantity;
          } else {
            newDataSet[0] = newDataSet[0] + actualQuantity;
          }
        }
      });
    }

    this.dataSet = newDataSet;
  }
}
