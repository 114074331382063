<ng-container>
  <div
    class="app-bar tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-w-full tw-justify-around tw-bg-white"
  >
    <nav
      class="tw-flex tw-h-full tw-min-w-[25%] tw-flex-col tw-items-center tw-justify-center"
      (click)="selectLink('dashboard')"
      [ngClass]="{ active: selectedKey === 'dashboard' }"
      [routerLink]="['/', 'primary-dashboard']"
    >
      <mat-icon class="mat-icon-secondary">dashboard</mat-icon>
      <span>{{ "sideMenuDashboard" | i18n }}</span>
    </nav>

    <nav
      class="tw-flex tw-h-full tw-min-w-[25%] tw-flex-col tw-items-center tw-justify-center"
      [routerLink]="['/gloss-settings', 'account']"
      (click)="selectLink('account')"
      [ngClass]="{ active: selectedKey === 'account' }"
    >
      <mat-icon class="mat-icon-secondary">account_balance_wallet</mat-icon>
      <span>{{ "sideMenuAccount" | i18n }}</span>
    </nav>

    <nav
      class="tw-flex tw-h-full tw-min-w-[25%] tw-flex-col tw-items-center tw-justify-center"
      [routerLink]="['/settings', 'account']"
      (click)="selectLink('settings')"
      [ngClass]="{ active: selectedKey === 'settings' }"
    >
      <mat-icon class="mat-icon-secondary">settings</mat-icon>
      <span>{{ "settings" | i18n }}</span>
    </nav>

    <nav
      class="tw-flex tw-h-full tw-min-w-[25%] tw-flex-col tw-items-center tw-justify-center"
      (click)="selectLink('help')"
    >
      <mat-icon class="mat-icon-secondary">help</mat-icon>
      <span>{{ "help" | i18n }}</span>
    </nav>
  </div>
</ng-container>
