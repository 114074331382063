import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { NormalizedPair } from "@bitwarden/web-vault/app/models/data/shared/normalized-pair";
import { AllocationUtils } from "@bitwarden/web-vault/app/models/data/utils/allocation.utils";

export class Allocation extends AllocationUtils {
  private readonly __v = 1;
  category = "noCategory";
  classification = "noClassification";
  _value: NormalizedPair = new NormalizedPair();
  _balance: GlossBalance = new GlossBalance();

  get id(): string {
    return `${this.category}::${this.classification}`;
  }
  get value(): NormalizedPair {
    return this._value;
  }
  set value(value: NormalizedPair) {
    this._value = value;
  }
  get balance(): GlossBalance {
    return this._balance;
  }
  set balance(allocationBalance: GlossBalance) {
    this._balance = allocationBalance;
  }

  add(additionalAllocation: Allocation) {
    this.value.add(additionalAllocation.value);
    this.balance.add(additionalAllocation.balance);
  }

  setNormalizedNumber(normalizedNumber: GlossNumber) {
    this.value.normalizedValue = normalizedNumber;
  }

  setSymbolAmount(symbolAmount: GlossNumber) {
    this.value.symbolAmount = symbolAmount;
  }

  setNormalizedAmountSymbol(value: number, symbol: string) {
    this.setNormalizedAmount(value);
    this.setNormalizedSymbol(symbol);
  }

  setNormalizedAmount(value: number) {
    this.value.normalizedValue.amount = value;
  }

  setNormalizedSymbol(symbol: string) {
    this.value.normalizedValue.symbol = symbol;
  }

  setValueAmountSymbol(value: number, symbol: string) {
    this.setValueAmount(value);
    this.setValueSymbol(symbol);
  }

  setValueAmount(value: number) {
    this.value.symbolAmount.amount = value;
  }

  setValueSymbol(symbol: string) {
    this.value.symbolAmount.symbol = symbol;
  }

  setToAllocationObj(response: Record<string, any>) {
    return response instanceof Allocation
      ? this.setFromObject(response)
      : this.setFromQueryString(response);
  }

  private setFromObject(allocation: Allocation) {
    return allocation;
  }

  private setFromQueryString(response: Record<string, any>) {
    if (response.value) {
      this.value = new NormalizedPair().setToNormalizedPairObj(response.value);
    } else if (response._value) {
      this.value = new NormalizedPair().setToNormalizedPairObj(response._value);
    }

    if (response.balance) {
      this.balance = new GlossBalance().setToBalanceObj(response.balance);
    } else if (response._balance) {
      this.balance = new GlossBalance().setToBalanceObj(response._balance);
    }

    if (response.category) {
      this.category = response.category;
    }

    if (response.classification) {
      this.classification = response.classification;
    }
    return this;
  }
}
