<!--<app-scenario-difference-message></app-scenario-difference-message>-->

<div class="transaction-table-tabs">
  <div class="tabs-container sm:tw-justify-between">
    <ng-container *ngIf="tabs">
      <div class="tab-container" *ngFor="let tab of tabs">
        <div class="{{ tabContainerByDevice(tab) }}" (click)="updateTab(tab.type, tab.key)">
          {{ tabName(tab) }}
          <div *ngIf="tab.type === 'scenario' && tab.class.includes('selected')">
            <!--            todo: double click event here for quick fix, 1 is for using button id to trigger mimic click event for end of wizard, second is for changing icon color on tooltip open-->
            <button
              tabindex="-1"
              mat-icon-button
              title="{{ 'pathHelpTitle' | i18n }}"
              id="scenario-{{ tab.key + 1 }}-help-button"
              (click)="toggleSelected(tab.key.toString()); openTabHelp($event, tab.type, tab.key)"
            >
              <mat-icon
                class="mat-icon-secondary mat-icon-transaction-table-tabs mat-icon-tooltip"
                (click)="toggleSelected(tab.key.toString()); openTabHelp($event, tab.type, tab.key)"
                [class.clicked]="selectedTabKey === tab.key.toString()"
              >
                info_outline
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <app-help-tooltip
      [showHelpTooltip]="showHelpTooltip"
      [tooltipInfo]="tooltipInfo"
      [xPosition]="xPosition"
      [yPosition]="yPosition"
      [pointer]="pointer"
      [tabs]="tabs"
      (onTooltipClose)="onTooltipClose()"
      (toggleDeselected)="toggleSelected(null)"
    ></app-help-tooltip>
  </div>

  <div class="buttons-container tw-flex" *ngIf="false">
    <button class="table-action-buttons right-space" disabled>
      <mat-icon class="transaction-action-button-icon" aria-hidden="true">edit</mat-icon>
      {{ "edit" | i18n }}
    </button>

    <button class="table-action-buttons right-space" (click)="resetColumns.emit()">
      <mat-icon class="transaction-action-button-icon" aria-hidden="true">history</mat-icon>
      {{ "reset" | i18n }}
    </button>

    <button class="table-action-buttons right-space" (click)="callDeleteTransaction()">
      <mat-icon class="transaction-action-button-icon" aria-hidden="true">delete</mat-icon>
      {{ "delete" | i18n }}
    </button>

    <div *ngIf="inDevMode">
      <button
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="table-action-buttons right-space"
        id="transaction-more-actions"
      >
        <div class="more-actions-dot"></div>
        <div class="more-actions-dot"></div>
        <div class="more-actions-dot"></div>
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button
          class="dropdown-item"
          href="#"
          (click)="callLinkConversion()"
          [disabled]="!isLinkButtonEnabled"
        >
          {{ "linkAsConversion" | i18n }}
        </button>
        <button
          class="dropdown-item"
          href="#"
          (click)="callLinkTransfer()"
          [disabled]="!isLinkButtonEnabled"
        >
          {{ "linkAsTransfer" | i18n }}
        </button>
        <button
          class="dropdown-item"
          href="#"
          (click)="callUnlinkTransactions()"
          [disabled]="!isLinkButtonEnabled"
        >
          {{ "removeLinkage" | i18n }}
        </button>
      </div>
    </div>
  </div>
</div>
