import { Injectable } from "@angular/core";

import { BalanceDirection } from "@bitwarden/common/enums/balanceDirection";
import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { RevaluationService } from "@bitwarden/web-vault/app/services/DataCalculationService/revaluation/revaluation.service";
import { TransactionCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.calculation.service";
import { EstimateService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";

import { EstimateActionData } from "../../../models/data/blobby/estimate.action.data";
import { Transaction } from "../../../models/data/blobby/transaction.data";
import { EstimateType } from "../../../models/enum/estimateType";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { EstimateActionsService } from "../../DataService/estimates/estimate-actions-service";
import { SymbolService } from "../../DataService/symbol/symbol.service";
import { BlobbyService } from "../../blobby/blobby.service";
import { AllocationService } from "../allocation/allocation.service";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class TransactionEstimateService implements CalculationServiceAbstraction {
  // TODO: we will need to store the base currency in user settings at some point and call that instead of this
  // hardcoded value
  baseCurrency: string;
  allTransactions: Array<Transaction>;

  projectedTransactions: Array<Transaction>;
  creationOrder: Array<EstimateType> = [
    EstimateType.Transaction,
    EstimateType.Interest,
    EstimateType.Revaluation,
  ];

  /**
   * @param dataRepositoryService
   * @param blobbyService
   * @param symbolService
   * @param allocationService
   * @param estimatesService
   * @param transactionCalculationService
   * @param estimateGroupService
   * @param helperPreference
   */
  constructor(
    private dataRepositoryService: DataRepositoryService,
    /** @deprecated **/ private blobbyService: BlobbyService,
    private symbolService: SymbolService,
    private allocationService: AllocationService,
    private estimatesService: EstimateActionsService, // private logService: LogService
    private transactionCalculationService: TransactionCalculationService,
    private estimateGroupService: EstimateService,
    private helperPreference: HelperPreference,
    private revaluationService: RevaluationService
  ) {
    this.setBaseCurrency();
  }

  async setBaseCurrency(): Promise<string> {
    return await this.helperPreference.getBaseCurrency();
  }

  async getAllTransactions() {
    if (!this.allTransactions) {
      this.allTransactions = await this.revaluationService.generateRevaluations();
    }
  }

  /**
   * addTransactionData - adds the extra details required for a transaction type estimate transaction
   *
   * @param estimate - the original estimate data
   * @param transResData - the transaction response we need to generate to create the transaction with
   */
  addTransactionData(estimate: EstimateActionData, transResData: any) {
    if (estimate.transactionTemplate) {
      const glossQuantity = new GlossQuantity();
      glossQuantity.currency = estimate.transactionTemplate.currency;
      glossQuantity.setQuantityAmountSymbol(
        estimate.transactionTemplate.quantity,
        estimate.transactionTemplate.symbol
      );
      transResData.quantity = glossQuantity;
    }
  }

  /**
   * addInterestData - adds the extra details required for an interest type estimate transaction
   *
   * @param estimate - the original estimate data
   * @param transResData - the transaction response we need to generate to create the transaction with
   * @param futureDate - the date in the future we are creating the transaction for
   * @param estimateTransactions - the list of generated transactions so far that needs to be used to get the closing balance
   */
  async addInterestData(
    estimate: EstimateActionData,
    transResData: any,
    futureDate: Date,
    estimateTransactions: Array<Transaction>
  ) {
    // the interestMatch component must be set for an interest type future estimate
    if (!estimate.interestMatch) {
      return;
    }

    let balanceTotal = 0;
    let accountBalance = 0;

    await this.getAllTransactions();

    const transactionsWithEstimates = this.allTransactions.concat(estimateTransactions);
    const groupedBalances = await this.transactionCalculationService.getBalance(
      transactionsWithEstimates,
      true,
      true,
      ["account"]
    );

    // TODO: get the balance for the account only once the proper balance function has been implemented
    // and then restrict match on category-renderer and or classification
    accountBalance = this.transactionCalculationService.getAccountCurrencyBalanceByDate(
      groupedBalances,
      estimate.interestMatch.account[0],
      estimate.account.currency,
      futureDate
    );

    if (estimate.interestMatch.balanceDirection === BalanceDirection.Own) {
      if (accountBalance > 0) {
        balanceTotal = balanceTotal + accountBalance;
      }
    } else if (estimate.interestMatch.balanceDirection === BalanceDirection.Owe) {
      if (accountBalance < 0) {
        balanceTotal = balanceTotal + accountBalance;
      }
    }

    // TODO: This section can be rewritten to use the new functions to the balance from blobby
    // get the current balance matrix from blobby
    // for (const account of estimate.interestMatch.account) {

    // calculate the current balance with the parameters from interestMatch
    balanceTotal = Math.abs(balanceTotal);
    const interestAmount = (balanceTotal * estimate.interestMatch.percentage) / 100;
    const glossQuantity = new GlossQuantity();
    glossQuantity.currency = estimate.account.currency;
    glossQuantity.setQuantityAmountSymbol(interestAmount, estimate.account.currency);
    transResData.quantity = glossQuantity;
  }
}
