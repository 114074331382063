<div class="modal-container">
  <div class="m-header tw-mb-[30px] tw-px-10 xs:tw-px-5">
    <div class="tw-flex tw-items-center tw-text-2xl xs:tw-text-lg">
      <svg class="tw-mr-3 tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#card" />
      </svg>
      {{ data.accountView.name }} : {{ title | i18n }}
    </div>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="m-body tw-p-10 xs:tw-px-5">
    <form [formGroup]="form" name="accountForm">
      <div class="tw-flex tw-flex-col">
        <app-currencies (selectedCurrency)="currencySelected($event)"></app-currencies>
        <br />

        <app-gloss-input [options]="balanceInputOptions"></app-gloss-input>
        <br />
        <app-date-picker
          inputId="date"
          controlName="date"
          [dateValue]="form.controls.date.value"
          [maxDate]="maxDate"
          labelKey="date"
          (changeDateEvent)="onEndDateChangeEvent($event)"
        >
        </app-date-picker>
        <mat-error *ngIf="form.controls.date.hasError('required')"> Field is required </mat-error>
      </div>
      <div class="tw-flex tw-justify-center tw-gap-8 md:tw-flex-col">
        <button class="nav-button tw-bg-primary tw-text-white" (click)="submit()">
          {{ "save" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
