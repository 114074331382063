import { NgModule } from "@angular/core";
import { Route, RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "@bitwarden/angular/guards/auth.guard";
import { InitialRedirectGuard } from "@bitwarden/angular/guards/initialRoute.guard";
import { LockGuard } from "@bitwarden/angular/guards/lock.guard";
import { UnauthGuard } from "@bitwarden/angular/guards/unauth.guard";
import { PlanComponent } from "@bitwarden/web-vault/app/components/paywall/plan/plan.component";
import { PreviewTransactionTableComponent } from "@bitwarden/web-vault/app/components/preview-transaction-table/preview-transaction-table.component";
import { TransactionFormComponent } from "@bitwarden/web-vault/app/gloss/dash/transactions-form/transaction-form.component";
import { DeveloperTestComponent } from "@bitwarden/web-vault/app/gloss/developer-test/developer-test.component";
import { EstimatesComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates.component";
import { ReferenceManagementComponent } from "@bitwarden/web-vault/app/gloss/import/reference-management/reference-management.component";
import { ManageCategoriesComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-categories/manage-categories.component";
import { ManageClassificationsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-classifications/manage-classifications.component";
import { ManageInstitutionsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/manage-institutions.component";
import { ManagePreferencesComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/manage-preferences.component";
import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { SubscriptionComponent } from "@bitwarden/web-vault/app/settings/subscription.component";
import { getRouteNameForNavigation } from "@bitwarden/web-vault/app/shared/utils/helper.navigation";

import { flagEnabled, Flags } from "../utils/flags";

import { AcceptEmergencyComponent } from "./accounts/accept-emergency.component";
import { AcceptOrganizationComponent } from "./accounts/accept-organization.component";
import { HintComponent } from "./accounts/hint.component";
import { LockComponent } from "./accounts/lock.component";
import { LoginWithDeviceComponent } from "./accounts/login/login-with-device.component";
import { LoginComponent } from "./accounts/login/login.component";
import { RecoverDeleteComponent } from "./accounts/recover-delete.component";
import { RecoverTwoFactorComponent } from "./accounts/recover-two-factor.component";
import { RemovePasswordComponent } from "./accounts/remove-password.component";
import { SetPasswordComponent } from "./accounts/set-password.component";
import { SsoComponent } from "./accounts/sso.component";
import { TrialInitiationComponent } from "./accounts/trial-initiation/trial-initiation.component";
import { TwoFactorComponent } from "./accounts/two-factor.component";
import { UpdatePasswordComponent } from "./accounts/update-password.component";
import { UpdateTempPasswordComponent } from "./accounts/update-temp-password.component";
import { VerifyEmailTokenComponent } from "./accounts/verify-email-token.component";
import { VerifyRecoverDeleteComponent } from "./accounts/verify-recover-delete.component";
import { MemberRegisterComponents } from "./components/paywall/register/member-register.components";
import { DashComponent } from "./gloss/dash/dash.component";
import { DashboardHoldingComponent } from "./gloss/dashboard-holding/dashboard-holding.component";
import { DashPrimaryComponent } from "./gloss/dashboard-primary/dash-primary.component";
import { DashboardProjectionComponent } from "./gloss/dashboard-projection/dashboard-projection.component";
import { ImportCsvComponent } from "./gloss/import/import-csv.component";
import { ImportManagementComponent } from "./gloss/import/import-management.component";
import { ManageAccountComponent } from "./gloss/settings/manage-accounts/manage-account.component";
import { HomeGuard } from "./guards/home.guard";
import { FrontendLayoutComponent } from "./layouts/frontend-layout.component";
import { UserLayoutComponent } from "./layouts/user-layout.component";
import { OrganizationModule } from "./organizations/organization.module";
import { AcceptFamilySponsorshipComponent } from "./organizations/sponsorships/accept-family-sponsorship.component";
import { FamiliesForEnterpriseSetupComponent } from "./organizations/sponsorships/families-for-enterprise-setup.component";
import { ReportsModule } from "./reports";
import { AccessComponent } from "./send/access.component";
import { SendComponent } from "./send/send.component";
import { AccountComponent } from "./settings/account.component";
import { CreateOrganizationComponent } from "./settings/create-organization.component";
import { DomainRulesComponent } from "./settings/domain-rules.component";
import { EmergencyAccessViewComponent } from "./settings/emergency-access-view.component";
import { EmergencyAccessComponent } from "./settings/emergency-access.component";
import { SecurityRoutingModule } from "./settings/security-routing.module";
import { SettingsComponent } from "./settings/settings.component";
import { SponsoredFamiliesComponent } from "./settings/sponsored-families.component";
import { SubscriptionRoutingModule } from "./settings/subscription-routing.module";
import { GeneratorComponent } from "./tools/generator.component";
import { ToolsComponent } from "./tools/tools.component";
import { VaultModule } from "./vault/vault.module";

const routes: Routes = [
  {
    path: "",
    component: FrontendLayoutComponent,
    data: { doNotSaveUrl: true },
    children: [
      {
        path: "",
        pathMatch: "full",
        children: [], // Children lets us have an empty component.
        canActivate: [HomeGuard], // Redirects either to primary-dashboard, login or lock page.
      },
      { path: "login", component: LoginComponent, canActivate: [UnauthGuard] },
      { path: "membership-plan", component: PlanComponent, canActivate: [UnauthGuard] },
      { path: "register-member", component: MemberRegisterComponents, canActivate: [UnauthGuard] },
      {
        path: "login-with-device",
        component: LoginWithDeviceComponent,
        data: { titleId: "loginWithDevice" },
      },
      { path: "2fa", component: TwoFactorComponent, canActivate: [UnauthGuard] },
      {
        path: "register",
        component: TrialInitiationComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "createAccount" },
      },
      {
        path: "trial",
        redirectTo: "register",
        pathMatch: "full",
      },
      {
        path: "sso",
        component: SsoComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "enterpriseSingleSignOn" },
      },
      {
        path: "set-password",
        component: SetPasswordComponent,
        data: { titleId: "setMasterPassword" },
      },
      {
        path: "hint",
        component: HintComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "passwordHint" },
      },
      {
        path: "lock",
        component: LockComponent,
        canActivate: [LockGuard],
      },
      { path: "verify-email", component: VerifyEmailTokenComponent },
      {
        path: "accept-organization",
        component: AcceptOrganizationComponent,
        data: { titleId: "joinOrganization", doNotSaveUrl: false },
      },
      {
        path: "accept-emergency",
        component: AcceptEmergencyComponent,
        data: { titleId: "acceptEmergency", doNotSaveUrl: false },
      },
      {
        path: "accept-families-for-enterprise",
        component: AcceptFamilySponsorshipComponent,
        data: { titleId: "acceptFamilySponsorship", doNotSaveUrl: false },
      },
      { path: "recover", pathMatch: "full", redirectTo: "recover-2fa" },
      {
        path: "recover-2fa",
        component: RecoverTwoFactorComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "recoverAccountTwoStep" },
      },
      {
        path: "recover-delete",
        component: RecoverDeleteComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "deleteAccount" },
      },
      {
        path: "verify-recover-delete",
        component: VerifyRecoverDeleteComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "deleteAccount" },
      },
      {
        path: "send/:sendId/:key",
        component: AccessComponent,
        data: { title: "Bitwarden Send" },
      },
      {
        path: "update-temp-password",
        component: UpdateTempPasswordComponent,
        canActivate: [AuthGuard],
        data: { titleId: "updateTempPassword" },
      },
      {
        path: "update-password",
        component: UpdatePasswordComponent,
        canActivate: [AuthGuard],
        data: { titleId: "updatePassword" },
      },
      {
        path: "remove-password",
        component: RemovePasswordComponent,
        canActivate: [AuthGuard],
        data: { titleId: "removeMasterPassword" },
      },
      {
        path: "transaction-preview",
        component: PreviewTransactionTableComponent,
      },
    ],
  },
  {
    path: "",
    component: UserLayoutComponent,
    canActivate: [AuthGuard, InitialRedirectGuard],
    children: [
      {
        path: "vault",
        loadChildren: () => VaultModule,
      },
      { path: "sends", component: SendComponent, data: { title: "Send" } },
      { path: "test-page", component: DeveloperTestComponent },
      { path: getRouteNameForNavigation(GlossRouteEnum.estimates), component: EstimatesComponent },
      { path: "transactions-dashboard", component: DashComponent },
      { path: "holdings-dashboard", component: DashboardHoldingComponent },
      { path: "projections-dashboard", component: DashboardProjectionComponent },
      {
        path: getRouteNameForNavigation(GlossRouteEnum.dashboard),
        component: DashPrimaryComponent,
      },
      {
        path: getRouteNameForNavigation(GlossRouteEnum.import),
        component: ImportManagementComponent,
      },
      {
        path: getRouteNameForNavigation(GlossRouteEnum.reference),
        component: ReferenceManagementComponent,
      },
      { path: "import-csv", component: ImportCsvComponent },
      { path: "add-edit-transaction", component: TransactionFormComponent },
      {
        path: "create-organization",
        component: CreateOrganizationComponent,
        data: { titleId: "newOrganization" },
      },
      {
        path: "gloss-settings",
        children: [
          { path: "", pathMatch: "full", redirectTo: "account" },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.account),
            component: ManageAccountComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.classifications),
            component: ManageClassificationsComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.categories),
            component: ManageCategoriesComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.institutions),
            component: ManageInstitutionsComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.settings),
            component: ManagePreferencesComponent,
          },
        ],
      },

      {
        path: "settings",
        component: SettingsComponent,
        children: [
          { path: "", pathMatch: "full", redirectTo: "account" },
          { path: "account", component: AccountComponent, data: { titleId: "myAccount" } },
          {
            path: "preferences",
            component: ManagePreferencesComponent,
            data: { titleId: "preferences" },
          },
          {
            path: "security",
            loadChildren: () => SecurityRoutingModule,
          },
          { path: "subscription", component: SubscriptionComponent },
          {
            path: "domain-rules",
            component: DomainRulesComponent,
            data: { titleId: "domainRules" },
          },
          {
            path: "subscription",
            loadChildren: () => SubscriptionRoutingModule,
          },
          {
            path: "emergency-access",
            children: [
              {
                path: "",
                component: EmergencyAccessComponent,
                data: { titleId: "emergencyAccess" },
              },
              {
                path: ":id",
                component: EmergencyAccessViewComponent,
                data: { titleId: "emergencyAccess" },
              },
            ],
          },
          {
            path: "sponsored-families",
            component: SponsoredFamiliesComponent,
            data: { titleId: "sponsoredFamilies" },
          },
        ],
      },
      {
        path: "tools",
        component: ToolsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "generator" },
          {
            path: "",
            loadChildren: () =>
              import("./tools/import-export/import-export.module").then(
                (m) => m.ImportExportModule
              ),
          },
          {
            path: "generator",
            component: GeneratorComponent,
            data: { titleId: "generator" },
          },
        ],
      },
      {
        path: "reports",
        loadChildren: () => ReportsModule,
      },
      { path: "setup/families-for-enterprise", component: FamiliesForEnterpriseSetupComponent },
    ],
  },
  {
    path: "organizations",
    loadChildren: () => OrganizationModule,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: "always",
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class OssRoutingModule {}

export function buildFlaggedRoute(flagName: keyof Flags, route: Route): Route {
  return flagEnabled(flagName)
    ? route
    : {
        path: route.path,
        redirectTo: "/",
      };
}
