import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { format } from "date-fns";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

@Component({
  selector: "app-projected-income-expenses-card",
  templateUrl: "./projected-income-expenses-card.component.html",
})
export class ProjectedIncomeExpensesCardComponent implements OnInit {
  @Input() title: string;
  @Input() projectedTransactions: Array<Transaction>;

  dataSetIn: Array<any>;
  dataSetOut: Array<any>;
  dataSetNettIn: Array<any>;
  dataLabels: Array<Date>;

  async ngOnInit(): Promise<void> {
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectedTransactions) {
      this.parseGraphData();
    }
  }

  private parseGraphData() {
    const newDataSetIn: Array<any> = [];
    const newDataSetOut: Array<any> = [];
    const newDataSetNettIn: Array<any> = [];
    const newDataLabels: Array<Date> = [];

    const parsedMonths: Array<string> = [];
    if (this.projectedTransactions && Array.isArray(this.projectedTransactions)) {
      this.projectedTransactions.forEach((transaction) => {
        const transactionDate = new Date(transaction.transactionDate.date);
        const newDate = new Date(transactionDate.setDate(1));
        const monthYear = transactionDate.getMonth() + "/" + transactionDate.getFullYear();
        const formattedDate = format(
          new Date(transactionDate.getFullYear(), transactionDate.getMonth(), 1),
          "dd/MM/yyyy"
        );
        const direction = transaction.direction;
        const actualQuantity = transaction.quantity.actualQuantity.amount;

        if (!parsedMonths.includes(monthYear)) {
          parsedMonths.push(monthYear);
          newDataLabels.push(newDate);
          newDataSetIn.push({ x: formattedDate, y: 0 });
          newDataSetOut.push({ x: formattedDate, y: 0 });
        }
        const index = parsedMonths.indexOf(monthYear);
        if (direction == TransactionDirection.In) {
          const existingElement = newDataSetIn[index];
          newDataSetIn[index] = { ...existingElement, y: existingElement.y + actualQuantity };
        } else if (direction == TransactionDirection.Out) {
          const existingElement = newDataSetOut[index];
          newDataSetOut[index] = {
            ...existingElement,
            y: existingElement.y + actualQuantity,
          };
        }
      });
    }

    // Calculate the data set for Nett Income
    newDataSetIn.forEach((income, index) => {
      let nettIncome = 0;
      if (income.y > 0 && income.y > newDataSetOut[index].y) {
        nettIncome = income.y - newDataSetOut[index].y;
      }
      newDataSetNettIn.push({ ...income, y: nettIncome });
    });

    this.dataSetIn = newDataSetIn;
    this.dataSetNettIn = newDataSetNettIn;
    this.dataSetOut = newDataSetOut;
    this.dataLabels = newDataLabels;
  }
}
