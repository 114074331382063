<div class="tw-max-w-5xl">
  <div class="tw-mx-14" *ngIf="scenarioIndex === 2">
    <span class="tw-m-auto tw-flex tw-text-base">
      {{ scenarioMessages.scenario3 }}
    </span>

    <div *ngIf="showActionButton">
      <span class="tw-m-auto tw-block tw-flex tw-text-base">{{ actionPreMessage }}</span>
      <span class="tw-m-auto tw-flex tw-text-base">
        <span
          (click)="openHelp($event)"
          class="tw-cursor-pointer tw-pr-1.5 tw-text-lg tw-font-bold tw-leading-[1.25] tw-text-accent tw-underline"
          >{{ actionLinkText }}
        </span>
        {{ actionLinkMessage }}
      </span>
    </div>
  </div>

  <div class="tw-mx-14" *ngIf="scenarioIndex === 1">
    <span class="tw-m-auto tw-flex tw-text-left tw-text-base">
      {{ scenarioMessages.scenario2 }}
    </span>
    <div *ngIf="showActionButton">
      <span class="tw-m-auto tw-block tw-flex tw-text-base">{{ actionPreMessage }}</span>
      <span class="tw-m-auto tw-flex tw-text-base">
        <span
          (click)="openHelp($event)"
          class="tw-cursor-pointer tw-pr-1.5 tw-text-lg tw-font-bold tw-leading-[1.25] tw-text-accent tw-underline"
          >{{ actionLinkText }}
        </span>
        {{ actionLinkMessage }}
      </span>
    </div>
  </div>

  <div class="tw-mx-14" *ngIf="scenarioIndex === 0">
    <span class="tw-m-auto tw-flex tw-text-base">
      {{ scenarioMessages.scenario1 }}
    </span>
    <div *ngIf="showActionButton">
      <span class="tw-m-auto tw-block tw-flex tw-text-base">{{ actionPreMessage }}</span>
      <span class="tw-m-auto tw-flex tw-text-base">
        <span
          (click)="openHelp($event)"
          class="tw-cursor-pointer tw-pr-1.5 tw-text-lg tw-font-bold tw-leading-[1.25] tw-text-accent tw-underline"
          >{{ actionLinkText }}
        </span>
        {{ actionLinkMessage }}
      </span>
    </div>
  </div>

  <div class="tw-mx-14" *ngIf="dashboardType === transactionOnly">
    <span class="tw-m-auto tw-flex tw-text-base">
      {{ transactionOnlyMessage }}
    </span>
    <div *ngIf="showActionButton">
      <span class="tw-m-auto tw-block tw-flex tw-text-base">{{ actionPreMessage }}</span>
      <span class="tw-m-auto tw-flex tw-text-base">
        <span
          (click)="openHelp($event)"
          class="tw-cursor-pointer tw-pr-1.5 tw-text-lg tw-font-bold tw-leading-[1.25] tw-text-accent tw-underline"
          >{{ actionLinkText }}
        </span>
        {{ actionLinkMessage }}
      </span>
    </div>
  </div>
</div>
