import { Component, Output, Input, EventEmitter, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { actions } from "@storybook/addon-actions";

import { PresetDateRangeComponent } from "./preset-date-range/preset-date-range.component";
import "./date-range-picker.scss";

@Component({
  selector: "app-date-range-picker",
  templateUrl: "./date-range-picker.component.html",
  styles: ["date-range-picker.scss"],
})
export class DateRangePickerComponent {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  timeOut: NodeJS.Timeout;
  @Input() defaultDates: Array<Date>;
  @Output() dateChangeEvent = new EventEmitter<Array<string>>();
  protected readonly PresetDateRangeComponent = PresetDateRangeComponent;
  protected readonly actions = actions;

  constructor(private dateAdapter: DateAdapter<any>) {
    this.dateAdapter.setLocale("en-CA");
  }

  /*TODO @Sinan : Problem is when date selection is applied the method is being called as many times as the date changes. once date changes , called once and both date changes , called twice */
  dateRangeChange = (dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) => {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.callDateChange(dateRangeStart, dateRangeEnd);
    }, 1000);
  };

  callDateChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if (dateRangeStart.value !== "" && dateRangeEnd.value !== "") {
      this.dateChangeEvent.emit([dateRangeStart.value, dateRangeEnd.value]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultDates) {
      this.range.controls.start.setValue(this.defaultDates[0]);
      this.range.controls.end.setValue(this.defaultDates[1]);
    }
  }
}
