<div class="tw-mb-6 tw-w-full" (click)="toggleExpand(0)">
  <div class="tw-mb-2 tw-flex tw-cursor-pointer tw-items-center tw-justify-center">
    <div class="tw-w-full tw-rounded-lg tw-p-2 tw-shadow-md">
      <div class="tw-flex tw-justify-between">
        <div class="tw-mb-4 tw-text-lg tw-font-semibold">
          {{ this.hubTotalsDate }}
        </div>
        <div class="tw-hidden tw-scale-75 2xl:tw-block">
          <svg class="tw-h-7 tw-w-7">
            <use *ngIf="panelExpanded[0]" xlink:href="images/sprite.svg#bg-up-arrow" />
            <use *ngIf="!panelExpanded[0]" xlink:href="images/sprite.svg#bg-down-arrow" />
          </svg>
        </div>
      </div>

      <div
        class="tw-mt-3 tw-mb-4 tw-flex tw-gap-3 lg:tw-flex-col"
        [ngClass]="{
          'tw-justify-around': !this.showBalanceDetails() || this.showScenarioDetails()
        }"
      >
        <div class="tw-rounded-lg lg:tw-w-full" *ngIf="this.showBalanceDetails()">
          <div class="tw-mt-3.5 tw-flex tw-gap-1 2xl:tw-w-full">
            <div class="tw-flex tw-flex-col tw-items-center 2xl:tw-hidden">
              <div class="tw-flex tw-cursor-pointer tw-select-none">
                <svg class="tw-h-7 tw-w-7">
                  <use *ngIf="panelExpanded[0]" xlink:href="images/sprite.svg#up-arrow" />
                  <use *ngIf="!panelExpanded[0]" xlink:href="images/sprite.svg#down-arrow" />
                </svg>
              </div>
            </div>
            <svg
              class="tw-mt-2 2xl:tw-hidden"
              *ngIf="panelExpanded[0]"
              width="3"
              height="84"
              viewBox="0 0 3 84"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="2"
                height="84"
                rx="1"
                transform="matrix(1 0 0 -1 0.818115 84)"
                fill="#00C4C4"
              />
            </svg>

            <div class="tw-ml-10 2xl:tw-ml-0 2xl:tw-w-full">
              <div class="tw-flex tw-justify-between">
                <div class="tw-font-montserrat tw-text-base tw-font-medium tw-text-neutral-800">
                  Balance
                </div>

                <div class="tw-relative tw-w-full tw-rounded-lg tw-pl-1">
                  <div
                    class="tw-ml-3 tw-flex tw-w-auto tw-items-center tw-justify-end tw-bg-neutral-50"
                  >
                    <div
                      class="tw-px-l tw-whitespace-nowrap tw-rounded-lg tw-font-roboto tw-text-base tw-font-semibold tw-tracking-tighter"
                    >
                      {{ this.hudTotalsBalance }} {{ this.userCurrency }}
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="panelExpanded[0]" class="tw-mt-3 tw-flex-col tw-rounded-lg tw-px-1">
                <div *ngFor="let account of this.hudAccountsBalanceData">
                  <div class="tw-flex tw-items-center tw-justify-between">
                    <div>{{ account.accountName }}:</div>
                    <div
                      class="tw-ml-4 tw-whitespace-nowrap tw-text-right tw-font-roboto tw-tracking-tighter"
                    >
                      {{ account.accountBalance }} {{ account.accountNormalisedCurrency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-rounded-lg lg:tw-w-full" *ngIf="this.showScenarioDetails()">
          <div class="tw-mt-3.5 tw-flex tw-gap-1 2xl:tw-w-full">
            <div class="tw-flex tw-flex-col tw-items-center 2xl:tw-hidden">
              <div class="tw-flex tw-cursor-pointer tw-select-none">
                <svg class="tw-h-7 tw-w-7">
                  <use *ngIf="panelExpanded[0]" xlink:href="images/sprite.svg#up-arrow" />
                  <use *ngIf="!panelExpanded[0]" xlink:href="images/sprite.svg#down-arrow" />
                </svg>
              </div>
            </div>
            <svg
              class="tw-mt-2 2xl:tw-hidden"
              *ngIf="panelExpanded[0]"
              width="3"
              height="84"
              viewBox="0 0 3 84"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="2"
                height="84"
                rx="1"
                transform="matrix(1 0 0 -1 0.818115 84)"
                fill="#00C4C4"
              />
            </svg>
            <div class="tw-ml-10 2xl:tw-ml-0 2xl:tw-w-full">
              <div class="tw-flex">
                <div
                  class="tw-font-montserrat tw-whitespace-nowrap tw-text-base tw-font-semibold tw-text-neutral-800"
                >
                  {{ "hudBalanceLabel1" | i18n }}
                </div>

                <div class="tw-relative tw-w-full tw-pl-1">
                  <div
                    class="tw-ml-3 tw-flex tw-w-auto tw-items-center tw-justify-end tw-rounded-lg tw-bg-neutral-50"
                  >
                    <div
                      class="tw-whitespace-nowrap tw-rounded-lg tw-px-1 tw-font-roboto tw-text-base tw-font-semibold tw-tracking-tighter"
                    >
                      <!--                    todo:should be in the scenario view only-->
                      {{ this.hudTotalsScenario1 }} {{ this.userCurrency }}
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="panelExpanded[0]" class="tw-mt-3 tw-flex-col tw-rounded-lg tw-px-1">
                <div *ngFor="let account of this.hudScenario1BalanceData">
                  <div class="tw-flex tw-items-center tw-justify-between">
                    <div>{{ account.accountName }}:</div>
                    <div
                      class="tw-ml-4 tw-whitespace-nowrap tw-text-right tw-font-roboto tw-tracking-tighter"
                    >
                      {{ account.accountBalance }} {{ account.accountNormalisedCurrency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-rounded-lg lg:tw-w-full" *ngIf="this.showScenarioDetails()">
          <div class="tw-mt-3.5 tw-flex 2xl:tw-w-full">
            <!--            <div class="tw-flex tw-flex-col tw-items-center">-->
            <!--                            <div (click)="toggleExpand(1)" class="tw-flex-auto tw-cursor-pointer tw-select-none">-->
            <!--                              <img-->
            <!--                                class=""-->
            <!--                                src="../../../../images/icons/hud/upArrow.svg"-->
            <!--                                alt="plus"-->
            <!--                                *ngIf="!panelExpanded[1]"-->
            <!--                              />-->
            <!--                              <img-->
            <!--                                class=""-->
            <!--                                src="../../../../images/icons/hud/downArrow.svg"-->
            <!--                                alt="minus"-->
            <!--                                *ngIf="panelExpanded[1]"-->
            <!--                              />-->
            <!--                            </div>-->
            <!--                            <svg-->
            <!--                              class=""-->
            <!--                              *ngIf="panelExpanded[0]"-->
            <!--                              width="3"-->
            <!--                              height="64"-->
            <!--                              viewBox="0 0 3 84"-->
            <!--                              fill="none"-->
            <!--                              xmlns="http://www.w3.org/2000/svg"-->
            <!--                            >-->
            <!--                              <rect-->
            <!--                                width="2"-->
            <!--                                height="84"-->
            <!--                                rx="1"-->
            <!--                                transform="matrix(1 0 0 -1 0.818115 84)"-->
            <!--                                fill="#00C4C4"-->
            <!--                              />-->
            <!--                            </svg>-->
            <!--            </div>-->

            <div class="2xl:tw-w-full">
              <div class="tw-flex tw-justify-between 2xl:tw-w-full">
                <div
                  class="tw-font-montserrat tw-whitespace-nowrap tw-text-base tw-font-semibold tw-text-neutral-800"
                >
                  {{ "hudBalanceLabel2" | i18n }}
                </div>
                <div class="tw-relative tw-w-full tw-pl-1">
                  <div
                    class="tw-ml-3 tw-flex tw-w-auto tw-items-center tw-justify-end tw-rounded-lg tw-bg-neutral-50"
                  >
                    <div
                      class="tw-whitespace-nowrap tw-rounded-lg tw-px-1 tw-font-roboto tw-text-base tw-font-semibold tw-tracking-tighter"
                    >
                      {{ this.hudTotalsScenario2 }} {{ this.userCurrency }}
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="panelExpanded[0]" class="tw-mt-3 tw-flex-col tw-rounded-lg tw-px-1">
                <div *ngFor="let account of this.hudScenario2BalanceData">
                  <div class="tw-flex tw-items-center tw-justify-between">
                    <div>{{ account.accountName }}:</div>
                    <div
                      class="tw-ml-4 tw-whitespace-nowrap tw-text-right tw-font-roboto tw-tracking-tighter"
                    >
                      {{ account.accountBalance }} {{ account.accountNormalisedCurrency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-rounded-lg lg:tw-w-full" *ngIf="this.showScenarioDetails()">
          <div class="tw-flex 2xl:tw-w-full">
            <!--            <div class="tw-flex tw-flex-col tw-items-center">-->
            <!--                            <div (click)="toggleExpand(2)" class="tw-flex-auto tw-cursor-pointer tw-select-none">-->
            <!--                              <img-->
            <!--                                class=""-->
            <!--                                src="../../../../images/icons/hud/upArrow.svg"-->
            <!--                                alt="plus"-->
            <!--                                *ngIf="!panelExpanded[2]"-->
            <!--                              />-->
            <!--                              <img-->
            <!--                                class=""-->
            <!--                                src="../../../../images/icons/hud/downArrow.svg"-->
            <!--                                alt="minus"-->
            <!--                                *ngIf="panelExpanded[2]"-->
            <!--                              />-->
            <!--                            </div>-->
            <!--                            <svg-->
            <!--                              class=""-->
            <!--                              *ngIf="panelExpanded[0]"-->
            <!--                              width="3"-->
            <!--                              height="64"-->
            <!--                              viewBox="0 0 3 84"-->
            <!--                              fill="none"-->
            <!--                              xmlns="http://www.w3.org/2000/svg"-->
            <!--                            >-->
            <!--                              <rect-->
            <!--                                width="2"-->
            <!--                                height="84"-->
            <!--                                rx="1"-->
            <!--                                transform="matrix(1 0 0 -1 0.818115 84)"-->
            <!--                                fill="#00C4C4"-->
            <!--                              />-->
            <!--                            </svg>-->
            <!--            </div>-->

            <div class="2xl:tw-w-full">
              <div class="tw-mt-3.5 tw-flex tw-justify-between 2xl:tw-w-full">
                <div
                  class="tw-font-montserrat tw-whitespace-nowrap tw-text-base tw-font-semibold tw-text-neutral-800"
                >
                  {{ "hudBalanceLabel3" | i18n }}
                </div>
                <div class="tw-relative tw-w-full tw-pl-1">
                  <div
                    class="tw-ml-3 tw-flex tw-w-auto tw-items-center tw-justify-end tw-rounded-lg tw-bg-neutral-50"
                  >
                    <div
                      class="tw-whitespace-nowrap tw-rounded-lg tw-px-1 tw-font-roboto tw-text-base tw-font-semibold tw-tracking-tighter"
                    >
                      {{ this.hudTotalsScenario3 }} {{ this.userCurrency }}
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="panelExpanded[0]" class="tw-mt-3 tw-flex-col tw-rounded-lg tw-px-1">
                <div *ngFor="let account of this.hudScenario3BalanceData">
                  <div class="tw-flex tw-items-start tw-justify-between">
                    <div class="tw-max-w-[132.7px]">{{ account.accountName }}:</div>
                    <div
                      class="tw-ml-4 tw-whitespace-nowrap tw-text-right tw-font-roboto tw-tracking-tighter"
                    >
                      {{ account.accountBalance }} {{ account.accountNormalisedCurrency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
