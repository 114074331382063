import { Injector } from "@angular/core";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import { BookResponse } from "@bitwarden/web-vault/app/models/data/response/book.response";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import {
  AccountCreationParameters,
  AccountCreationOutput,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";

export class AccountCreationAction extends EstimateActionData {
  private _mockAccount: Book;

  parameters: AccountCreationParameters;
  createdRecords: AccountCreationOutput;

  constructor(response: EstimateActionResponse) {
    super(response);
  }

  async run(
    parameters: AccountCreationParameters,
    injector: Injector
  ): Promise<AccountCreationOutput> {
    await super.run(parameters, injector);

    const accounts: Array<Book> = [];

    // create the mock account
    this.createMockAccount();

    accounts.push(this._mockAccount);

    // create the output to return
    this.createdRecords = {
      accounts: accounts,
    };

    return this.createdRecords;
  }

  // todo this should be moved in the TransactionResponse class so it can get updated if the model changes
  private createMockAccount() {
    //UPDATE : institutionLink is added
    this._mockAccount = new Book(
      new BookResponse({
        __v: 1,
        institutionLink: {
          institutionId: this.parameters.institution.id,
          institutionAccountId: this.parameters.newInstitutionalAccount.id,
        },
        name: this.parameters.accountName,
        currency: this.parameters.currency,
      })
    );
  }
}
