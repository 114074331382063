import { Component, DoCheck, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import {
  DeviceDetection,
  DeviceDetector,
} from "@bitwarden/web-vault/app/shared/utils/device-detector";
import { StepsService } from "@bitwarden/web-vault/app/components/account-wizard-stepper/wizard-stepper-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-layout",
  templateUrl: "user-layout.component.html",
})
export class UserLayoutComponent implements OnInit, DoCheck, OnDestroy {
  isGlossMenuOpen$ = this.glossMenuService.isGlossMenuOpen$;
  @ViewChild("glossMenu") glossMenu: MatSidenav;
  private ngUnsubscribe = new Subject<void>();
  deviceDetector: DeviceDetection;
  helpOpen = false;
  stage1Complete = false;
  stage2Complete = false;
  stage3Complete = false;
  hasNoAccounts = false;
  hasNoTransaction = false;
  isWizardInitialise = false;

  constructor(
    private glossMenuService: SideMenuService,
    private blobbyService: BlobbyService,
    private router: Router,
    private stepperService: StepsService
  ) {
    this.deviceDetector = new DeviceDetector();
  }
  async ngOnInit() {
    document.body.classList.remove("layout_frontend");
    if (this.glossMenu) {
      this.glossMenu.openedChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isOpened) => {
        {
          this.glossMenuService.setGlossMenuOpenState(isOpened);
        }
      });
    }

    this.glossMenuService.shouldRunWizard$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((shouldRunWizard) => {
        if (shouldRunWizard) {
          this.router.navigate(["/dashboard"]).then(() => {
            this.isWizardInitialise = false;
            this.stepperService.reinitializeSteps();
            this.resetSteps();
            this.openWizard();
          });
        }
      });
  }

  resetSteps() {
    this.stage1Complete = false;
    this.stage2Complete = false;
    this.stage3Complete = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngDoCheck() {
    if (this.blobbyService.getInitialised() && !this.isWizardInitialise) {
      const itemCountInstance = this.blobbyService.getItemCountService().getItemCountInstance();
      this.hasNoTransaction = itemCountInstance.getTotalTransaction() === 0;
      this.hasNoAccounts = itemCountInstance.getTotalAccount() === 0;

      if (this.hasNoAccounts) {
        localStorage.removeItem("wizardStepper");
      }
      this.isWizardInitialise = true;
      this.openWizard();
    }
  }

  handleHelpBoxOpen($event: boolean) {
    this.helpOpen = $event;
  }

  handleStep1($event: boolean) {
    this.stage1Complete = $event;
  }

  handleStep2($event: boolean) {
    this.stage2Complete = $event;
  }

  handleStep3($event: boolean) {
    this.stage3Complete = $event;
  }

  openWizard() {
    const wizardMemory = localStorage.getItem("wizardStepper");
    if (this.hasNoAccounts || this.hasNoTransaction || wizardMemory) {
      if (
        this.stage1Complete === false ||
        this.stage2Complete === false ||
        this.stage3Complete === false
      ) {
        return true;
      }
    }
    return false;
  }
}
