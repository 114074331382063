import { Component, Input, OnInit } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";

@Component({
  selector: "app-projections-table-card",
  templateUrl: "./projections-table-card.component.html",
})
export class ProjectionsTableCardComponent implements OnInit {
  @Input() title: string;
  @Input() projectedTransactions: Array<Transaction>;
  @Input() defaultStartDate: string;
  @Input() defaultEndDate: string;
  @Input() defaultGranularity: string;

  async ngOnInit(): Promise<void> {
    return null;
  }
}
