import { Injectable, Injector } from "@angular/core";
import _ from "lodash";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { BlobbyResponse } from "@bitwarden/common/models/response/blobby.response";
import { ErrorResponse } from "@bitwarden/common/models/response/error.response";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Preference } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { ImportHandler } from "@bitwarden/web-vault/app/models/data/import-handler.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import { EstimateResponse } from "@bitwarden/web-vault/app/models/data/response/estimate.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { SymbolInfoData } from "@bitwarden/web-vault/app/models/data/symbol-info.data";
import { SymbolData } from "@bitwarden/web-vault/app/models/data/symbol.data";
import { ReferenceDataUtils } from "@bitwarden/web-vault/app/models/data/utils/reference-data.utils";
import {
  crudFlag,
  crudFlagDataType,
  DataServiceAbstractionTypeArray,
  GlossEncryptedDataType,
} from "@bitwarden/web-vault/app/models/enum/glossEncryptedDataType";
import { BasiqInstitution } from "@bitwarden/web-vault/app/models/types/basiq.types";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";
import { spaceFreeString } from "@bitwarden/web-vault/app/shared/utils/helper.string";

import { Book } from "../../models/data/blobby/book.data";
import { BlobbyDataTypeEnum } from "../../models/enum/blobbyDataTypeEnum";

export type BlobbyDataTypeShort =
  | BlobbyDataTypeEnum.Classification
  | BlobbyDataTypeEnum.Category
  | BlobbyDataTypeEnum.ReferenceData
  | BlobbyDataTypeEnum.Transaction
  | BlobbyDataTypeEnum.Institution
  | BlobbyDataTypeEnum.Preference
  | BlobbyDataTypeEnum.Book
  | BlobbyDataTypeEnum.Estimate
  | BlobbyDataTypeEnum.EstimateGroup
  | BlobbyDataTypeEnum.SourceTransaction
  | BlobbyDataTypeEnum.SourceCategory
  | BlobbyDataTypeEnum.SourceBook
  | BlobbyDataTypeEnum.Connector;

export type queryFilter = {
  (item: GlossEncryptedDataType): boolean;
};

@Injectable({
  providedIn: "root",
})
export class DataRepositoryService {
  private transactionNormalizeService: TransactionNormalizeService;
  constructor(
    private blobbyService: BlobbyService,
    private globalService: GlobalService,
    private logger: LogService,
    private injector: Injector,
    private perfService: PerformanceService,
    private apiService: ApiService
  ) {}

  /**
   * @throws an error
   * @param item
   * @param type
   */
  private async create(
    item: GlossEncryptedDataType,
    type: BlobbyDataTypeShort
  ): Promise<GlossEncryptedDataType> {
    try {
      const itemIsSafe = await this.checkItem(item);
      if (itemIsSafe) {
        const addedItem = await this.blobbyService.create(type, item);
        if (this.isInstanceDataServiceAbstractionType(addedItem)) {
          return addedItem;
        } else {
          throw new Error("DataRepositoryService.saveBook blobbyService.save return undefined");
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  private async bulkCreate(
    type: BlobbyDataTypeShort,
    items: GlossEncryptedDataType[],
    reloadInMemoryAfterSave = true
  ) {
    try {
      await this.blobbyService.save(type, items, reloadInMemoryAfterSave);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  private async update(
    update: GlossEncryptedDataType,
    type: BlobbyDataTypeEnum
  ): Promise<GlossEncryptedDataType> {
    try {
      const itemIsSafe =
        type !== BlobbyDataTypeEnum.Institution
          ? await this.checkItem(update, crudFlag.update)
          : true;
      if (itemIsSafe) {
        const updatedItem = await this.blobbyService.update(type, update);
        if (this.isInstanceDataServiceAbstractionType(updatedItem)) {
          return updatedItem;
        } else {
          throw new Error("DataRepositoryService.saveBook blobbyService.save return undefined");
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  private async purge(type: BlobbyDataTypeEnum): Promise<boolean> {
    try {
      return await this.blobbyService.purge(type);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  protected async delete(
    deleteItem: GlossEncryptedDataType,
    type: BlobbyDataTypeEnum
  ): Promise<boolean> {
    try {
      return await this.blobbyService.delete(type, deleteItem);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async getAllTransactions(runValuation = true) {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Transaction);

    if (runValuation) {
      this.transactionNormalizeService = this.injector.get(TransactionNormalizeService);
      this.perfService.mark("getAllTransactions::normalizeImportedTransaction");
      for (const transaction of blobbyResponse.transactions) {
        await this.transactionNormalizeService.normalizeImportedTransaction(transaction);
      }
      this.perfService.markEnd();
    }

    return blobbyResponse.transactions;
  }

  async getAllTransactionsBySymbol(symbol: string) {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Transaction);

    const filteredResponse = blobbyResponse.transactions.filter(
      (transaction) => transaction.quantity.actualQuantity.symbol === symbol
    );

    this.perfService.mark("getAllTransactions::getAllTransactionsBySymbol");
    for (const transaction of filteredResponse) {
      await this.transactionNormalizeService.normalizeImportedTransaction(transaction);
    }
    this.perfService.markEnd();

    return filteredResponse;
  }

  async getAllTransactionsByAccount(accountID: string) {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Transaction);

    return blobbyResponse.transactions.filter((transaction) => transaction.accountId === accountID);
  }

  async getAllTransactionsBySymbolTillDate(symbol: string, tillDate: string) {
    const symbolTransactions = await this.getAllTransactionsBySymbol(symbol);
    const tillDateStamp = new Date(tillDate).getTime();
    return symbolTransactions.filter(
      (transaction) => new Date(transaction.transactionDate.date).getTime() <= tillDateStamp
    );
  }

  async getScenarioGroupAccounts(scenarioGroup: ScenarioGroup) {
    const symbol = scenarioGroup.symbol;
    const tillDate = scenarioGroup.anchorPoint.date;
    const symbolTransactions = await this.getAllTransactionsBySymbolTillDate(symbol, tillDate);
    return this.getAccountsFromTransactions(symbolTransactions, []);
  }

  async getAccountsFromTransactions(
    transactions: Transaction[],
    accounts: Book[]
  ): Promise<Book[]> {
    if (transactions.length === 0) {
      return accounts;
    }
    const account = await this.getBookById(transactions[0].accountId);
    accounts.push(account);
    const restOfTransactions = transactions.filter(
      (transaction) => transaction.accountId !== transactions[0].accountId
    );

    return await this.getAccountsFromTransactions(restOfTransactions, accounts);
  }

  async getAllEstimates() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Estimate);
    return blobbyResponse.estimates;
  }

  async getAllScenarioGroups() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.ScenarioGroup);
    return blobbyResponse.scenarioGroups;
  }

  async getAllEstimateGroups() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.EstimateGroup);
    return blobbyResponse.estimateGroups;
  }

  async getAllEstimatesOfGroup(groupId: string): Promise<EstimateActionData[]> {
    const group = await this.getEstimateGroupById(groupId);
    return groupId ? group.estimateActions : [];
  }

  async getAllCategories() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Category);
    return blobbyResponse.categories;
  }

  async getAllSourceCategories(): Promise<SourceCategory[]> {
    const blobbyResponse: BlobbyResponse = await this.getAll(BlobbyDataTypeEnum.SourceCategory);
    return blobbyResponse.sourceCategories;
  }

  async getAllClassifications() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Classification);
    return blobbyResponse.classifications;
  }

  async getAllInstitutions() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Institution);
    return blobbyResponse.institutions;
  }

  async getAllBasiqBasedInstitutions() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Institution);
    return blobbyResponse.institutions.filter((insto: Institution) => !!insto.basiqId);
  }

  async getInstitutionByBasiqInstoId(basiqInstoId: string) {
    const allInstos = await this.getAllInstitutions();
    return allInstos.find((insto) => insto.basiqId === basiqInstoId);
  }

  async getAllConnectors() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Connector);
    return blobbyResponse.connector;
  }

  async getAllSymbols() {
    const symbols: SymbolData[] = [];
    const symbolIsLoaded: { [index: string]: boolean } = {};
    const transactions = await this.getAllTransactions();

    transactions.forEach((t) => {
      const glossQuantity = new GlossQuantity().setToQuantityObj(t.quantity);
      const symbol = glossQuantity.actualQuantity.symbol;
      if (symbol) {
        const s = new SymbolData(symbol, t.accountId);
        if (!symbolIsLoaded[s.id]) {
          symbols.push(s);
          symbolIsLoaded[s.id] = true;
        }
      }
    });

    return symbols;
  }

  async querySymbols(where: queryFilter) {
    // todo this is not the final implementation but just a quick workaround
    const allSymbols = await this.getAllSymbols();
    return allSymbols.filter(where);
  }

  async getAllPreferences() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Preference);
    return blobbyResponse.preferences[0];
  }

  async createCategory(newCat: Category): Promise<Category> {
    return <Category>await this.create(newCat, BlobbyDataTypeEnum.Category);
  }
  async getCategoryByName(catName: string): Promise<Category> {
    const categories = await this.getAllCategories();
    return categories.find((cat) => cat.name === catName);
  }

  async getCategoryById(catId: string): Promise<Category> {
    const categories = await this.getAllCategories();
    return categories.find((cat) => cat.id === catId);
  }

  async getSourceCategoryByName(sourceCatName: string): Promise<SourceCategory> {
    const categories = await this.getAllSourceCategories();
    return categories.find(
      (sourceCat) =>
        sourceCat.name.toLowerCase().replace(/\s/g, "") ===
        sourceCatName.toLowerCase().replace(/\s/g, "")
    );
  }

  async isCategoryExists(catName: string): Promise<boolean> {
    return !!(await this.getCategoryByName(catName));
  }

  async createSourceBook(sourceBook: SourceBook): Promise<SourceBook> {
    return <SourceBook>await this.create(sourceBook, BlobbyDataTypeEnum.SourceBook);
  }

  async createSourceCategory(sourceCategory: SourceCategory): Promise<SourceCategory> {
    return <SourceCategory>await this.create(sourceCategory, BlobbyDataTypeEnum.SourceCategory);
  }

  async deleteSourceBook(sourceBook: SourceBook) {
    return await this.delete(sourceBook, BlobbyDataTypeEnum.SourceBook);
  }

  async createClassification(newCls: Classification): Promise<Classification> {
    return <Classification>await this.create(newCls, BlobbyDataTypeEnum.Classification);
  }

  async createInstitution(newInsts: Institution): Promise<Institution> {
    return <Institution>await this.create(newInsts, BlobbyDataTypeEnum.Institution);
  }

  async createConnector(connector: Connector): Promise<Connector> {
    return <Connector>await this.create(connector, BlobbyDataTypeEnum.Connector);
  }

  async createPreference(preference: Preference): Promise<Preference> {
    return <Preference>await this.create(preference, BlobbyDataTypeEnum.Preference);
  }

  async createEstimateToNewGroup(estimate: EstimateActionData): Promise<Estimate> {
    const newEstimateGroup = new Estimate(new EstimateResponse(""));
    newEstimateGroup.name = estimate.name;
    newEstimateGroup.estimateActions = [];

    const createdEstimateGroup = <Estimate>(
      await this.create(newEstimateGroup, BlobbyDataTypeEnum.EstimateGroup)
    );
    estimate.groupId = createdEstimateGroup.id;
    const createdEstimate = <EstimateActionData>(
      await this.create(estimate, BlobbyDataTypeEnum.Estimate)
    );
    createdEstimateGroup.estimateActions = [createdEstimate];

    const updateGroup = <Estimate>(
      await this.update(createdEstimateGroup, BlobbyDataTypeEnum.EstimateGroup)
    );

    await this.getAllEstimateGroups();

    return updateGroup;
  }
  async createEstimateToExistingGroup(estimate: EstimateActionData): Promise<Estimate> {
    const createdEstimate = <EstimateActionData>(
      await this.create(estimate, BlobbyDataTypeEnum.Estimate)
    );
    const updateEstimateGroup = await this.getEstimateGroupById(estimate.groupId);
    const newEstimatesArray: EstimateActionData[] = [];
    updateEstimateGroup.estimateActions.forEach((es) => {
      const estimateDate: EstimateActionData = new EstimateActionData(
        new EstimateActionResponse(es)
      );
      newEstimatesArray.push(estimateDate);
    });

    newEstimatesArray.push(createdEstimate);
    updateEstimateGroup.estimateActions = newEstimatesArray;
    return <Estimate>await this.update(updateEstimateGroup, BlobbyDataTypeEnum.EstimateGroup);
  }

  async deleteTransaction(transaction: Transaction) {
    return await this.delete(transaction, BlobbyDataTypeEnum.Transaction);
  }

  async bulkDeleteTransaction(transactions: Transaction[]) {
    return await this.bulkDelete(BlobbyDataTypeEnum.Transaction, transactions);
  }
  async bulkDeleteSourceTransaction(transactions: SourceTransaction[]) {
    return await this.bulkDelete(BlobbyDataTypeEnum.SourceTransaction, transactions);
  }
  async bulkDelete(blobbyType: BlobbyDataTypeEnum, items: GlossEncryptedDataType[]) {
    return await this.blobbyService.bulkDelete(blobbyType, items, [], []);
  }

  async bulkDeleteConnectors(connectors: Connector[]) {
    return await this.bulkDelete(BlobbyDataTypeEnum.Connector, connectors);
  }

  async deleteSourceTransaction(sourceTransaction: SourceTransaction) {
    return await this.delete(sourceTransaction, BlobbyDataTypeEnum.SourceTransaction);
  }

  async deleteCategory(category: Category): Promise<boolean> {
    return await this.delete(category, BlobbyDataTypeEnum.Category);
  }

  async deleteInstitution(institution: Institution): Promise<boolean> {
    return await this.delete(institution, BlobbyDataTypeEnum.Institution);
  }

  async deleteClassification(classification: Classification): Promise<boolean> {
    return await this.delete(classification, BlobbyDataTypeEnum.Classification);
  }

  async deleteEstimateGroup(estimateGroup: Estimate): Promise<boolean> {
    return await this.delete(estimateGroup, BlobbyDataTypeEnum.EstimateGroup);
  }
  async deleteEstimate(estimate: Estimate): Promise<boolean> {
    return await this.delete(estimate, BlobbyDataTypeEnum.Estimate);
    /*    if (estimateDeleted) {
      // update estimateGroup
      const estimateGroup = await this.getEstimateGroupById(estimate.groupId);
      estimateGroup.estimateActions = estimateGroup.estimateActions.filter((es) => {
        const estimateData = new EstimateActionData(new EstimateActionResponse(es));
        return estimateData.id !== estimate.id;
      });

      const estimateGroupUpdated = await this.updateEstimateGroup(estimateGroup);
      if (estimateGroupUpdated instanceof Estimate) {
        return estimateDeleted;
      }
    }*/
  }

  async deleteConnector(connector: Connector): Promise<boolean> {
    return await this.delete(connector, BlobbyDataTypeEnum.Connector);
  }

  /**
   * @throws DataRepositoryService error
   * @param catToBeUpdated
   */
  async updateCategory(catToBeUpdated: Category): Promise<Category> {
    try {
      return <Category>await this.update(catToBeUpdated, BlobbyDataTypeEnum.Category);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updatePreference(pref: Preference): Promise<Preference> {
    try {
      return <Preference>await this.update(pref, BlobbyDataTypeEnum.Preference);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateClassification(updateClassification: Classification): Promise<Classification> {
    try {
      return <Classification>(
        await this.update(updateClassification, BlobbyDataTypeEnum.Classification)
      );
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateInstitution(updateInstitution: Institution): Promise<Institution> {
    try {
      return <Institution>await this.update(updateInstitution, BlobbyDataTypeEnum.Institution);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateConnector(connector: Connector): Promise<Connector> {
    try {
      return <Connector>await this.update(connector, BlobbyDataTypeEnum.Connector);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateEstimate(updateEstimate: Estimate): Promise<Estimate> {
    try {
      return <Estimate>await this.update(updateEstimate, BlobbyDataTypeEnum.Estimate);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateEstimateGroup(updateEstimateGroup: Estimate): Promise<Estimate> {
    try {
      return <Estimate>await this.update(updateEstimateGroup, BlobbyDataTypeEnum.EstimateGroup);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async createEstimateGroup(estimateGroup: Estimate): Promise<Estimate> {
    return <Estimate>await this.create(estimateGroup, BlobbyDataTypeEnum.EstimateGroup);
  }

  async createEstimate(estimate: Estimate): Promise<Estimate> {
    return <Estimate>await this.create(estimate, BlobbyDataTypeEnum.Estimate);
  }

  isInstanceDataServiceAbstractionType(obj: unknown): obj is GlossEncryptedDataType {
    return (
      obj instanceof Category ||
      obj instanceof ReferenceData ||
      obj instanceof Classification ||
      obj instanceof Institution ||
      obj instanceof Transaction ||
      obj instanceof Book ||
      obj instanceof EstimateActionData ||
      obj instanceof Estimate ||
      obj instanceof SymbolInfoData ||
      obj instanceof SourceTransaction ||
      obj instanceof SourceBook ||
      obj instanceof ImportHandler ||
      obj instanceof SourceCategory ||
      obj instanceof Preference ||
      obj instanceof Connector
    );
  }

  async importTransactions(transactions: Transaction[]): Promise<boolean> {
    try {
      await this.bulkCreate(BlobbyDataTypeEnum.Transaction, transactions, false);
      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }

    return true;
  }

  async importCategories(transactions: Transaction[]): Promise<boolean> {
    try {
      await this.bulkCreate(BlobbyDataTypeEnum.Transaction, transactions, false);
      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }

    return true;
  }

  async refreshInMemoryData() {
    await this.blobbyService.refresh();
  }

  async importReferenceData(newReferenceData: ReferenceData[]): Promise<boolean> {
    try {
      const existingReferenceData = await this.getAllReferenceData();
      const { newRecords, importedReferenceData } = ReferenceDataUtils.processNewReferences(
        newReferenceData,
        existingReferenceData
      );

      if (!_.isEmpty(newRecords)) {
        await this.bulkCreate(BlobbyDataTypeEnum.ReferenceData, newRecords, false);
      }

      if (importedReferenceData.length > 0) {
        const recordsToUpdate = ReferenceDataUtils.processUpdateReferences(
          importedReferenceData,
          existingReferenceData
        );
        for (const record of recordsToUpdate) {
          await this.update(record, BlobbyDataTypeEnum.ReferenceData);
        }
      }

      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }

    return true;
  }

  async getAllReferenceData() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.ReferenceData);
    return blobbyResponse.references;
  }

  async createReferenceData(newReferenceData: ReferenceData): Promise<ReferenceData> {
    return <ReferenceData>await this.create(newReferenceData, BlobbyDataTypeEnum.ReferenceData);
  }

  // BOOK Method
  async createBook(book: Book): Promise<Book> {
    return <Book>await this.create(book, BlobbyDataTypeEnum.Book);
  }
  async createTransaction(transaction: Transaction): Promise<Transaction> {
    return <Transaction>await this.create(transaction, BlobbyDataTypeEnum.Transaction);
  }

  async createSourceTransaction(sourceTransaction: SourceTransaction): Promise<SourceTransaction> {
    return <SourceTransaction>(
      await this.create(sourceTransaction, BlobbyDataTypeEnum.SourceTransaction)
    );
  }
  async bulkCreateSourceTransaction(sourceTransactions: SourceTransaction[]) {
    try {
      await this.bulkCreate(BlobbyDataTypeEnum.SourceTransaction, sourceTransactions, false);
      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }
  }
  async bulkCreateCategories(categories: Category[]) {
    try {
      await this.bulkCreate(BlobbyDataTypeEnum.Category, categories, false);
      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }
  }

  async bulkCreateInstitutions(institutions: Institution[]) {
    try {
      await this.bulkCreate(BlobbyDataTypeEnum.Institution, institutions, false);
      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }
  }

  async bulkCreateSourceCategories(sourceCategories: SourceCategory[]) {
    try {
      await this.bulkCreate(BlobbyDataTypeEnum.SourceCategory, sourceCategories, false);
      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }
  }

  async bulkCreateClassifications(classifications: Classification[]) {
    try {
      await this.bulkCreate(BlobbyDataTypeEnum.Classification, classifications, false);
      await this.refreshInMemoryData();
    } catch (e) {
      this.logger.error(e);
      this.globalService.showMessage("error", "Import failed", "Import failed");
      return false;
    }
  }

  async updateBook(book: Book): Promise<Book> {
    try {
      return <Book>await this.update(book, BlobbyDataTypeEnum.Book);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateTransaction(transaction: Transaction): Promise<Transaction> {
    try {
      return <Transaction>await this.update(transaction, BlobbyDataTypeEnum.Transaction);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }
  async deleteBook(book: Book) {
    return await this.delete(book, BlobbyDataTypeEnum.Book);
  }
  async getAllBooks() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Book);
    return blobbyResponse.books;
  }
  async getAllBasiqBasedBooks(): Promise<Book[]> {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Book);
    return blobbyResponse.books.filter((book) => {
      return book.origin === Origin.basiq;
    });
  }

  async getAllSourceBooks(): Promise<SourceBook[]> {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.SourceBook);
    return blobbyResponse.sourceBooks;
  }

  async getBookByName(bookName: string) {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Book);
    return blobbyResponse.books.find((book) => book.name === bookName);
  }

  async getBookById(bookId: string) {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Book);
    return blobbyResponse.books.find((book) => book.id === bookId);
  }

  async getBookBySymbol(symbol: string) {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.Book);
    return blobbyResponse.books.find((book) => book.currency === symbol);
  }

  async getAllSourceTransactions() {
    const blobbyResponse = await this.getAll(BlobbyDataTypeEnum.SourceTransaction);
    return blobbyResponse.sourceTransactions;
  }

  private async save(
    type: BlobbyDataTypeEnum,
    item: DataServiceAbstractionTypeArray,
    isNew: boolean
  ): Promise<BlobbyResponse> {
    return await this.blobbyService.save(type, item);
  }

  private async getAll(type: BlobbyDataTypeEnum): Promise<BlobbyResponse> {
    // to we need double
    return await this.blobbyService.isInitialised().then(async () => {
      return await this.blobbyService.getAll(type);
    });
  }

  async categoryAlreadyExists(cat: Category) {
    const existingCategories = await this.getAllCategories();
    this.globalService.alreadyExists(cat, existingCategories, "category");
  }
  async classificationAlreadyExists(cat: Classification) {
    const existingClassifications = await this.getAllClassifications();
    this.globalService.alreadyExists(cat, existingClassifications, "classification");
  }
  async institutionAlreadyExists(ins: Institution): Promise<boolean> {
    const existingInstitutions = await this.getAllInstitutions();
    /** institution have a special case for existence check . we check based on both name and country code */
    const elementExists = existingInstitutions.some(
      (institution) =>
        institution.name === ins.name && institution.swift.countryCode === ins.swift.countryCode
    );
    if (elementExists) {
      this.globalService.throwInstitutionExistsError();
      return false;
    }
    return true;
  }
  async bookAlreadyExists(book: Book): Promise<boolean> {
    const existingBooks = await this.getAllBooks();
    /** institution have a special case for existence check . we check based on both name and country code  / */
    // const elementExists = existingBooks.some(
    //   (b) => spaceFreeString(b.name) === spaceFreeString(book.name)
    // );
    const elementExists = existingBooks.some(
      (b) =>
        spaceFreeString(b.name) === spaceFreeString(book.name) &&
        spaceFreeString(b.id) !== spaceFreeString(book.id)
    );

    if (elementExists) {
      this.globalService.throwBookExistsError();
      return true;
    }
    return false;
  }
  async connectorAlreadyExists(connector: Connector): Promise<boolean> {
    const existingConnectors = await this.getAllConnectors();
    const elementExists = existingConnectors.some(
      (c) => spaceFreeString(c.name) === spaceFreeString(connector.name)
    );
    if (elementExists) {
      this.globalService.throwConnectorExistsError();
      return true;
    }
    return false;
  }

  async checkItem(item: GlossEncryptedDataType, flag: crudFlagDataType = null): Promise<boolean> {
    if (item instanceof Category) {
      return await this.checkCategory(item);
    }

    //TODO add check method for Currency/Market Data/Reference Data
    if (item instanceof ReferenceData) {
      return true;
    }

    if (item instanceof Classification) {
      return await this.checkClassification(item);
    }

    if (item instanceof Institution) {
      return await this.checkInstitution(item);
    }

    if (item instanceof Preference) {
      return await this.checkPreference(item);
    }

    if (item instanceof Book) {
      return await this.checkBook(item, flag);
    }

    if (item instanceof EstimateActionData) {
      return await this.checkEstimate(item);
    }

    if (item instanceof Estimate) {
      return await this.checkEstimateGroup(item);
    }

    if (item instanceof Connector) {
      // return this.checkConnector(item);
      return true;
    }

    //TODO add check method for Transaction
    if (item instanceof Transaction) {
      return true;
    }
    //TODO add check method for Source book-renderer
    if (item instanceof SourceBook) {
      return true;
    }
    //TODO add check method for Source book-renderer
    if (item instanceof SourceCategory) {
      return true;
    }

    //TODO add check method for Source transaction
    if (item instanceof SourceTransaction) {
      return true;
    }

    return false;
  }
  async checkCategory(item: Category): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.name);
      await this.categoryAlreadyExists(item);
      return true;
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async checkClassification(item: Classification): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.name);
      await this.classificationAlreadyExists(item);
      return true;
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async checkInstitution(item: Institution): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.name);
      return await this.institutionAlreadyExists(item);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async checkConnector(item: Connector): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.name);
      return await this.connectorAlreadyExists(item);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async checkBasiqInstitution(basiqInstitution: BasiqInstitution): Promise<boolean> {
    try {
      const institutions = await this.getAllInstitutions();
      return institutions.some((institution) => institution.basiqId === basiqInstitution.id);
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async checkPreference(item: Preference): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.baseCurrency);
      this.globalService.checkEmpty(item.dateFormat);
      this.globalService.checkEmpty(item.mode);
      this.globalService.checkEmpty(item.timeZone);
      return true;
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  /** todo: @Sinan is there any reason to create multiple books of the same name*/
  async checkBook(item: Book, flag: crudFlagDataType = null): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.name);
      // if (flag !== crudFlag.update) {
      // commented out so that it should check for duplicate books whether
      const bookExists = await this.bookAlreadyExists(item);
      if (bookExists) {
        return false;
      }
      // }
      // this.globalService.checkEmpty(item.balance.toString());
      //this.globalService.checkEmptyArr(item.defaultClassifications);
      //this.globalService.checkEmptyArr(item.defaultCategories);
      return true;
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async checkEstimate(item: EstimateActionData): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.name);
      return true;
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async checkEstimateGroup(item: Estimate): Promise<boolean> {
    try {
      this.globalService.checkEmpty(item.name);
      return true;
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  // This method is gonna replace this.checkEstimate
  async estimateGroupToSave(item: EstimateActionData): Promise<Estimate[] | false> {
    try {
      this.globalService.checkEmpty(item.name);
      if (item.groupId) {
        return await this.existingGroupCheckEstimate(item);
      } else {
        return await this.newGroupCheckEstimate(item);
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      return false;
    }
  }

  async existingGroupCheckEstimate(newEstimate: EstimateActionData): Promise<Estimate[] | false> {
    const estimateGroups = await this.getAllEstimateGroups();
    return estimateGroups.map((eg) => {
      if (eg.id === newEstimate.groupId) {
        // means newEstimate belongs to this estimateGroup
        eg.estimateActions.push(newEstimate);
      }
      return eg;
    });
  }

  async newGroupCheckEstimate(newEstimate: EstimateActionData): Promise<Estimate[] | false> {
    const estimateGroups = await this.getAllEstimateGroups();
    const newEstimateGroup = new Estimate(new EstimateResponse(""));
    newEstimateGroup.estimateActions = [newEstimate];
    estimateGroups.push(newEstimateGroup);
    return estimateGroups;
  }

  async getEstimateGroupById(groupId: string) {
    const estimateGroups = await this.getAllEstimateGroups();
    const theGroupArray = estimateGroups.filter((eg) => eg.id === groupId);
    return theGroupArray[0];
  }

  async send(
    method: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    body: any,
    authed: boolean,
    hasResponse: boolean,
    apiUrl?: string,
    alterHeaders?: (headers: Headers) => void
  ): Promise<any> {
    //apiUrl = Utils.isNullOrWhitespace(apiUrl) ? this.environmentService.getApiUrl() : apiUrl;

    const requestUrl = apiUrl + path;

    // Prevent directory traversal from malicious paths
    if (new URL(requestUrl).href !== requestUrl) {
      return Promise.reject("Invalid request url path.");
    }

    // const device = this.platformUtilService.getDevice();
    // const deviceType = device.toString();

    const headers = new Headers({
      // "Device-Type": deviceType,
    });

    const requestInit: RequestInit = {
      cache: "no-store",
      method: method,
    };

    if (authed) {
      const authHeader = await this.apiService.getActiveBearerToken();
      headers.set("Authorization", "Bearer " + authHeader);
    }

    if (body != null) {
      if (typeof body === "string") {
        requestInit.body = body;
        headers.set("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
      } else if (typeof body === "object") {
        if (body instanceof FormData) {
          requestInit.body = body;
        } else {
          headers.set("Content-Type", "application/json; charset=utf-8");
          requestInit.body = JSON.stringify(body);
        }
      }
    }
    if (hasResponse) {
      headers.set("Accept", "application/json");
    }
    if (alterHeaders) {
      alterHeaders(headers);
    }

    requestInit.headers = headers;
    const newRequest = new Request(requestUrl, requestInit);
    const response = await this.fetch(newRequest);

    const responseType = response.headers.get("content-type");
    const responseIsJson = responseType != null && responseType.indexOf("application/json") !== -1;
    if (hasResponse && response.status === 200 && responseIsJson) {
      const responseJson = await response.json();
      return responseJson;
    } else if (response.status !== 200) {
      const error = await this.handleError(response, false, authed);
      return Promise.reject(error);
    }
  }

  async fetch(request: Request): Promise<Response> {
    return fetch(request);
  }

  private async handleError(
    response: Response,
    tokenError: boolean,
    authed: boolean
  ): Promise<ErrorResponse> {
    let responseJson: any = null;
    if (this.isJsonResponse(response)) {
      responseJson = await response.json();
    } else if (this.isTextResponse(response)) {
      responseJson = { Message: await response.text() };
    }

    if (authed) {
      if (
        response.status === 401 ||
        response.status === 403 ||
        (tokenError &&
          response.status === 400 &&
          responseJson != null &&
          responseJson.error === "invalid_grant")
      ) {
        //await this.logoutCallback(true);
        return null;
      }
    }

    return new ErrorResponse(responseJson, response.status, tokenError);
  }

  private isJsonResponse(response: Response): boolean {
    const typeHeader = response.headers.get("content-type");
    return typeHeader != null && typeHeader.indexOf("application/json") > -1;
  }

  private isTextResponse(response: Response): boolean {
    const typeHeader = response.headers.get("content-type");
    return typeHeader != null && typeHeader.indexOf("text") > -1;
  }
}
