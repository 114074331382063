import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";

import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";

@Component({
  selector: "app-dash",
  templateUrl: "./dash.component.html",
})
export class DashComponent implements OnInit {
  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 3,
          largeChart: { cols: 3, rows: 1 },
          smallChart: { cols: 1, rows: 1 },
          table: { cols: 3, rows: 5 },
        };
      }

      return {
        columns: 3,
        largeChart: { cols: 3, rows: 1 },
        smallChart: { cols: 1, rows: 1 },
        table: { cols: 3, rows: 5 },
      };
    })
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private blobbyService: BlobbyService
  ) {}

  async ngOnInit(): Promise<void> {
    return null;
  }
}
